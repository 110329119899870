import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserRoutingModule } from './user-routing.module';
import { AddEditProducerUserComponent } from './add-edit-producer-user/add-edit-producer-user.component';
import { AddEditEmployerUserComponent } from './add-edit-employer-user/add-edit-employer-user.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxMaskModule } from 'ngx-mask';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { AddEditAdminUserComponent } from './add-edit-admin-user/add-edit-admin-user.component';
import { AddEditGeneralAgentUserComponent } from './add-edit-general-agent-user/add-edit-general-agent-user.component';
import { UserInfoFormComponent } from './components/user-info-form/user-info-form.component';
import { PasswordModalComponent } from './components/password-modal/password-modal.component';
import { AddEditBaUserComponent } from './add-edit-ba-user/add-edit-ba-user.component';
import { AddBaComponent } from './add-edit-ba-user/components/add-ba/add-ba.component';
import { EditBaComponent } from './add-edit-ba-user/components/edit-ba/edit-ba.component';
import { AddEditSubProducerUserComponent } from './add-edit-sub-producer-user/add-edit-sub-producer-user.component';
import { AddEditGaUserComponent } from './add-edit-ga-user/add-edit-ga-user.component';

@NgModule({
  declarations: [
    AddEditProducerUserComponent,
    AddEditEmployerUserComponent,
    AddEditAdminUserComponent,
    AddEditGeneralAgentUserComponent,
    AddEditBaUserComponent,
    UserProfileComponent,
    UserInfoFormComponent,
    PasswordModalComponent,
    AddBaComponent,
    EditBaComponent,
    AddEditSubProducerUserComponent,
    AddEditGaUserComponent
  ],
  imports: [
    CommonModule,
    UserRoutingModule,
    SharedModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
  ],
})
export class UserModule {}
