import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmployerUsersComponent } from './employer-users/employer-users.component';
import { ManageAdminUsersComponent } from './manage-admin-users/manage-admin-users.component';
import { ManageMpUsersComponent } from './manage-mp-users/manage-mp-users.component';
import { ManageEmployerUsersComponent } from './manage-employer-users/manage-employer-users.component';
import { ManageAllMpUsersComponent } from './manage-all-mp-users/manage-all-mp-users.component';
import { ManageGaUsersComponent } from './manage-ga-users/manage-ga-users.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'employer-users',
        component: EmployerUsersComponent,
        canActivate: [],
      },
      {
        path: 'admin-users',
        component: ManageAdminUsersComponent,
        canActivate: [],
      },
      {
        path: 'manage-all-mp-users', // Manage all mp users
        component: ManageAllMpUsersComponent,
        canActivate: [],
      },
      {
        path: 'manage-mp-users', // Manage current impersonated mp users (Just keeping the name that is being used in the old one)
        component: ManageMpUsersComponent,
        canActivate: [],
      },
      {
        path: 'manage-ga-users', 
        component: ManageGaUsersComponent,
        canActivate: [],
      },
      {
        path: 'manage-employer-users',
        component: ManageEmployerUsersComponent,
        canActivate: [],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UsersRoutingModule {}
