import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { AddEditProducerUserComponent } from './add-edit-producer-user/add-edit-producer-user.component';
import { AddEditEmployerUserComponent } from './add-edit-employer-user/add-edit-employer-user.component';
import { AddEditAdminUserComponent } from './add-edit-admin-user/add-edit-admin-user.component';
import { AddEditGeneralAgentUserComponent } from './add-edit-general-agent-user/add-edit-general-agent-user.component';
import { AddEditBaUserComponent } from './add-edit-ba-user/add-edit-ba-user.component';
import { ManageBaGroupsComponent } from '../benefit-administrator/manage-ba-groups/manage-ba-groups.component';
import { AddEditSubProducerUserComponent } from './add-edit-sub-producer-user/add-edit-sub-producer-user.component';
import { AddEditGaUserComponent } from './add-edit-ga-user/add-edit-ga-user.component';

const routes: Routes = [
  {
    path: '',
    children: [
      // Main user Profile
      {
        path: 'profile',
        component: UserProfileComponent,
        canActivate: [],
        title: 'Allied Administrators - User Profile',
      },
      // Producer
      {
        path: 'add-producer-user',
        component: AddEditProducerUserComponent,
        canActivate: [],
        title: 'Allied Administrators - Add Producer User',
      },
      {
        path: 'edit-producer-user/:id',
        component: AddEditProducerUserComponent,
        canActivate: [],
        title: 'Allied Administrators - Edit Producer User',
      },
      {
        path: 'add-sub-producer-user/:id',
        component: AddEditSubProducerUserComponent,
        canActivate: [],
        title: 'Allied Administrators - Add Sub Producer User',
      },
      //GA USER
      {
        path: 'add-ga-user',
        component: AddEditGaUserComponent,
        canActivate: [],
        title: 'Allied Administrators - Add GA User',
      },
      {
        path: 'edit-ga-user/:id',
        component: AddEditGaUserComponent,
        canActivate: [],
        title: 'Allied Administrators - Edit GA User',
      },
      // Employer
      {
        path: 'add-employer-user',
        component: AddEditEmployerUserComponent,
        canActivate: [],
        title: 'Allied Administrators - Add Employer User',
      },
      {
        path: 'edit-employer-user/:id',
        component: AddEditEmployerUserComponent,
        canActivate: [],
        title: 'Allied Administrators - Edit Employer User',
      },
      // Admin
      {
        path: 'add-admin-user',
        component: AddEditAdminUserComponent,
        canActivate: [],
        title: 'Allied Administrators - Add Admin User',
      },
      {
        path: 'edit-admin-user/:id',
        component: AddEditAdminUserComponent,
        canActivate: [],
        title: 'Allied Administrators - Edit Admin User',
      },
      // General Agent
      {
        path: 'add-general-agent-user',
        component: AddEditGeneralAgentUserComponent,
        canActivate: [],
        title: 'Allied Administrators - Add General Agent User',
      },
      {
        path: 'edit-general-agent-user/:id',
        component: AddEditGeneralAgentUserComponent,
        canActivate: [],
        title : 'Allied Administrators - Edit General Agent User',
      },
      {
        path: 'view-general-agent-user/:id',
        component: AddEditGeneralAgentUserComponent,
        canActivate: [],
        title: 'Allied Administrators - View General Agent User',
      },
      // BA
      {
        path: 'add-benefit-administrator',
        component: AddEditBaUserComponent,
        title: 'Allied Administrators - Add Benefit Administrator',
      },
      {
        path: 'edit-benefit-administrator/:id',
        component: AddEditBaUserComponent,
        title: 'Allied Administrators - Edit Benefit Administrator',
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserRoutingModule {}
