import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { faBuilding, faListCheck, faLock, faUnlock, faUserLock, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { Observable, take } from 'rxjs';
import { ProducerUserData } from 'src/app/core/models/api/user/mp-all-users.response';
import { UserViewState } from 'src/app/core/models/user-view-state.model';
import { FileService } from 'src/app/core/services/file.service';
import { SubProducerService } from 'src/app/core/services/http/sub-producer.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UserViewService } from 'src/app/core/services/user-view.service';
import { DisplayedColumns, TableDownloadButtons } from 'src/app/shared/components/table/table.component';

@Component({
  selector: 'app-sub-producers-groups',
  styleUrls: ['./sub-producers-groups.component.scss'],
  template: `
    <app-content-card CustomTitle="Manage Sub Producers Groups">
      <ng-container *ngIf="data$ | withLoading | async as data">
        <app-table
          [IsLoading]="data.loading ?? false"
          [EnableSearch]="true"
          [DisplayedColumns]="displayedColumns"
          [DownloadButtons]="downloadButtons"
          [Rows]="data.value"
          [EnablePagination]="true"
          [EnableLocalSearch]="true"
          [EnableSearch]="true"
          [EnableLocalSort]="true"></app-table>
      </ng-container>
    </app-content-card>

    <ng-template #disabledColumnTemplate let-data>
      <span>{{data.Disabled === true ?'Y': 'N'}} </span>
    </ng-template>

    <ng-template #allowedToSeeCommissionStatementsTemplate let-data>
      <span>{{data.AllowedToSeeCommissionStatements === true ?'Y': 'N'}} </span>
    </ng-template>

    <ng-template #actionColumnTemplate let-data>
      <!-- DISABLE ENABLE -->
      <div style="display:flex;justify-content: space-evenly">
        <a *ngIf="data.Disabled === true" (click)="enableUser(data.UserId)" style="cursor:pointer" title="Enable User">
          <fa-icon [icon]="enableIcon" style="color: var(--brandColor);"></fa-icon>
        </a>

        <a
          *ngIf="data.Disabled === false"
          (click)="disableUser(data.UserId)"
          style="cursor:pointer"
          title="Disable User">
          <fa-icon [icon]="disableIcon" style="color: var(--brandColor);"></fa-icon>
        </a>

        <a *ngIf="data.AllowedToSeeCommissionStatements === true" (click)="disableCommissionStatements(data.UserId)" style="cursor:pointer" title="Disable Commission Statements">
          <fa-icon [icon]="listCheckIcon" style="color: var(--brandColor);"></fa-icon>
        </a>

        <a
          *ngIf="data.AllowedToSeeCommissionStatements !== true"
          (click)="enableCommissionStatements(data.UserId)"
          style="cursor:pointer"
          title="Enable Commission Statements">
          <fa-icon [icon]="listCheckIcon" style="color: var(--brandColor);"></fa-icon>
        </a>

        <a style="cursor:pointer" title="Manage groups" (click)="assignGroups(data.UserId)">
          <fa-icon [icon]="groupsIcon" style="color: var(--brandColor);"></fa-icon>
        </a>
      </div>
      <!-- MANAGE GROUPS -->
    </ng-template>
  `,
})
export class SubProducersGroupsComponent implements OnInit {
  constructor(
    private userViewService: UserViewService,
    private subProducerService: SubProducerService,
    private fileService: FileService,
    private notificationService: NotificationService,
    private router: Router
  ) {}

  data$: Observable<ProducerUserData[]>;
  downloadButtons: TableDownloadButtons;
  displayedColumns: DisplayedColumns[];

  enableIcon = faUnlock;
  disableIcon = faLock;
  groupsIcon = faBuilding;
  listCheckIcon = faListCheck;

  viewState: UserViewState | null;

  @ViewChild('actionColumnTemplate', { static: true }) actionColTemplate: TemplateRef<unknown>;
  @ViewChild('disabledColumnTemplate', { static: true }) disabledColumnTemplate: TemplateRef<unknown>;
  @ViewChild('allowedToSeeCommissionStatementsTemplate', { static: true }) allowedToSeeCommissionStatementsTemplate: TemplateRef<unknown>;

  ngOnInit(): void {
    this.viewState = this.userViewService.GetCurrentUserViewState();
    this.downloadButtons = {
      DownloadPDF: {
        callback: () => {
          let headers = [
            {
              firstName: 'First Name',
              lastName: 'Last Name',
              email:'Email',
              phone:'Phone',
              disabled: 'Disabled',
              AllowedToSeeCommissionStatements: 'Commission Statements'
            },
          ];

          this.data$.pipe(take(1)).subscribe(x => {
            this.fileService
              .GeneratePDF('Allied Administrators - Sub Producers', headers, this.mapTableToObject(x))
              .save('Allied Administrators - Sub Producers');
          });
        },
      },
      DownloadXLSX: {
        callback: () => {
          this.data$.pipe(take(1)).subscribe(x => {

            this.fileService.GenerateXLSX('Allied Administrators - Sub Producers', this.mapTableToObject(x),
            [
              'First Name',
              'Last Name',
              'Email',
              'Phone',
              'Disabled',
              'Commission Statements'
            ]);
          });
        },
      },
    };

    this.displayedColumns = [
      { columnName: 'FirstName', label: 'First Name', sortable: true },
      { columnName: 'LastName', label: 'Last Name', sortable: true },
      { columnName: 'Email', label: 'Email', sortable: true },
      { columnName: 'Phone', label: 'Phone' },

      {
        columnName: 'Disabled',
        label: 'Disabled',
        template: this.disabledColumnTemplate,
        enableFullDataColumnTemplateContext: true,
      },
      {
        columnName: 'AllowedToSeeCommissionStatements',
        label: 'Commision Statements',
        template: this.allowedToSeeCommissionStatementsTemplate,
        enableFullDataColumnTemplateContext: true,
      },
      {
        columnName: 'Action',
        label: 'Action',
        template: this.actionColTemplate,
        enableFullDataColumnTemplateContext: true,
      },
    ];

    this.loadData();
  }

  loadData() {
    if (this.viewState?.MasterProducerId)
      this.data$ = this.subProducerService.GetSubProducers(Number(this.viewState.MasterProducerId));
  }

  
  enableCommissionStatements(userId: number)
  {
    this.notificationService.confirmation('Confirm enabling the commission statements for this sub producer?', () => {
      this.subProducerService.UpdateSubProducerCommissionStatements(userId).subscribe(response => {
        this.loadData();
      });
    });
  }

  disableCommissionStatements(userId: number){
    this.notificationService.confirmation('Confirm disabling the commission statements for this sub producer?', () => {
      this.subProducerService.UpdateSubProducerCommissionStatements(userId).subscribe(response => {
        this.loadData();
      });
    });
  }

  disableUser(userId: number) {
    this.notificationService.confirmation('Confirm disabling this sub producer?', () => {
      this.subProducerService.UpdateSubProducerStatus(userId, true).subscribe(response => {
        this.loadData();
      });
    });
  }

  enableUser(userId: number) {
    this.notificationService.confirmation('Confirm enabling this sub producer?', () => {
      this.subProducerService.UpdateSubProducerStatus(userId, false).subscribe(response => {
        this.loadData();
      });
    });
  }

  assignGroups(userId: number) {
    this.router.navigate(['/dashboard/assign-sub-producers-groups/' + userId]);
  }

  private mapTableToObject(subProducerData: ProducerUserData[]) {
    return subProducerData.map(producer => ({
      firstName: producer.FirstName,
      lastName: producer.LastName,
      email:producer.Email,
      phone:producer.Phone,
      disabled: producer.Disabled === true ? 'Y' : 'N',
      AllowedToSeeCommissionStatements: producer.AllowedToSeeCommissionStatements === true ? 'Y': 'N'
    }));
  }
}
