import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TutorialsService } from 'src/app/core/services/http/tutorials.service';

@Component({
  selector: 'app-tutorials',
  templateUrl: './tutorials.component.html',
  styleUrls: ['./tutorials.component.scss']
})
export class TutorialsComponent implements OnInit {

  constructor(
    private router: Router,
    private tutorialService: TutorialsService
  ) { }

  buttons: {btnText: string, link: string, externalLink: boolean}[];

  ngOnInit(): void {
    this.tutorialService.GetAllTutorials().subscribe(res => {
      this.buttons = res.map<{btnText: string, link: string, externalLink: boolean}>(tutorial => ({btnText: tutorial.DisplayName, link: tutorial.Link, externalLink: tutorial.ExternalLink }));

    })

  }

  openLink (link:{btnText: string, link: string, externalLink: boolean} ) {
    if(link.externalLink === true)
    {
      window.open(link.link);
    }else{
      this.router.navigate([link.link]);
    }
  }
}
