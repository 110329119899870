<app-modal [open]="openMfaAskModal" [modalProps]="modalMfaProps" modalHeader="2FA Opt In">
  <div ngClass="mfaModal_inner">
    <span>Would you like to sign up for Two Factor Authentication?</span>
    <span>Emails will be sent to {{ mfaEmail }}</span>
  </div>
</app-modal>

<app-modal [open]="OpenPasswordModal.value" [modalProps]="newPasswordModal" modalHeader="Change Your Password">
  <div
    style="display: flex; flex-direction: column; gap: 10px; padding: 10px"
    ngClass="change_password_after_reset_wrapper">
    <form [formGroup]="changePasswordAfterResetForm">
      <app-form-input
        fieldName="newPassword"
        formControlName="newPassword"
        [parentForm]="changePasswordAfterResetForm"
        [enableVisibilityToggle]="true"
        [Wide]="true"
        type="password"></app-form-input>
      <span>* Password must have one uppercase, one lowercase,8 characters, 1 number and/or special character.</span>
    </form>
    <custom-button
      [forceCapitalization]="true"
      [IsLoading]="changePasswordAfterResetLoading"
      [disabled]="changePasswordAfterResetLoading"
      (onClick)="onChangePasswordAfterReset()"
      label="SAVE"
      style="align-self: flex-end"></custom-button>
  </div>
</app-modal>
<!-- <app-change-password [open]="OpenPasswordModal.value" (onClose)="onPasswordModalClose()"></app-change-password> -->

<div ngClass="signin_container">
  <div *ngIf="showRedirectDisplay">
    <div class="login_heading welcome redirect_container">
      <span>We are redirecting you back...</span>
      <fa-icon [icon]="faCircleNotch" [spin]="true"></fa-icon>
    </div>
  </div>
  <div *ngIf="!showRedirectDisplay" ngClass="signin_inner_wrapper">
    <div ngClass="signin_welcome">
      <div ngClass="signin_welcome_text">Welcome Back!</div>
      <p ngClass="" style="line-height: 25px">
        Allied Administrators Website for<br />
        Delta Dental's Small Business Program
      </p>
    </div>
    <ng-container *ngIf="!mfaEnabled; else showMfaForm">
      <ng-container *ngIf="!emailFormEnabled; else showEmailForm">
        <form ngClass="signin_form " [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <app-form-input
            ngClass="form_item mat-field-dark-theme"
            type="text"
            [primary]="true"
            title="User Name"
            [Wide]="true"
            placeholder="username"
            formControlName="username"
            [parentForm]="loginForm"
            fieldName="username"
            (blur)="onTouched()">
          </app-form-input>
          <app-form-input
            ngClass="form_item mat-field-dark-theme"
            [primary]="true"
            [Wide]="true"
            type="password"
            title="Password"
            placeholder="password"
            fieldName="password"
            formControlName="password"
            [parentForm]="loginForm"
            (blur)="onTouched()">
          </app-form-input>

          <button ngClass="form_item sign_in_button" type="submit" class="btn" [disabled]="loading | async">
            <span>LOGIN</span>
            <span *ngIf="loading | async">
              <fa-icon [icon]="faCircleNotch" [spin]="true"></fa-icon>
            </span>
          </button>
          <div ngClass="links_wrapper">
            <a class="link_item" routerLink="/auth/signup">Register</a>
            <a class="link_item" routerLink="/auth/forgot-password">Forgot Password?</a>
          </div>
        </form>
      </ng-container>
    </ng-container>
    <ng-template #showMfaForm>
      <form ngClass="signin_form" [formGroup]="mfaForm" (ngSubmit)="onSubmitMfaDigits()">
        <app-form-input
          ngClass="form_item mat-field-dark-theme"
          type="text"
          [primary]="true"
          title="2FA 6 digit code"
          [Wide]="true"
          placeholder="mfaDigits"
          formControlName="mfaDigits"
          [parentForm]="mfaForm"
          fieldName="mfaDigits"
          (blur)="onTouched()">
        </app-form-input>

        <span style="font-size: 12px; font-weight: 400; color: white"
          >A 6 digit code have been sent to your email to verify your identity</span
        >

        <button ngClass="form_item sign_in_button" type="submit" class="btn">
          <span>SUBMIT</span>
          <span *ngIf="loading | async">
            <fa-icon [icon]="faCircleNotch" [spin]="true"></fa-icon>
          </span>
        </button>
      </form>
    </ng-template>
    <ng-template #showEmailForm >
      <span style="font-size: 12px; font-weight: 400; color: white">You need to confirm your Email address to receive the 2FA code to login</span>
      <form ngClass="signin_form" [formGroup]="emailForm" (ngSubmit)="onSubmitEmail()">
        <app-form-input
          ngClass="form_item mat-field-dark-theme"
          type="text"
          [primary]="true"
          title="Email Address"
          [Wide]="true"
          placeholder="john@email.com"
          formControlName="emailAddress"
          [parentForm]="emailForm"
          fieldName="emailAddress"></app-form-input>
          <button ngClass="form_item sign_in_button" type="submit" class="btn">
            <span>SUBMIT</span>
            <span *ngIf="loading | async">
              <fa-icon [icon]="faCircleNotch" [spin]="true"></fa-icon>
            </span>
          </button>
      </form>
    </ng-template>
  </div>
  <div *ngIf="!showRedirectDisplay" ngClass="signin_background"></div>
</div>
