<app-content-card CustomTitle="Manage Tutorial">
  <p>For <b>external links</b> such as Youtube etc, please add the entire url, example; https://www.youtube.com/watch?q= and also select External Link checkbox</p>
  <p>For <b>internal links</b>, copy the url from the page you want the user to be redirected. Example, this page you need to add <b>/dashboard/add-tutorial</b>, and then the user will be redirected to the page to add a tutorial;</p>
    <form [formGroup]="tutorialForm"
    style="display: flex; flex-direction: column; padding: 20px;"
    >
        <app-form-input
        fieldName="displayName"
        style="flex: 1;"
        [Wide]="true"
        title="Display Name"
        [parentForm]="tutorialForm"> </app-form-input>
        <app-form-input
        fieldName="link"
        title="link"
        
        [Wide]="true"
        [parentForm]="tutorialForm"> </app-form-input>
        <app-form-checkbox
        name="External Link"
        fieldName="externalLink"
        [parentForm]="tutorialForm"></app-form-checkbox>
        <div style="display: flex; gap: 10px; justify-content: center;">
            <custom-button
              [forceCapitalization]="true"
              [routerLink]="[returnUrl]"
              label="BACK"
              color="secondary"></custom-button>
            <custom-button [forceCapitalization]="true" (onClick)="OnSubmit()" label="SAVE"></custom-button>
          </div>
    </form>
</app-content-card>