import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';
import { GaEmployerResponse } from 'src/app/core/models/api/employer/ga-employer.response';
import { EmployerService } from 'src/app/core/services/http/employer.service';
import { UserViewService } from 'src/app/core/services/user-view.service';
import { DisplayedColumns } from 'src/app/shared/components/table/table.component';
import { parseStringToRegionCode } from '../../helpers/user-view.helper';
import { ViewType } from 'src/app/core/models/user-view.model';

@Component({
  selector: 'app-ga-clients',
  template: ` <app-content-card CustomTitle="Your Clients">
      <ng-container *ngIf="rows$ | withLoading | async as rows">
        <app-table
          [DisplayedColumns]="displayedColumns"
          [IsLoading]="rows.loading ?? false"
          [EnableSearch]="true"
          [EnableLocalSearch]="true"
          [EnableLocalSort]="true"
          [EnablePagination]="true"
          [Rows]="rows.value"
          emptyRowsTextMessage="If no companies are listed, there are no active employers at this time."></app-table>
      </ng-container>
    </app-content-card>

    <ng-template #actionCol let-data>
      <div
        (click)="onImpersonateUser(data)"
        style="cursor: pointer;"
        [matTooltip]="'View ' + data.EmployerName + ' as ER'">
        <fa-icon ngClass="view_as_user_eye" [icon]="viewIcon"></fa-icon>
      </div>
    </ng-template>`,
  styleUrls: ['./ga-clients.component.scss'],
})
export class GaClientsComponent implements OnInit {
  viewIcon = faEye;

  constructor(private userViewService: UserViewService, private employerService: EmployerService) {}

  gaId: number;
  displayedColumns: DisplayedColumns[];
  rows$: Observable<GaEmployerResponse[]>;
  @ViewChild('actionCol', { static: true }) actionColTemplate: TemplateRef<unknown>;

  ngOnInit(): void {
    const viewState = this.userViewService.GetCurrentUserViewState();
    if (viewState?.GaId) this.gaId = viewState.GaId;
    this.displayedColumns = [
      {
        columnName: 'Action',
        label: 'Action',
        template: this.actionColTemplate,
        enableFullDataColumnTemplateContext: true,
      },
      { columnName: 'Region', label: 'Region', sortable: true },
      { columnName: 'EmployerNr', label: 'Employer Number', sortable: true },
      { columnName: 'EmployerName', label: 'Employer Name', sortable: true },
      { columnName: 'MasterProducer', label: 'Master Producer', sortable: true },
    ];

    this.loadData();
  }

  loadData() {
    this.rows$ = this.employerService.GetGaClients(this.gaId);
  }

  onImpersonateUser(data: GaEmployerResponse) {
    let oeMonth: number | undefined = undefined;
    if (data.OEMonth !== undefined) {
      oeMonth = data.OEMonth;
    }

    this.userViewService
      .Propagate(false)
      .ChangeRegion(parseStringToRegionCode(data.Region))
      .ChangeEmployerId(parseInt(data.EmployerId.toString() ?? '0').toString())
      .ChangeEmployerNumber(data.EmployerNr)
      .ChangePlanNumber(data.PlanNr)
      .ChangeCompanyName(data.EmployerName)
      .ChangeEmployerManageEligibility(data.ManageEligibility === true)
      .ChangeWaitingPeriod(data.WaitingPeriod)
      .ChangeEmployerOeMonth(oeMonth)
      .ChangeImpersonationState(true)
      .ChangeView(ViewType.EmployerView)
      .Propagate(true)
      .NavigateToPageView(this.userViewService.GetPrimaryLink().toString());
  }
}
