import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { link } from 'fs-extra';
import { TutorialsInput } from 'src/app/core/models/api/tutorials/tutorials.input';
import { RouteEnum } from 'src/app/core/models/routes.enum';
import { TutorialsService } from 'src/app/core/services/http/tutorials.service';
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
  selector: 'app-add-edit-tutorial',
  templateUrl: './add-edit-tutorial.component.html',
  styleUrls: ['./add-edit-tutorial.component.scss'],
})
export class AddEditTutorialComponent implements OnInit {
  constructor(
    private tutorialsService: TutorialsService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private notificationService: NotificationService
  ) {}
  returnUrl = RouteEnum.ManageTutorials;
  tutorialId?: number;
  tutorialForm: FormGroup<any>;

  ngOnInit(): void {
    const paramId = this.route.snapshot.paramMap.get('id');

    this.tutorialForm = this.fb.group({
      displayName: ['', Validators.required],
      link: ['', Validators.required],
      externalLink: [false],
    });
    if (paramId) {
      this.tutorialId = parseInt(paramId);
      //load tutorial
      this.tutorialForm.disable();
      this.loadTutorial();
    }

  }

  loadTutorial() {
    this.tutorialsService.GetbyId(this.tutorialId ?? 0).subscribe(res => {
      this.tutorialForm.patchValue({
        displayName: res.DisplayName,
        link: res.Link,
        externalLink: res.ExternalLink,
      });
      this.tutorialForm.enable();
    });
  }

  OnSubmit() {
    if (!this.tutorialForm.valid) return;
    const input = new TutorialsInput();
    const { displayName, link, externalLink } = this.tutorialForm.getRawValue();

    input.DisplayName = displayName;
    input.Link = link;
    input.ExternalLink = externalLink;
    input.Id = this.tutorialId;

    this.tutorialsService.AddOrUpdateTutorial(input).subscribe(res => {
      this.notificationService.success('Tutorial Updated Successfully');
      this.router.navigate([this.returnUrl]);
    });
  }
}
