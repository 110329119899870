import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InvoicesComponent } from './pages/invoices/invoices.component';
import { ManageContentComponent } from './pages/manage-content/manage-content.component';
import { ModulePickerComponent } from './pages/module-picker/module-picker.component';
import { OnlinePaymentComponent } from './pages/online-payment/online-payment.component';
import { ProductsManagementComponent } from './pages/products-management/products-management.component';
import { UnderDevelopmentComponent } from './pages/under-development/under-development.component';
import { SelectEmployerComponent } from './pages/employers/select-employer/select-employer.component';
import { UserDashboardComponent } from './user-dashboard.component';
import { WebAnalyticsComponent } from './pages/web-analytics/web-analytics.component';
import { EmployeesComponent } from './pages/employees/employees.component';
import { CommissionStatementsComponent } from './pages/comission-statements/commission-statements.component';
import { YourClientsComponent } from './pages/your-clients/your-clients.component';
import { MarketingResourcesComponent } from './pages/marketing-resources/marketing-resources.component';
import { MasterProducerProfileComponent } from './pages/master-producer-profile/master-producer-profile.component';
import { ProducerDocumentsComponent } from './pages/documents/producer-documents/producer-documents.component';
import { EmployerDocumentsComponent } from './pages/documents/employer-documents/employer-documents.component';
import { OnboardingComponent } from './pages/onboarding/onboarding.component';
import { ReplicationStatusComponent } from './pages/replication-status/replication-status.component';
import { CmsComponent } from './pages/cms/cms.component';
import { NewUserRegistrationComponent } from './pages/new-user-registration/new-user-registration.component';
import { ManageGeneralAgentsComponent } from './pages/manage-general-agents/manage-general-agents.component';
import { SelectMasterProducerComponent } from './pages/select-master-producer/select-master-producer.component';
import { AddEditAdminUserComponent } from './pages/user/add-edit-admin-user/add-edit-admin-user.component';
import { CompanyProfileComponent } from './pages/company-profile/company-profile.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { MessageComponent } from './pages/manage-content/message/message.component';
import { EligibilityArchiveLogsXmlExcelComponent } from './pages/eligibility/eligibility-archive-logs-xml-excel/eligibility-archive-logs-xml-excel.component';
import { ManageEligibilityUploadXmlExcelComponent } from './pages/eligibility/manage-eligibility-upload-xml-excel/manage-eligibility-upload-xml-excel.component';
import { FileManagementComponent } from './pages/eligibility/file-management/file-management.component';
import { ViewLatestComponent } from './pages/version-history/view-latest/view-latest.component';
import { ViewArchivedComponent } from './pages/version-history/view-archived/view-archived.component';
import { SelectBaUsersComponent } from './pages/benefit-administrator/select-ba-users/select-ba-users.component';
import { AddEditMarketingResourceComponent } from './pages/marketing-resources/add-edit-marketing-resource/add-edit-marketing-resource.component';
import { ManageBaGroupsComponent } from './pages/benefit-administrator/manage-ba-groups/manage-ba-groups.component';
import { EligibilityAccessComponent } from './pages/benefit-administrator/eligibility-access/eligibility-access.component';
import { ApprovePendingBaComponent } from './pages/benefit-administrator/approve-pending-ba/approve-pending-ba.component';
import { SubProducersAccessComponent } from './pages/sub-producers/sub-producers-access/sub-producers-access.component';
import { SubProducersGroupsComponent } from './pages/sub-producers/sub-producers-groups/sub-producers-groups.component';
import { RequestAcessComponent } from './pages/sub-producers/request-acess/request-acess.component';
import { AssignGroupsSubProducerComponent } from './pages/sub-producers/assign-groups-sub-producer/assign-groups-sub-producer.component';
import { EmployerInvoiceDocumentsComponent } from './pages/documents/employer-invoice-documents/employer-invoice-documents.component';
import { EmployerInvoicesComponent } from './pages/invoices/employer-invoices/employer-invoices.component';
import { ManageContentPictureComponent } from './pages/manage-content-picture/manage-content-picture.component';
import { ManageEmployerLinkComponent } from './pages/employers/manage-employer-link/manage-employer-link.component';
import { AddEditEmployerLinkComponent } from './pages/employers/add-edit-employer-link/add-edit-employer-link.component';
import { MasterEmployerComponent } from './pages/master-employer/master-employer.component';
import { ManageTutorialsComponent } from './pages/manage-tutorials/manage-tutorials.component';
import { AddEditTutorialComponent } from './pages/add-edit-tutorial/add-edit-tutorial.component';
import { GaClientsComponent } from './pages/ga-clients/ga-clients.component';

const routes: Routes = [
  {
    path: '',
    component: UserDashboardComponent,
    children: [
      {
        path: 'employee',
        loadChildren: () => import('./pages/employee/employee.module').then(m => m.EmployeeModule),
        canActivate: [],
      },
      {
        path: 'site-navigation',
        loadChildren: () => import('./pages/site-navigation/site-navigation.module').then(m => m.SiteNavigationModule),
        canActivate: [],
      },
      {
        path: 'reports',
        loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule),
        canActivate: [],
      },
      {
        path: 'email',
        loadChildren: () => import('./pages/email/email.module').then(m => m.EmailModule),
        canActivate: [],
      },
      {
        path: 'users',
        loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule),
        canActivate: [],
      },
      {
        path: 'user',
        loadChildren: () => import('./pages/user/user.module').then(m => m.UserModule),
        canActivate: [],
      },
      {
        path: 'supplies',
        loadChildren: () => import('./pages/supplies/supplies.module').then(m => m.SuppliesModule),
        canActivate: [],
      },
      {
        path: 'products-management',
        component: ProductsManagementComponent,
        canActivate: [],
        title: 'Allied Administrators - Products Management',
      },
      {
        path: 'invoice',
        component: InvoicesComponent,
        canActivate: [],
        title: 'Allied Administrators - Invoice',
      },
      {
        path: 'invoice/pdf',
        component: EmployerInvoicesComponent,
        canActivate: [],
        title: 'Allied Administrators - Summary Invoice - PDF',
      },
      {
        path: 'invoice/excel',
        component: EmployerInvoicesComponent,
        canActivate: [],
        title: 'Allied Administrators - Detailed Invoice - Excel',
      },
      {
        path: 'invoice/manage-invoices',
        component: EmployerInvoiceDocumentsComponent,
        canActivate: [],
        title: 'Allied Administrators - Manage Invoices',
      },
      {
        path: 'online-payment',
        component: OnlinePaymentComponent,
        canActivate: [],
        title: 'Allied Administrators - Online Payment',
      },
      {
        path: 'employees',
        component: EmployeesComponent,
        canActivate: [],
        title: 'Allied Administrators - Employees',
      },
      {
        path: 'under-development',
        component: UnderDevelopmentComponent,
        canActivate: [],
      },
      {
        path: 'employer',
        children: [
          {
            path: 'master-employer',
            component: MasterEmployerComponent,
            canActivate: [],
            title: 'Allied Administrators - Select Employer'
          },
          {
            path: 'select-employer',
            component: SelectEmployerComponent,
            canActivate: [],
            title: 'Allied Administrators - Select Employer',
          },
          {
            path: 'documents',
            component: EmployerDocumentsComponent,
            canActivate: [],
            title: 'Allied Administrators - Documents',
          },
          {
            path:'manage-links',
            component: ManageEmployerLinkComponent,
            canActivate: [],
            title: 'Allied Administrators - Employer Associations'
          },
          {
            path:'edit-link/:id',
            component: AddEditEmployerLinkComponent,
            canActivate: [],
            title: 'Allied Administrators - Employer Associations'
          },
          {
            path:'add-link',
            component: AddEditEmployerLinkComponent,
            canActivate: [],
            title: 'Allied Administrators - Employer Associations'
          }
        ],
      },
      {
        path: 'manage-content',
        component: ManageContentComponent,
        canActivate: [],
        title: 'Allied Administrators - Manage Content',
      },
      {
        path: 'manage-tutorials',
        component: ManageTutorialsComponent,
        canActivate: [],
        title: 'Allied Administrators - Manage Tutorials',
      },
      {
        path: 'add-tutorial',
        component: AddEditTutorialComponent,
        canActivate: [],
        title: 'Allied Administrators - Add / Edit Tutorial',
      },
      {
        path: 'edit-tutorial/:id',
        component: AddEditTutorialComponent,
        canActivate: [],
        title: 'Allied Administrators - Add / Edit Tutorial',
      },
      {
        path: 'manage-content-picture',
        component: ManageContentPictureComponent,
        canActivate: [],
        title: 'Allied Administrators - Manage Content Picture',
      },
      {
        path: 'message/:id',
        component: MessageComponent,
        canActivate: [],
        title: 'Allied Administrators - Message',
      },
      {
        path: 'eligibility',
        children: [
          // Master
          {
            path: 'manage-master-eligibility-template',
            component: ManageEligibilityUploadXmlExcelComponent,
            canActivate: [],
          },
          {
            path: 'master-file-management',
            component: FileManagementComponent,
            canActivate: [],
          },

          // Excel
          {
            path: 'manage-eligibility-template',
            component: ManageEligibilityUploadXmlExcelComponent,
            canActivate: [],
            title: 'Allied Administrators - Manage Eligibility Template',
          },
          {
            path: 'file-management',
            component: FileManagementComponent,
            canActivate: [],
            title: 'Allied Administrators - File Management',
          },

          //XML
          {
            path: 'manage-eligibility-xml-template',
            component: ManageEligibilityUploadXmlExcelComponent,
            canActivate: [],
            title: 'Allied Administrators - Manage Eligibility XML Template',
          },
          {
            path: 'xml-file-management',
            component: FileManagementComponent,
            canActivate: [],
            title: 'Allied Administrators - XML File Management',
          },
          {
            path: 'archive-logs-excel/:id',
            component: EligibilityArchiveLogsXmlExcelComponent,
            title: 'Allied Administrators - Archive Logs Excel',
          },
          {
            path: 'archive-logs-xml/:id',
            component: EligibilityArchiveLogsXmlExcelComponent,
            title: 'Allied Administrators - Archive Logs XML',
          }
        ],
      },
      {
        path: 'company-profile',
        component: CompanyProfileComponent,
        canActivate: [],
        title: 'Allied Administrators - Company Profile',
      },
      {
        path: 'chart',
        component: DashboardComponent,
        canActivate: [],
        title: 'Allied Administrators - Chart',
      },
      {
        path: 'web-analytics',
        component: WebAnalyticsComponent,
        canActivate: [],
        title: 'Allied Administrators - Web Analytics',
      },
      {
        path: 'commission-statements',
        component: CommissionStatementsComponent,
        canActivate: [],
        title: 'Allied Administrators - Commission Statements',
      },
      {
        path: 'master-producer-clients',
        component: YourClientsComponent,
        canActivate: [],
        title: 'Allied Administrators - Master Producer Clients',
      },
      {
        path: 'ga-clients',
        component: GaClientsComponent,
        canActivate: [],
        title: 'Allied Administrators - General Agent Clients'
      },
      {
        path: 'sub-producers-request-access',
        component: RequestAcessComponent,
        canActivate: [],
        title: 'Allied Administrators - Request access for sub producers',
      },
      {
        path:'sub-producers-access',
        component: SubProducersAccessComponent,
        canActivate: [],
        title: 'Allied Administrators - Sub Producers Access Request'
      },
      {
        path:'sub-producers-groups',
        component: SubProducersGroupsComponent,
        canActivate: [],
        title: 'Allied Administrators - Sub Producers Groups'
      },
      {
        path:'assign-sub-producers-groups/:id',
        component: AssignGroupsSubProducerComponent,
        canActivate: [],
        title: 'Allied Administrators - Assign Sub Producers Groups'
      },
      {
        path: 'xml-ba-producer-clients',
        component: YourClientsComponent,
        canActivate: [],
        title: 'Allied Administrators - XML BA Producer Clients',
      },
      {
        path: 'select-master-producer',
        component: SelectMasterProducerComponent,
        canActivate: [],
        title: 'Allied Administrators - Select Master Producer',
      },
      {
        path: 'marketing-resources',
        component: MarketingResourcesComponent,
        canActivate: [],
        title: 'Allied Administrators - Marketing Resources',
      },
      {
        path: 'add-marketing-resources',
        component: AddEditMarketingResourceComponent,
        canActivate: [],
        title: 'Allied Administrators - Add Marketing Resources',
      },
      {
        path: 'edit-marketing-resources/:id',
        component: AddEditMarketingResourceComponent,
        canActivate: [],
        title: 'Allied Administrators - Edit Marketing Resources',
      },
      {
        path: 'profile',
        component: MasterProducerProfileComponent,
        canActivate: [],
        title: 'Allied Administrators - Profile',
      },
      {
        path: 'onboarding',
        component: OnboardingComponent,
        canActivate: [],
        title: 'Allied Administrators - Onboarding',
      },
      {
        path: 'cms',
        component: CmsComponent,
        canActivate: [],
        title: 'Allied Administrators - CMS',
      },
      {
        path: 'replication-status',
        component: ReplicationStatusComponent,
        canActivate: [],
        title: 'Allied Administrators - Replication Status',
      },
      {
        path: 'new-user-registration',
        component: NewUserRegistrationComponent,
        canActivate: [],
        title: 'Allied Administrators - New User Registration',
      },
      {
        path: 'manage-ga',
        component: ManageGeneralAgentsComponent,
        canActivate: [],
        title: 'Allied Administrators - Manage GA',
      },
      {
        path: 'producer',
        canActivate: [],
        children: [
          {
            path: 'documents',
            component: ProducerDocumentsComponent,
            canActivate: [],
            title: 'Allied Administrators - Documents',
          },
        ],
      },
      {
        path: 'add-admin-user',
        component: AddEditAdminUserComponent,
        canActivate: [],
        title: 'Allied Administrators - Add Admin User',
      },
      {
        path: 'edit-admin-user/:userId',
        component: AddEditAdminUserComponent,
        canActivate: [],
        title: 'Allied Administrators - Edit Admin User',
      },
      {
        path:'version-history',
        children:[
          {
            path: 'latest',
            component: ViewLatestComponent,
            title: 'Allied Administrators - Version History',
          },
          {
            path:'archived',
            component: ViewArchivedComponent,
            title: 'Allied Administrators - Version History',
          }
        ]
      },
      {
        path: 'benefit-administrator',
        children: [
          {
            path: 'select-ba-users',
            component: SelectBaUsersComponent,
            title: 'Allied Administrators - Select BA Users',
          },
          {
            path: 'manage-ba-groups',
            component: ManageBaGroupsComponent,
            title: 'Allied Administrators - Manage BA Groups',
          },
          {
            path: 'eligibility-access',
            component: EligibilityAccessComponent,
            canActivate: [],
            title: 'Allied Administrators - Eligibility Access',
          },
          {
            path: 'approve-pending-ba-access/:id',
            component: ApprovePendingBaComponent,
            canActivate: [],
            title: 'Allied Administrators - Approve Pending BA Access',
          },
        ]
      }
    ],
  },
  {
    path: '',
    children: [
      {
        path: 'module-picker',
        component: ModulePickerComponent,
        canActivate: [],
      },
      { path: '**', redirectTo: 'dashboard/under-development' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserDashboardRoutingModule {}
