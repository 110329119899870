<app-content-card CustomTitle="Manage Tutorials">
  <div ngClass="search_filter_wrapper">
    <div ngClass="search_filter"></div>
    <custom-button
      [forceCapitalization]="true"
      style="align-self: flex-end"
      label="CREATE TUTORIAL"
      (onClick)="addTutorial()"
      [icon]="addIcon"></custom-button>
  </div>

  <ng-container *ngIf="tutorialsInfo$ | withLoading | async as tutorialsInfo">
    <app-table
      [Rows]="tutorialsInfo.value ?? undefined"
      [IsLoading]="tutorialsInfo.loading ?? false"
      [DisplayedColumns]="displayedColumns"
      [EnableLocalSearch]="true"
      [EnablePagination]="true"
      [EnableSearch]="true"
      [EnableLocalSort]="true">
    </app-table>
  </ng-container>
</app-content-card>

<ng-template #tutorialsExternalLinkTemplate let-data>
  <span>{{ data === true ? 'Yes' : 'No' }}</span>
</ng-template>
<ng-template #tutorialsActiontemplate let-data>
  <div ngClass="action_icons">
    <div (click)="onNavigateToPage(editTutorial, data)">
      <fa-icon ngClass="action_icon" title="Edit Tutorial" [icon]="editIcon"></fa-icon>
    </div>
    <div (click)="onDeleteTutorial(data)">
        <fa-icon ngClass="action_icon" title="Delete Tutorial" [icon]="deleteIcon"></fa-icon>
      </div>
  </div>
</ng-template>
