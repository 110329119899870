export enum UserTypeEnum {
  Admin = 'Admin',
  Producer = 'Producer',
  BaProducer = 'BaProducer',
  Employer = 'Employer',
  None = 'None',
  NewBaProducer = "NewBaProducer",
  SubProducer= "IsSubProducer",
  MasterEmployer = "MasterEmployer",
  Ga = 'GA'
}
