export enum UserRightCodeEnum {
  ManageUsers = 'ManageUsers',

  EmployerEdit = 'EmployerEdit',
  EmployeeEdit = 'EmployeeEdit',

  SupplyAdmin = 'SupplyAdmin',
  LanveraDownload = 'LanveraDownload',
  MasterProducerEdit = 'MasterProducerEdit',
  MasterProducerView = 'MasterProducerView',
  ViewGA = 'ViewGA',
  EditGA = 'EditGA',
  // Regions
  DDCARegion = 'DDCARegion',
  DDICRegion = 'DDICRegion',
  DDPARegion = 'DDPARegion',
  DDNJRegion = 'DDNJRegion',

  ProductManagement = 'ProductManagement',
  ReportsManagement = 'ReportsManagement',

  WebAnalytical = 'WebAnalytical',
  AccessAlliedAdminGroups = 'AccessAlliedAdminGroups',

  Onboarding = 'Onboarding',
  OnboardingAdmin = 'OnboardingAdmin',
  OnboardingSA = 'OnboardingSA',
  ReplicationStatus = 'ReplicationStatus',

  ManageInstructions = 'ManageInstructions',
  ManageUserRegistration = 'ManageUserRegistration',
  ManageEmailTemplate = 'ManageEmailTemplate',
  ManageERUser = 'ManageERUser',

  ManageMasterProducerUsers = 'ManageMasterProducerUsers',
  ManageInvoices = 'ManageInvoices',
  ManageEmailLog = 'ManageEmailLog',

  ManageXML = 'ManageXML',
  ManageEmails = 'ManageEmails',
  ManageEligibility = 'ManageEligibility',
  ManageEligibilityUpload = 'ManageEligibilityUpload',
  MasterEligibilityUpload = 'MasterEligibilityUpload',

  CMS = 'CMS',
  CMSAdmin = 'CMSAdmin',
  ManageTutorials = 'ManageTutorials'
}
