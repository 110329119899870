<app-content-card CustomTitle="User Profile Information">
  <div ngClass="wrapper">
    <app-change-password [open]="OpenPasswordModal.value" (onClose)="onPasswordModalClose()"></app-change-password>
    <ng-container *ngIf="userProfileInformation$ | withLoading | async as userInfo">
      <ng-template [ngIf]="userInfo.loading">
        <app-skeleton-input style="margin-bottom: 5px"></app-skeleton-input>
      </ng-template>
      <ng-template [ngIf]="userInfo.value">
        <form [formGroup]="userProfileForm">
          <div>
            <app-form-input
              type="text"
              [Icon]="personIcon"
              [primary]="true"
              title="User Name"
              [Wide]="true"
              placeholder="userName"
              formControlName="userName"
              [parentForm]="userProfileForm"
              fieldName="userName"></app-form-input>

            <app-form-input
              type="text"
              [primary]="true"
              [Icon]="personIcon"
              title="First Name"
              [Wide]="true"
              placeholder="firstName"
              formControlName="firstName"
              [parentForm]="userProfileForm"
              fieldName="firstName"></app-form-input>
            <app-form-input
              type="text"
              [primary]="true"
              title="Email"
              [Icon]="emailIcon"
              [Wide]="true"
              placeholder="email"
              formControlName="email"
              [parentForm]="userProfileForm"
              fieldName="email"></app-form-input>
          </div>
          <div>
            <app-form-input
              type="text"
              [Icon]="personCheckIcon"
              [primary]="true"
              title="User Type"
              [Wide]="true"
              placeholder="userType"
              formControlName="userType"
              [parentForm]="userProfileForm"
              fieldName="userType"></app-form-input>

            <app-form-input
              type="text"
              [primary]="true"
              [Icon]="personIcon"
              title="Last Name"
              [Wide]="true"
              placeholder="lastName"
              formControlName="lastName"
              [parentForm]="userProfileForm"
              fieldName="lastName"></app-form-input>

            <app-form-input
              type="text"
              [primary]="true"
              title="Phone Number"
              [Icon]="phoneIcon"
              inputmode="numeric"
              [Wide]="true"
              mask="(000) 000-0000"
              placeholder="phoneNumber"
              formControlName="phoneNumber"
              [parentForm]="userProfileForm"
              fieldName="phoneNumber"></app-form-input>
          </div>
          <div style="margin-top: 10px; flex: 1; width: 100%; flex-basis: 100%">
            <custom-button [forceCapitalization]="true"
              label="CHANGE PASSWORD"
              [Wide]="false"
              [primary]="true"
              [icon]="keyIcon"
              (onClick)="onPasswordClick()"></custom-button>
          </div>
        </form>
      </ng-template>

      <!-- Admin View -->
      <ng-container *ngIf="isUserAdmin">
        <ng-container *ngIf="userPrivilegesInformation$ | withLoading | async as userPrivileges">
          <div ngClass="divider_below"></div>
          <p><strong ngClass="section_title">Log Details:</strong></p>

          <ng-template [ngIf]="userPrivileges.loading">
            <app-skeleton-input style="width: 100%"></app-skeleton-input>
          </ng-template>

          <ng-template [ngIf]="userPrivileges.value">
            <div ngClass="log_details">
              <div>
                <i class="md-list-addon-icon material-icons color primary">lock</i>
                <label> Total Logins :</label>
                <label class="ng-binding">{{ userPrivileges.value.Success_login + userPrivileges.value.BadLoginAttempts }}</label>
              </div>
              <div>
                <i class="md-list-addon-icon material-icons uk-text-success color success">check_circle</i>
                <label> Success Logins :</label>
                <label class="ng-binding">{{ userPrivileges.value.Success_login }}</label>
              </div>
              <div>
                <i class="md-list-addon-icon material-icons uk-text-danger color danger">remove_circle</i>
                <label> Failed Logins :</label>
                <label>{{ userPrivileges.value.Failed_login }}</label>
              </div>
              <div>
                <i class="md-list-addon-icon material-icons uk-text-warning color warning">watch_later</i>
                <label> Last Login Attempt :</label>
                <label>
                  {{ userPrivileges.value.Lastlogin }}
                  <span>
                    -
                    <i
                      *ngIf="userPrivileges.value.LastloginStatus; else noLastLoginStatus"
                      class="md-list-addon-icon material-icons color success"
                      >check_circle</i
                    >
                    <ng-template #noLastLoginStatus>
                      <i class="md-list-addon-icon material-icons color success">remove_circle</i>
                    </ng-template>
                  </span>
                </label>
              </div>
              <div>
                <i class="md-list-addon-icon material-icons color warning">exit_to_app</i>
                <label> Last Log Off : </label>
                <label>{{ userPrivileges.value.Lastlogoff }}</label>
              </div>
            </div>
          </ng-template>

        </ng-container>

        <div ngClass="divider_below"></div>
        <p><strong ngClass="section_title">Privileges:</strong></p>

        <ng-template [ngIf]="userInfo.loading">
          <app-skeleton-input style="width: 100%"></app-skeleton-input>
        </ng-template>

        <ng-template [ngIf]="userInfo.value">
          <div ngClass="privileges">
            <div *ngFor="let col of rightsCols$ | async; let colI = index">
              <ng-container *ngFor="let userRight of col; let rowsI = index">
                <app-checkbox
                  [name]="userRight.right.toString()"
                  [checked]="userRight.assigned"
                  [disabled]="true"></app-checkbox>
              </ng-container>
            </div>
          </div>
        </ng-template>
      </ng-container>
      <ng-template [ngIf]="userInfo.value">
        <div style="display: flex; justify-content: center">
          <custom-button [forceCapitalization]="true"
            *ngIf="isUserAdmin"
            label="VIEW JOURNAL"
            [color]="'secondary'"
            [Wide]="false"
            (onClick)="onViewJournalClick()"
            [icon]="historyIcon"></custom-button>
          <custom-button [forceCapitalization]="true"
            label="SAVE"
            [Wide]="false"
            [primary]="true"
            [IsLoading]="saveLoading.value"
            [icon]="saveIcon"
            [disabled]="!userProfileForm.dirty"
            (onClick)="onSubmit(userProfileForm.value, userInfo.value)"></custom-button>
        </div>

        <div style="flex: 1" *ngIf="openJournal">
          <ng-container *ngIf="journalData$ | withLoading | async as journalData">
            <app-table
              *ngIf="journalData.value"
              [DisplayedColumns]="DisplayedColumns"
              [EnablePagination]="true"
              [IsLoading]="false"
              [Rows]="journalData.value"></app-table>
            <ng-template> </ng-template>
            <app-table
              *ngIf="journalData.loading"
              [DisplayedColumns]="DisplayedColumns"
              [IsLoading]="true"
              [LoadingColumns]="3"></app-table>
            <ng-template> </ng-template>
          </ng-container>
        </div>
      </ng-template>
    </ng-container>
  </div>
</app-content-card>

<!-- Column Templates -->
<ng-template #loggedInTemplate let-data>
  <div>{{ data.Username }}</div>
  <div>({{ data.UserID }})</div>
</ng-template>

<ng-template #actionOnTemplate let-data>
  <div>{{ data.FirstName }} {{ data.Lastname }}</div>
  <div>({{ data.UserId }})</div>
</ng-template>

<ng-template #timeTemplate let-date>
  {{ FormatDate(date) }}
</ng-template>
