import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, debounceTime, of, take, tap } from 'rxjs';
import { AuthService } from 'src/app/core/services/http/auth/auth.service';
import { UserControlService } from 'src/app/core/services/http/user-control.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { PasswordValidator } from 'src/app/shared/components/form-elements/password.validator';
import { ModalProps } from 'src/app/shared/components/modal/modal.component';
import { LogDetailsInfo } from 'src/app/shared/components/profile/log-details/log-details.component';
import { GetMasterProducer, GetMasterProducerApiResponse } from 'src/app/core/models/api/master-producer/get-master-producer.response';
import {
  faKey,
  faArrowLeft,
  faRotate,
  faUser,
  faUserCheck,
  faEnvelope,
  faPhone,
  faFloppyDisk,
  faClockRotateLeft,
  faLock,
} from '@fortawesome/free-solid-svg-icons';
import { RouteEnum } from 'src/app/core/models/routes.enum';
import randomatic from 'randomatic';
import { UserJournal } from 'src/app/shared/components/view-journal/view-journal.component';
import { AuthenticationUserType } from 'src/app/core/models/api/auth/AuthenticationUserTypeEnum';
import { UserViewService } from 'src/app/core/services/user-view.service';
import { IsStringNullOrEmpty } from 'src/app/core/helpers/validation-helpers';

@Component({
  selector: 'app-add-edit-employer-user',
  templateUrl: './add-edit-employer-user.component.html',
  styleUrls: ['./add-edit-employer-user.component.scss', '../styles.scss'],
})
export class AddEditEmployerUserComponent implements OnInit {
  keyIcon = faKey;
  arrowLeft = faArrowLeft;
  generatePWIcon = faRotate;
  personIcon = faUser;
  personCheckIcon = faUserCheck;
  emailIcon = faEnvelope;
  phoneIcon = faPhone;
  saveIcon = faFloppyDisk;
  historyIcon = faClockRotateLeft;
  resetPasswordButtonIcon = faClockRotateLeft;

  isEdittingUser: boolean;
  userId: number;
  userLocked: boolean = false;
  badLoginAttempts: number = 0;
  lockedUserIcon = faLock;


  employerForm: FormGroup;
  saveLoading: boolean;
  changePasswordLoading: boolean;

  userData$: Observable<GetMasterProducerApiResponse>;
  modalProps: ModalProps;
  showResetPasswordModal: boolean;
  logDetailsInfo: LogDetailsInfo = {};
  openJournal: any;
  employerUsersUrl: string = RouteEnum.EmployerUsers;
  journalInput: UserJournal;
  employerId: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private userControlService: UserControlService,
    private userViewService: UserViewService,
    private authService: AuthService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    let userView = this.userViewService.GetUser();

    if (userView?.EmployerId !== null && userView?.EmployerId !== undefined && userView?.EmployerId !== '') {
      this.employerUsersUrl = RouteEnum.ManageEmployeUsers;
      this.employerId = userView.EmployerId;
    }

    this.userId = Number(this.route.snapshot.paramMap.get('id'));
    this.isEdittingUser = this.userId !== 0;
    this.userData$ = of({} as GetMasterProducerApiResponse);
    this.initForm({});
    this.modalProps = {
      disableButtons: true,
      CloseButton: {
        OnAction: () => (this.showResetPasswordModal = false),
      },
    };

    if (this.isEdittingUser) {
      this.userData$ = this.userControlService.GetMasterProducer(this.userId.toString()).pipe(
        tap(res => {
          let data: EmployerFormFields = {
            disabled: res.response.Disabled,
            email: res.response.Email,
            firstName: res.response.FirstName,
            lastName: res.response.LastName,
            masterEmployerName: res.response.ProducerName,
            phoneNumber: res.response.Phone,
            userName: res.response.Username,
            manageEligibility: res.response.IsViewOnly !== true
          };
          if(IsStringNullOrEmpty(this.employerId)){
            this.employerId = res.response.EmployerId?.toString() ?? '';
          }

          this.userLocked = res.response.IsLockOut && userView?.IsAdmin() === true;
          this.badLoginAttempts = res.response.BadLoginAttempts;

          this.logDetailsInfo = {
            FailedLogins: res.response.Failed_login,
            SuccessLogins: res.response.Success_login,
            TotalLogins: res.response.Failed_login + res.response.Success_login,
            LastLoginAttempt: res.response.Lastlogin,
            LastLoginStatus: res.response.LastloginStatus,
            LastLogOff: res.response.Lastlogoff,
          };

          this.journalInput = {
            UserId: this.userId,
            UserType: AuthenticationUserType.Employer,
          };

          this.initForm(data);
          this.onUsernameChange();
        })
      );
    } else {
      this.onUsernameChange();
    }
  }

  unlockUser(){
    this.notificationService.confirmation("Confirm User Unlock?", () => {
      this.authService.UnlockUser(this.userId ?? 0).subscribe(res => {

        if(res.status === true){
          this.notificationService.success(res.response);
          window.location.reload();

        }else{
          this.notificationService.error(res.response);
        }
      });
    });
  }

  onResetPassword() {
    let passwordField = this.employerForm.get('password');
    let userField = this.employerForm.get('userName');

    let data = this.userData$;
    this.changePasswordLoading = true;
    data.pipe(take(1)).subscribe(res => {
      if (passwordField?.valid && this.userId && userField?.value) {
        // TODO payload not correct, returning exception. Fix that
        this.authService
          .ResetPassword(
            passwordField.value,
            this.userId.toString(),
            userField.value,
            AuthenticationUserType.Employer,
            res.response.MasterProducerId
          )
          .subscribe(res => {
            if (res.status) this.notificationService.success(res.message);
            else this.notificationService.error(res.message);

            this.showResetPasswordModal = false;
            this.changePasswordLoading = false;
          });
      }
    });
  }

  onSubmit(data: GetMasterProducerApiResponse) {
    if (this.employerForm.invalid) {
      this.employerForm.markAllAsTouched();
      return;
    }

    let { userName, firstName, lastName, password, email, phoneNumber, disabled, manageEligibility } = this.employerForm.value;

    let userPayload = {
      ...data.response,
      FirstName: firstName,
      Username: userName,
      LastName: lastName,
      Password: password,
      EmployerId: this.employerId ? Number(this.employerId) : '',
      Email: email,
      Phone: phoneNumber,
      Disabled: disabled,
      MasterProducerId: this.isEdittingUser ? data.response.MasterProducerId : 0,
      UserID: this.userId === 0 ? '' : this.userId,
      Type: AuthenticationUserType.Employer,
      IsViewOnly: manageEligibility === false
    } as GetMasterProducer;
    this.saveLoading = true;
    this.userControlService.CreateOrUpdateUser(userPayload).subscribe(res => {
      if (res.status) {
        this.notificationService.success(res.message);
        this.router.navigate([this.employerUsersUrl]);
        this.saveLoading = false;
        return;
      } else this.notificationService.error(res.message);
      this.saveLoading = false;
    });
  }

  onGeneratePassword() {
    this.employerForm.get('password')?.patchValue(randomatic('Aa0!', 10));
  }

  onViewJournalClick() {
    this.openJournal = true;
  }

  private initForm(employerForm: EmployerFormFields) {
    let passwordValidations = this.isEdittingUser
      ? []
      : [Validators.minLength(8), PasswordValidator.strong, Validators.required];

    this.employerForm = this.fb.group({
      userName: [employerForm?.userName ?? '', Validators.required],
      firstName: [employerForm?.firstName ?? '', Validators.required],
      lastName: [employerForm?.lastName ?? '', Validators.required],
      password: [employerForm?.password ?? '', passwordValidations],
      email: [employerForm?.email ?? '', [Validators.required, Validators.email]],
      phoneNumber: [employerForm?.phoneNumber ?? '', Validators.required],
      masterEmployerName: [{ value: employerForm?.masterEmployerName ?? '', disabled: true }, Validators.required],
      manageEligibility: [employerForm?.manageEligibility ?? true],
      disabled: [employerForm?.disabled ?? false, Validators.required],
    });
  }

  private onUsernameChange() {
    this.employerForm
      .get('userName')
      ?.valueChanges.pipe(debounceTime(1000))
      .subscribe((userName: string) => {
        if (!userName || userName.length === 0) return;
        this.saveLoading = true;
        this.authService.CheckUser({ UserName: userName }).subscribe(res => {
          let userNameField = this.employerForm.get('userName');

          if (!res.status) userNameField?.setErrors({ usernameTaken: true });
          else {
            userNameField?.setErrors(null);
          }
          this.saveLoading = false;
        });
      });
  }
}

type EmployerFormFields = {
  userName?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  password?: string;
  phoneNumber?: string;
  disabled?: boolean;
  masterEmployerName?: string;
  manageEligibility?: boolean;
};
