import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, filter, takeUntil } from 'rxjs';
import { MenuLink } from 'src/app/shared/components/vertical-menu/components/menu-item/menu-item.component';
import { VerticalMenuItensGroup } from 'src/app/shared/components/vertical-menu/vertical-menu.component';
import { RouteEnum, getRouteEnumByString } from '../models/routes.enum';
import { NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import { uniqueId } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class VerticalMenuService {
  VERTICAL_MENU_STATE_STORAGE = 'vertical-menu-state';
  state: BehaviorSubject<MenuVerticalState>;
  selectedMenuLink = new BehaviorSubject<MenuLink | null>(null);
  private destroyed$ = new Subject();
  constructor(
    private router: Router
  ) {
    this.state = new BehaviorSubject<MenuVerticalState>(this.getCurrentVerticalMenuFromLocalStorage());
  }

  SetUp() {
    this.router.events.pipe(takeUntil(this.destroyed$)).subscribe(event => {
      if (event instanceof NavigationEnd) {
        console.debug('Navigation Start', event);

        let route = getRouteEnumByString(event.url);

        if (route) {
          let menuLinkToBeSelected = this.getMenuLinkByRoute(route);
          if (menuLinkToBeSelected)
            this.UpdateState(prevState => ({ ...prevState, selectedMenuLink: menuLinkToBeSelected }));
        }
      }
    });
  }

  UpdateState(callback: (previous: MenuVerticalState) => MenuVerticalState) {
    let newState = callback(this.state.value);
    this.setCurrentVerticalMenuToLocalStorage(newState);
    this.state.next(newState);
  }

  SetIsLoading(isLoading: boolean) {
    this.UpdateState(previous => ({ ...previous, IsLoading: isLoading }));
  }

  toggleMenuLinkActive(menuLink: MenuLink){

    if(menuLink.SubMenuLinks?.length){
      menuLink.Active = !menuLink.Active;
      this.UpdateState(prevState => ({ ...prevState, selectedMenuLink: menuLink }));

    }

  }
  SetActiveMenuLink(menuLink: MenuLink) {
    this.UpdateState(prevState => ({ ...prevState, selectedMenuLink: menuLink, selectedHash: uniqueId() }));
  }

  SetActiveMenuLinkByRoute(route: RouteEnum) {
    let menuLinkFound: MenuLink | undefined = undefined;
    this.state.value.MenuLinksGroups?.forEach(menuLinkGroup => {
      menuLinkGroup.verticalMenuItemNew.forEach(menuLink => {
        if (route === menuLink.Link) {
          menuLinkFound = menuLink;
        }

        menuLink.SubMenuLinks?.forEach(subMenuLink => {
          if (route === subMenuLink.Link) {
            menuLinkFound = subMenuLink;
          }
        });
        return menuLink;
      });
      return menuLinkGroup;
    });
    if (menuLinkFound) this.UpdateState(prevState => ({ ...prevState, selectedMenuLink: menuLinkFound }));
  }

  DeselectActiveMenu() {
    this.UpdateState(prevState => ({ ...prevState, selectedMenuLink: undefined }));
  }

  RemoveVerticalMenuState() {
    sessionStorage.removeItem(this.VERTICAL_MENU_STATE_STORAGE);
    this.state.next({ MenuLinksGroups: [], IsOpen: true, IsLoading: false });
    this.selectedMenuLink.next(null);
  }

  private getMenuLinkByRoute(route: RouteEnum): MenuLink | undefined {
    let menuLink: MenuLink | undefined = undefined;

    this.state.value.MenuLinksGroups.forEach(group => {
      return group.verticalMenuItemNew.forEach(menuItem => {
        if (menuItem.Link == route) menuLink = menuItem;

        let foundSubMenuItem = menuItem.SubMenuLinks?.find(subMenuItem => subMenuItem.Link == route);

        if (foundSubMenuItem) {
          menuLink = foundSubMenuItem;
        }
      });
    });

    return menuLink;
  }

  private getCurrentVerticalMenuFromLocalStorage = (): MenuVerticalState => {
    let verticalMenuState = sessionStorage.getItem(this.VERTICAL_MENU_STATE_STORAGE);

    if (verticalMenuState == null) return { MenuLinksGroups: [], IsOpen: true, IsLoading: false };

    return JSON.parse(verticalMenuState) as MenuVerticalState;
  };

  private setCurrentVerticalMenuToLocalStorage = (state: MenuVerticalState) =>
    sessionStorage.setItem(this.VERTICAL_MENU_STATE_STORAGE, JSON.stringify(state));
}

export type MenuVerticalState = {
  IsLoading: boolean;
  MenuLinksGroups: VerticalMenuItensGroup[];
  selectedMenuLink?: MenuLink;
  selectedHash?: string;
  IsOpen: boolean;
};
