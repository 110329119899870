import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { TutorialsResponse } from '../../models/api/tutorials/tutorials.response';
import { environment } from 'src/environments/environment';
import { TutorialsInput } from '../../models/api/tutorials/tutorials.input';


@Injectable({
    providedIn: 'root',
  })
  export class TutorialsService
  {
    constructor(private http: HttpClient){}

    GetAllTutorials(){
        return this.http.get<TutorialsResponse[]>(`${environment.alliedApi.baseUrl}/tutorials`);
    }

    GetbyId(id: number){
        return this.http.get<TutorialsResponse>(`${environment.alliedApi.baseUrl}/tutorials/${id}`);
    }
    AddTutorial(input: TutorialsInput){
        return this.http.post<TutorialsResponse>(`${environment.alliedApi.baseUrl}/tutorials`,
            input
        );
    }
    UpdateTutorial(input: TutorialsInput){
        return this.http.put<TutorialsResponse>(`${environment.alliedApi.baseUrl}/tutorials`,
            input
        );
    }

    AddOrUpdateTutorial(input: TutorialsInput){
        if(input.Id){
            return this.UpdateTutorial(input);
        }
        return this.AddTutorial(input);
    }
    DeleteTutorial(id: number)
    {
        return this.http.delete(`${environment.alliedApi.baseUrl}/tutorials/${id}`);
    }
  }