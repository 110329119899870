<app-content-card CustomTitle="GA Users">
    <div style="display: flex; justify-content: flex-end; padding: 10px;">
      <custom-button
        [forceCapitalization]="true"
        label="+ ADD GA USER"
        [routerLink]="[addGaUrl]"></custom-button>
    </div>
    <ng-container *ngIf="data$ | withLoading | async as data">
      <app-table
        [IsLoading]="data.loading ?? false"
        [EnableSearch]="true"
        [DisplayedColumns]="displayedColumns"
        [Rows]="data.value"
        [EnablePagination]="true"></app-table>
    </ng-container>
  </app-content-card>

  <ng-template #actionColumnTemplate let-data>
    <a [routerLink]="[editGaUrl, data.UserId]">
      <fa-icon [icon]="editIcon" style="color: var(--brandColor);"></fa-icon>
    </a>
  </ng-template>
  <ng-template #disabledColumnTemplate let-disabled>
    <span *ngIf="isControllerView">{{ disabled ? 'Y' : 'N' }}</span>
    <app-checkbox *ngIf="!isControllerView" [disabled]="true" [checked]="disabled"></app-checkbox>
  </ng-template>
  <ng-template #dateColumnTemplate let-data>
    {{ customFormatDate(data) }}
  </ng-template>