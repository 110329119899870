import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { faPencil, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';
import { TutorialsResponse } from 'src/app/core/models/api/tutorials/tutorials.response';
import { RouteEnum } from 'src/app/core/models/routes.enum';
import { TutorialsService } from 'src/app/core/services/http/tutorials.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { DisplayedColumns } from 'src/app/shared/components/table/table.component';

@Component({
  selector: 'app-manage-tutorials',
  templateUrl: './manage-tutorials.component.html',
  styleUrls: ['./manage-tutorials.component.scss'],
})
export class ManageTutorialsComponent implements OnInit {
  constructor(private tutorialService: TutorialsService, private router: Router,
    private notificationService: NotificationService
  ) {}
  editIcon = faPencil;
  editTutorial = RouteEnum.EditTutorial;
  deleteIcon = faTrash;
  addIcon = faPlus;

  tutorialsInfo$: Observable<TutorialsResponse[]>;
  displayedColumns: DisplayedColumns[];
  @ViewChild('tutorialsExternalLinkTemplate', { static: true }) tutorialsExternalLinkTemplate: TemplateRef<unknown>;
  @ViewChild('tutorialsActiontemplate', { static: true }) tutorialsActiontemplate: TemplateRef<unknown>;

  ngOnInit(): void {
    this.displayedColumns = [
      {
        columnName: 'DisplayName',
        label: 'Name',
        sortable: true,
      },
      { columnName: 'Link', label: 'Link' },
      {
        columnName: 'ExternalLink',
        label: 'External Link',
        template: this.tutorialsExternalLinkTemplate,
      },
      { columnName: 'UploadedBy', label: 'Updated By' },
      {
        columnName: 'Id',
        label: 'Action',
        template: this.tutorialsActiontemplate,
        overrideValueFromColumn: 'Id',
      },
    ];
    this.loadData();
  }

  loadData() {
    this.tutorialsInfo$ = this.tutorialService.GetAllTutorials();
  }

  onDeleteTutorial(id: number){
    this.notificationService.confirmation("Confirm delete of tutorial?", () => {
      this.tutorialService.DeleteTutorial(id).subscribe(_ => {
        this.notificationService.success("Tutorial deleted")
        this.loadData();
      })
    })
  }

  addTutorial() {
    this.onNavigateToPage(RouteEnum.AddTutorial)
  }
  onNavigateToPage(route: RouteEnum, id?: string) {
    var routeArray: string[] = [route.toString()];
    if (id) routeArray.push(id);

    this.router.navigate(routeArray);
  }
}
