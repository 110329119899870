import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { faEye, faPencil, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import saveAs from 'file-saver';
import { Observable, map, take } from 'rxjs';
import { formatDate } from 'src/app/core/helpers/date-helpers';
import { MarketResource } from 'src/app/core/models/api/marketing-resource/marketing-resource-api.response';
import { RouteEnum } from 'src/app/core/models/routes.enum';
import { ViewType } from 'src/app/core/models/user-view.model';
import { FileService } from 'src/app/core/services/file.service';
import { AuthService } from 'src/app/core/services/http/auth/auth.service';
import { MarkertingResourceService } from 'src/app/core/services/http/markerting-resource.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UserViewService } from 'src/app/core/services/user-view.service';
import { DisplayedColumns, TableDownloadButtons } from 'src/app/shared/components/table/table.component';

@Component({
  selector: 'app-marketing-resources',
  // templateUrl: './marketing-resources.component.html',
  styleUrls: ['./marketing-resources.component.scss'],
  template: `
    <app-content-card CustomTitle="Marketing Resources">
      <div style="display: flex; flex-direction: column; padding: 20px;">
        <span>
          Below you will find plan information, marketing and enrollment materials. These tools will help you manage
          your current book of business as well as prospective clients.
        </span>
        <custom-button
          [forceCapitalization]="true"
          style="align-self: flex-end; padding-top: 10px;"
          label="UPLOAD DOCUMENT"
          *ngIf="isOnControllerView"
          [routerLink]="addMarketingResourceUrl"
          [icon]="addIcon"></custom-button>
      </div>
      <ng-container *ngIf="rows$ | withLoading | async as rows">
        <app-table
          [IsLoading]="rows.loading ?? false"
          [DisplayedColumns]="displayedColumns"
          [DownloadButtons]="downloadButtons"
          [EnablePagination]="true"
          [EnableSearch]="true"
          [EnableLocalSearch]="true"
          [EnableLocalSort]="true"
          [Rows]="rows.value"
          [EnableSearch]="true"></app-table>
      </ng-container>
    </app-content-card>

    <!-- Column Template Region -->
    <ng-template #actionColumnTemplate let-data>
      <div style="display: flex; gap: 10px; color: var(--brandColor);">
        <div *ngIf="isOnControllerView" [routerLink]="[editMarketingResourceUrl, data.FileID]" title="Edit">
          <i class="md-icon material-icons icon">edit</i>
        </div>
        <div (click)="onDownloadMarketingResource(data.FileID, data.Location)" title="Download">
          <i class="md-icon material-icons icon">cloud_download </i>
        </div>
        <div *ngIf="isOnControllerView" (click)="onDeleteMarketingResource(data.FileID)" title="Delete">
          <i class="md-icon material-icons icon">delete</i>
        </div>
      </div>
    </ng-template>

    <ng-template #typeColumnTemplate let-data>
      {{getFileType( data) }}
    </ng-template>

    <!-- <ng-template #employerNumberTemplate let-data>
      <a ngClass="table_cell_link" [title]="'View ' + data + ' as ER'">{{ data }}</a>
    </ng-template> -->
  `,
})
export class MarketingResourcesComponent implements OnInit {
  rows$: Observable<MarketResource[]>;
  displayedColumns: DisplayedColumns[];
  viewIcon = faEye;
  editIcon = faPencil;
  addIcon = faPlus;
  trashIcon = faTrash;

  isAdmin: boolean;

  addMarketingResourceUrl = RouteEnum.AddMarketingResources
  editMarketingResourceUrl = RouteEnum.EditMarketingResources

  //Column Templates
  @ViewChild('actionColumnTemplate', { static: true }) actionColTemplate: TemplateRef<unknown>;
  @ViewChild('typeColumnTemplate', { static: true }) typeColumnTemplate: TemplateRef<unknown>;

  private TableFileName: string = 'Allied Administrators - Marketing Resources';

  isOnControllerView: boolean;
  downloadButtons: TableDownloadButtons = {
    DownloadPDF: {
      callback: () => {
        let header = [
          {
            GANR: 'Shared With',
            FileName: 'Description',
            Location: 'Type',
            UploadedDate: 'Date',
          },
        ];

        this.rows$.subscribe(res => {
          this.fileService
            .GeneratePDF(this.TableFileName, header, this.mapToDownloadableTable(res))
            .save(this.TableFileName);
        });
      },
    },
    DownloadXLSX: {
      callback: () => {
        this.rows$.subscribe(res => {
          this.fileService.GenerateXLSX(this.TableFileName, this.mapToDownloadableTable(res), [
            'Shared With',
            'Description',
            'Type',
            'Date',
          ]);
        });
      },
    },
  };

  constructor(
    private marketingResources: MarkertingResourceService,
    private userViewService: UserViewService,
    private authService: AuthService,
    private notificationService: NotificationService,
    private fileService: FileService,
  ) {}

  ngOnInit(): void {
    this.isAdmin = this.authService.IsAdmin();
    this.isOnControllerView = this.userViewService.GetCurrentUserViewState()?.ActualView == ViewType.Controller;

    if (this.isAdmin === false) {
      this.downloadButtons = {};
    }
    let shouldShowAdminCollumns = this.isAdmin == true;

    this.displayedColumns = [
      {
        columnName: 'Action',
        label: 'Action',
        template: this.actionColTemplate,
        enableFullDataColumnTemplateContext: true,
      },
      { columnName: 'GANR', label: 'Shared With', disableColumn: shouldShowAdminCollumns === false, sortable: true },
      { columnName: 'FileName', label: 'Description', sortable: true },
      { columnName: 'Location', label: 'Type', template: this.typeColumnTemplate, sortable: true },
      { columnName: 'UploadedDate', label: 'Date', disableColumn: shouldShowAdminCollumns === false, sortable: true },
    ];

    this.updateDocuments();
  }

  onDeleteMarketingResource(fileId: string) {
    this.notificationService.confirmation('Confirm deleting this document?', () => {
      this.marketingResources.DeleteDocument(fileId).subscribe(res => {
        if (res.status) {
          this.notificationService.success(res.message);
          this.updateDocuments();
        } else this.notificationService.error(res.message);
      });
    });
  }

  onDownloadMarketingResource(fileId: string, fileName: string) {
    this.marketingResources.DownloadFilePdf(fileId).subscribe(blob => {
      const data: Blob = new Blob([blob], {
        type: 'text/csv;charset=utf-8',
      });
      saveAs(data, fileName);
    });
  }

  getFileType(data: string){
     return data.split('.')[ data.split('.').length-1].toUpperCase();
  }

  private mapToDownloadableTable(marketResource: MarketResource[]) {
    return marketResource.map(x => {
      return {
        GANR: x.GANR,
        FileName: x.FileName,
        Location: x.Location,
        UploadedDate: x.UploadedDate,
      };
    });
  }
  private updateDocuments() {
    let view = this.userViewService.GetCurrentUserViewState();

    this.rows$ = this.marketingResources.GetMarketingResources(view?.MasterProducerId?.toString()).pipe(
      map(res => res.response),
      this.mapToTableObject()
    );
  }

  private mapToTableObject() {
    return map<MarketResource[], MarketResource[]>(res =>
      res.map(marketResource => {
        marketResource.UploadedDate = formatDate(marketResource.UploadedDate, 'MM/DD/yyyy');
        marketResource.GANR = this.mapSharedWith(marketResource.GANR);
        return marketResource;
      })
    );
  }

  private mapSharedWith(sharedWith: string) {
    switch (sharedWith) {
      case 'All':
        return 'Shared with All GA';

      case 'Abs':
        return "Shared with ABS GA's Only";

      case 'AllState':
        return 'Shared with All GA in Selected States';

      case 'AbsState':
        return "Shared with ABS GA's Only in Selected States GA";
    }
    return ' ';
  }
}
