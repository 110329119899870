<app-content-card CustomTitle="SSN Duplicate Records">
  <!--CALENDAR  -->
  <div>
    <form [formGroup]="ssnDuplicateRecordsForm">
      <app-form-input-date
        fieldName="ssnReportDate"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [parentForm]="ssnDuplicateRecordsForm"
        title="Report Date"></app-form-input-date>
    </form>
  </div>
  <div>
    <ng-container *ngIf="ssnReportInfo$ | withLoading | async as ssnReportInfo">
    
    <app-table 
    [DownloadButtons]="DownloadButtons"
          [Rows]="ssnReportInfo.value"
          [IsLoading]="ssnReportInfo.loading ?? false"
          [DisplayedColumns]="displayedColumns"
          [EnableLocalSearch]="true"
          [EnablePagination]="true"
          [EnableSearch]="true"
          [EnableLocalSort]="true">
        </app-table>
    </ng-container>

  </div>
</app-content-card>

<ng-template #timeTemplate let-date>
  {{ FormatDate(date) }}
</ng-template>
