import { AbstractControl, ValidationErrors } from '@angular/forms';

export default function SpecialCharactersValidator(control: AbstractControl): ValidationErrors | null {
  let inputValue = control.value;
  if (inputValue === undefined || inputValue === '' || inputValue === null) {
    return null;
  }

  let regex = /^[a-zA-Z0-9@#\$%\^\&\*\~\'\`\"\-\)\/\(_ -]+$/g;

  let regTest = regex.test(inputValue);

  if (regTest === false) {
    return { customErrorMessage: "Value invalid, please verify. Special characters allowed: & # ' @ _ ~ ` ”-( ) /" };
  }

  return null;
}
