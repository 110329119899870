import { AuthenticationUserType } from '../models/api/auth/AuthenticationUserTypeEnum';

export const mapUserTypeEnumReadableName = (userType: AuthenticationUserType) => {
  if (userType == 'A') return 'Admin User';

  if (userType == 'R') return 'Employer User';

  if(userType == 'G') return 'General Agent User';

  return 'Producer User';
};
