import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { GaUser } from "../../models/api/user/ga-user.response";
import { GetGaUserApiResponse } from "../../models/api/general-agent/get-ga-user.response";

@Injectable({
  providedIn: 'root',
})
export class GaUsersService {
    constructor(private http: HttpClient) {

    }

    GetGaUsers(gaId: number){
        return this.http.get<GaUser[]>(`${environment.alliedApi.baseUrl}/Userctrl/GetGaUsers/${gaId}`);
    }

    GetGaUser(userId: number){
        return this.http.get<GetGaUserApiResponse>(`${environment.alliedApi.baseUrl}/Userctrl/GetGaUser/${userId}`);
    }
}