import { Injectable } from '@angular/core';
import {
  faUsers,
  faEnvelope,
  faCircleUser,
  faUserPlus,
  faMessage,
  faClockRotateLeft,
  faTableList,
  faBuilding,
  faBriefcase,
  faUserCircle,
  faHome,
  faUserCheck,
  faEllipsis,
  faIdCard,
  faParachuteBox,
  faLandmark,
  faClipboardList,
  faCreditCard,
  faFile,
  faFileLines,
  faUserGear,
  faFilePdf,
  faFileExcel,
  faGraduationCap,
  faCity,
  faInfo,
} from '@fortawesome/free-solid-svg-icons';
import { AuthenticationUserType } from '../models/api/auth/AuthenticationUserTypeEnum';
import { RegionCodes } from '../models/api/auth/responses/get-regions.response';
import { UserRightCodeEnum } from '../models/api/auth/responses/user/user-rights/user-right-code.enum';
import { RouteEnum } from '../models/routes.enum';
import { UserTypeEnum } from '../models/user-type.enum';
import { ViewType, AuthorizedUserView } from '../models/user-view.model';
import { ViewConfigurationBuilder } from '../helpers/view-configuration-builder';
import { AuthService } from './http/auth/auth.service';
import { icon } from '@fortawesome/fontawesome-svg-core';

@Injectable({
  providedIn: 'root',
})
export class UserViewConfigurationService {
  constructor (private authService: AuthService) {}
  GetUserViewConfiguration (): AuthorizedUserView[] {
    return new ViewConfigurationBuilder()
      .withView(this.controllerView())
      .withView(this.producerView())
      .withView(this.masterEmployerView())
      .withView(this.gaView())
      .withView(this.employerView())
      .withView(this.regionView())
      .withView(this.baBenefitAdministratorNewBaUserView())
      .withView(this.baBenefitAdministratorBaUserView())
      .build();
  }

  MapAuthenticationUserTypeToUserTypeEnum = (authenticationUserType: AuthenticationUserType) =>
    new Map<AuthenticationUserType, UserTypeEnum>([
      [AuthenticationUserType.Admin, UserTypeEnum.Admin],
      [AuthenticationUserType.Employer, UserTypeEnum.Employer],
      [AuthenticationUserType.Producer, UserTypeEnum.Producer],
      [AuthenticationUserType.GA, UserTypeEnum.Ga]
    ]).get(authenticationUserType) || UserTypeEnum.None;

  GetInitialViewsConfiguration = () => [
    { userType: UserTypeEnum.Admin, viewType: ViewType.Controller },
    {
      userType: UserTypeEnum.Producer,
      viewType: ViewType.ProducerView,
    },
    {
      userType: UserTypeEnum.BaProducer,
      viewType: ViewType.BenefitAdministratorView,
    },
    {
      userType: UserTypeEnum.NewBaProducer,
      viewType: ViewType.BenefitAdministratorNewBaUserView,
    },
    { userType: UserTypeEnum.Employer, viewType: ViewType.EmployerView },
    { userType: UserTypeEnum.MasterEmployer, viewType: ViewType.MasterEmployerView },
    { userType: UserTypeEnum.Ga, viewType: ViewType.GaView}
  ];

  private controllerView (): AuthorizedUserView {
    return {
      viewType: ViewType.Controller,
      allowedUserTypes: [UserTypeEnum.Admin],
      userModule: [RegionCodes.Admin],
      primaryRoute: RouteEnum.UserProfile,
      menuLinksGroups: [
        {
          GroupName: 'Admin Account',
          MenuLinks: [
            {
              linkName: 'Master Producer',
              icon: faUsers,
              subMenuLinks: [
                {
                  linkName: 'Select GA',
                  link: RouteEnum.ManageGA,
                  authorizedRight: [UserRightCodeEnum.ViewGA],
                },
                {
                  linkName: 'Select Master Producer',
                  link: RouteEnum.SelectMasterProducer,
                },
                {
                  linkName: 'Marketing Resources',
                  link: RouteEnum.MarketingResources,
                },
              ],
            },
            {
              linkName: 'Benefit Administrator',
              icon: faUsers,
              subMenuLinks: [
                {
                  linkName: 'Add BA',
                  link: RouteEnum.AddBaUser,
                },
                {
                  linkName: 'Select BA Users',
                  link: RouteEnum.SelectBaUsers,
                },
              ],
            },
            {
              linkName: 'Master Eligibility Upload',
              authorizedUserTypes: [UserTypeEnum.Admin],
              authorizedRight: [UserRightCodeEnum.MasterEligibilityUpload],
              icon: faFile,
              subMenuLinks: [
                {
                  linkName: 'Manage Eligibility Upload',
                  link: RouteEnum.ManageMasterEligibilityTemplate,
                },
                {
                  linkName: 'Files Management',
                  link: RouteEnum.MasterFileManagement,
                },
                {
                  linkName: 'Email Management',
                  link: RouteEnum.MasterEligibilityEmailManagement,
                },
              ],
            },
            {
              linkName: 'Email',
              icon: faEnvelope,
              subMenuLinks: [
                {
                  linkName: 'Configuration',
                  link: RouteEnum.EmailConfiguration,
                },
                {
                  linkName: 'Templates',
                  link: RouteEnum.EmailTemplate,
                },
                {
                  linkName: 'Manage Emails',
                  link: RouteEnum.EmailManagement,
                },
              ],
            },
            {
              linkName: 'Users',
              icon: faCircleUser,
              subMenuLinks: [
                {
                  linkName: 'Admin Users',
                  link: RouteEnum.AdminUsers,
                },
                {
                  linkName: 'MP Users',
                  link: RouteEnum.ManageAllMpUsers,
                },
              ],
            },
            {
              linkName: 'Reports',
              icon: faFileLines,
              subMenuLinks: [
                {
                  linkName: 'Contact Information Report',
                  link: RouteEnum.ContactInformationReport,
                },
                {
                  linkName: 'SSN Duplicate Records',
                  link: RouteEnum.SsnDuplicateRecordsComponent,
                }
              ],
            },
            {
              linkName: 'New User Registration',
              icon: faUserPlus,
              link: RouteEnum.NewUserRegistration,
            },
            {
              linkName: 'Manage Content',
              icon: faMessage,
              authorizedUserTypes: [UserTypeEnum.Admin],
              subMenuLinks: [
                {
                  linkName: 'Manage Content',
                  icon: faMessage,
                  link: RouteEnum.ManageContent,
                  authorizedUserTypes: [UserTypeEnum.Admin],
                },
                {
                  linkName: 'Manage Home Page Image',
                  icon: faMessage,
                  link: RouteEnum.ManageContentPicture,
                  authorizedUserTypes: [UserTypeEnum.Admin],
                },
                {linkName: 'Tutorials',
                  icon: faInfo,
                  link: RouteEnum.ManageTutorials,
                  authorizedUserTypes: [UserTypeEnum.Admin],
                  authorizedRight: [UserRightCodeEnum.ManageTutorials],
                },
              ],
            },
            {
              linkName: 'Email Log',
              icon: faClockRotateLeft,
              link: RouteEnum.EmailLog,
            },
            {
              linkName: 'Product Management',
              icon: faTableList,
              link: RouteEnum.ProductsManagement,
            },
            {
              linkName: 'Onboarding',
              icon: faCircleUser,
              link: RouteEnum.Onboarding,
              customAuthorization: (): boolean => {
                let ssoPrivileges = this.authService.SsoPrivilege;
                return ssoPrivileges != null && ssoPrivileges.hasOnboarding;
              },
            },
            {
              linkName: 'CMS',
              icon: faCircleUser,
              link: RouteEnum.CMS,
              customAuthorization: (): boolean => {
                let ssoPrivileges = this.authService.SsoPrivilege;
                return ssoPrivileges != null && ssoPrivileges.hasCms;
              },
            },
            {
              linkName: 'Replication Status',
              icon: faClockRotateLeft,
              link: RouteEnum.ReplicationStatus,
              customAuthorization: (): boolean => {
                let ssoPrivileges = this.authService.SsoPrivilege;
                return ssoPrivileges != null && ssoPrivileges.hasReplication;
              },
            },
            {
              linkName: 'Version History',
              icon: faBriefcase,
              authorizedRight: [UserRightCodeEnum.ManageUsers],
              subMenuLinks: [
                {
                  linkName: 'View Latest',
                  link: RouteEnum.ViewLatestVersionHistory,
                },
                {
                  linkName: 'View Archived',
                  link: RouteEnum.ViewArchivedVersionHistory,
                },
              ],
            },
          ],
        },
      ],
    };
  }

  private masterEmployerView (): AuthorizedUserView {
    return {
      viewType: ViewType.MasterEmployerView,
      allowedUserTypes: [UserTypeEnum.MasterEmployer],
      userModule: [RegionCodes.DDCA, RegionCodes.DDIC, RegionCodes.DDNJ, RegionCodes.DDPA],
      primaryRoute: RouteEnum.MasterEmployerClients,
      menuLinksGroups: [
        {
          GroupName: 'Master Employer',
          MenuLinks: [
            {
              linkName: 'Employer Groups',
              icon: faBuilding,
              link: RouteEnum.MasterEmployerClients,
              isPrimary: true,
            },
          ],
        },
        {
          GroupName: 'Site Navigation',
          MenuLinks: [
            {
              linkName: 'Business Program',
              link: RouteEnum.BusinessProgram,
              icon: faBriefcase,
            },
            {
              linkName: 'FAQs',
              link: RouteEnum.Faq,
              icon: faUserCheck,
            },
            {
              linkName: 'Links',
              link: RouteEnum.Links,
              icon: faEllipsis,
            },
            {
              linkName: 'Contact Us',
              link: RouteEnum.ContactUs,
              icon: faIdCard,
            },
          ],
        },
      ],
    };
  }

  private gaView(): AuthorizedUserView {
    return {
      viewType: ViewType.GaView,
      allowedUserTypes: [UserTypeEnum.Admin, UserTypeEnum.Ga],
      userModule: [RegionCodes.MP, RegionCodes.DDCA, RegionCodes.DDIC, RegionCodes.DDNJ, RegionCodes.DDPA],
      primaryRoute: RouteEnum.GaClients, 
      menuLinksGroups : [
        {
          GroupName: 'General Agent',
          MenuLinks: [
            {
              linkName: 'Employer Groups',
              icon: faBuilding,
              link: RouteEnum.GaClients, 
              isPrimary: true,
            },
            {
              linkName: 'Manage GA Users',
              icon: faUsers,
              authorizedUserTypes: [UserTypeEnum.Admin],
              link: RouteEnum.ManageGAUSers,
            },
          ],
        },
        
        {
          GroupName: 'Site Navigation',
          MenuLinks: [
            {
              linkName: 'Business Program',
              link: RouteEnum.BusinessProgram,
              icon: faBriefcase,
            },
            {
              linkName: 'FAQs',
              link: RouteEnum.Faq,
              icon: faUserCheck,
            },
            {
              linkName: 'Links',
              link: RouteEnum.Links,
              icon: faEllipsis,
            },
            {
              linkName: 'Contact Us',
              link: RouteEnum.ContactUs,
              icon: faIdCard,
            },
          ],
        }
      ]
    }
  }
  private producerView (): AuthorizedUserView {
    return {
      viewType: ViewType.ProducerView,
      allowedUserTypes: [UserTypeEnum.Admin, UserTypeEnum.Producer],
      userModule: [RegionCodes.MP, RegionCodes.DDCA, RegionCodes.DDIC, RegionCodes.DDNJ, RegionCodes.DDPA],
      primaryRoute: RouteEnum.Home,
      menuLinksGroups: [
        {
          GroupName: 'Producer Account',
          MenuLinks: [
            {
              linkName: 'Home',
              link: RouteEnum.Home,
              icon: faHome,
            },
            {
              linkName: 'Your Clients',
              icon: faBuilding,
              link: RouteEnum.YourClients,
              isPrimary: true,
            },
            {
              linkName: 'Marketing Resources',
              icon: faBriefcase,
              link: RouteEnum.MarketingResources,
            },
            {
              linkName: 'Commission Statements',
              icon: faEnvelope,
              link: RouteEnum.CommissionStatements,
            },
            {
              linkName: 'Documents',
              icon: faUserCircle,
              link: RouteEnum.ProducerDocuments,
            },
            {
              linkName: 'Producer Profile',
              icon: faUserCircle,
              link: RouteEnum.Profile,
            },
            {
              linkName: 'Sub Producers',
              icon: faUserGear,
              authorizedUserTypes: [UserTypeEnum.Admin, UserTypeEnum.Producer],
              subMenuLinks: [
                {
                  linkName: 'Sub-Producers Access',
                  icon: faUsers,
                  //TODO - SET ACCESS CORRECTLY
                  link: RouteEnum.SubProducersAccess,
                },
                {
                  linkName: 'Sub-Producers Groups',
                  icon: faBuilding,
                  //TODO - SET ACCESS CORRECTLY
                  link: RouteEnum.SubProducersGroups,
                },
              ],
            },
            {
              linkName: 'Manage MP / Sub Producer Users',
              icon: faUsers,
              authorizedUserTypes: [UserTypeEnum.Admin],
              link: RouteEnum.ManageMpUsers,
            },
          ],
        },
        {
          GroupName: 'Site Navigation',
          MenuLinks: [
            {
              linkName: 'Business Program',
              link: RouteEnum.BusinessProgram,
              icon: faBriefcase,
            },
            {
              linkName: 'Tutorials',
              link: RouteEnum.Tutorials,
              icon: faGraduationCap,
            },
            {
              linkName: 'FAQs',
              link: RouteEnum.Faq,
              icon: faUserCheck,
            },
            {
              linkName: 'Links',
              link: RouteEnum.Links,
              icon: faEllipsis,
            },
            {
              linkName: 'Contact Us',
              link: RouteEnum.ContactUs,
              icon: faIdCard,
            },
          ],
        },
      ],
    };
  }


  private baBenefitAdministratorBaUserView (): AuthorizedUserView {
    return {
      viewType: ViewType.BenefitAdministratorView,
      allowedUserTypes: [UserTypeEnum.Producer],
      userModule: [RegionCodes.Producer],
      primaryRoute: RouteEnum.Home,
      menuLinksGroups: [
        {
          GroupName: 'Producer Account',
          MenuLinks: [
            {
              linkName: 'Your Clients',
              icon: faUserCircle,
              link: RouteEnum.YourClients,
            },
            {
              linkName: 'Marketing Resources',
              icon: faBriefcase,
              link: RouteEnum.MarketingResources,
            },
            {
              linkName: 'Commission Statements',
              icon: faEnvelope,
              link: RouteEnum.CommissionStatements,
            },
            {
              linkName: 'Documents',
              link: RouteEnum.ProducerDocuments,
              icon: faClipboardList,
            },
            {
              linkName: 'Producer Profile',
              icon: faUserCircle,
              link: RouteEnum.Profile,
            },
            {
              linkName: 'Manage MP Users',
              icon: faUserCircle,
              link: RouteEnum.ManageMpUsers,
              authorizedUserTypes: [UserTypeEnum.Admin],
            },
            {
              linkName: 'Manage XML',
              icon: faFile,
              authorizedUserTypes: [UserTypeEnum.BaProducer, UserTypeEnum.NewBaProducer],
              subMenuLinks: [
                {
                  linkName: 'Xml Interface',
                  link: RouteEnum.ManageEligibilityXmlTemplate,
                },
                {
                  linkName: 'File Management',
                  link: RouteEnum.XmlFileManagement,
                },
                {
                  linkName: 'Email Management',
                  link: RouteEnum.EligibilityEXmlEmailManagement,
                },
              ],
            },
          ],
        },
        {
          GroupName: 'Site Navigation',
          MenuLinks: [
            {
              linkName: 'Home',
              link: RouteEnum.Home,
              icon: faHome,
            },
            {
              linkName: 'Allied Benefit Suite',
              link: RouteEnum.AlliedBenefitSuite,
              icon: faIdCard,
            },
            {
              linkName: 'Business Program',
              link: RouteEnum.BusinessProgram,
              icon: faBriefcase,
            },
            {
              linkName: 'FAQs',
              link: RouteEnum.Faq,
              icon: faUserCheck,
            },
            {
              linkName: 'Links',
              link: RouteEnum.Links,
              icon: faEllipsis,
            },
            {
              linkName: 'Contact Us',
              link: RouteEnum.ContactUs,
              icon: faIdCard,
            },
          ],
        },
      ],
    };
  }

  private baBenefitAdministratorNewBaUserView (): AuthorizedUserView {
    return {
      viewType: ViewType.BenefitAdministratorNewBaUserView,
      allowedUserTypes: [UserTypeEnum.Producer],
      userModule: [RegionCodes.Producer],
      primaryRoute: RouteEnum.XMLYourClients,
      menuLinksGroups: [
        {
          GroupName: 'Producer Account',
          MenuLinks: [
            {
              linkName: 'Documents',
              link: RouteEnum.ProducerDocuments,
              icon: faClipboardList,
            },
            {
              linkName: 'Producer Profile',
              icon: faUserCircle,
              link: RouteEnum.Profile,
            },
            {
              linkName: 'XML Clients',
              icon: faUserCircle,
              link: RouteEnum.XMLYourClients,
            },
            {
              linkName: 'Manage Groups',
              authorizedUserTypes: [UserTypeEnum.NewBaProducer],
              icon: faUserCircle,
              link: RouteEnum.ManageBaGroups,
            },
            {
              linkName: 'Manage XML',
              icon: faFile,
              authorizedUserTypes: [UserTypeEnum.BaProducer, UserTypeEnum.NewBaProducer],
              subMenuLinks: [
                {
                  linkName: 'Xml Interface',
                  link: RouteEnum.ManageEligibilityXmlTemplate,
                },
                {
                  linkName: 'File Management',
                  link: RouteEnum.XmlFileManagement,
                },
                {
                  linkName: 'Email Management',
                  link: RouteEnum.EligibilityEXmlEmailManagement,
                },
              ],
            },
          ],
        },
        {
          GroupName: 'Site Navigation',
          MenuLinks: [
            {
              linkName: 'Home',
              link: RouteEnum.Home,
              icon: faHome,
            },
            {
              linkName: 'Allied Benefit Suite',
              link: RouteEnum.AlliedBenefitSuite,
              icon: faIdCard,
            },
            {
              linkName: 'Business Program',
              link: RouteEnum.BusinessProgram,
              icon: faBriefcase,
            },
            {
              linkName: 'FAQs',
              link: RouteEnum.Faq,
              icon: faUserCheck,
            },
            {
              linkName: 'Links',
              link: RouteEnum.Links,
              icon: faEllipsis,
            },
            {
              linkName: 'Contact Us',
              link: RouteEnum.ContactUs,
              icon: faIdCard,
            },
          ],
        },
      ],
    };
  }

  private employerView (): AuthorizedUserView {
    return {
      viewType: ViewType.EmployerView,
      allowedUserTypes: [UserTypeEnum.Admin, UserTypeEnum.Employer, UserTypeEnum.Producer, UserTypeEnum.Ga],
      userModule: [RegionCodes.DDCA, RegionCodes.DDIC, RegionCodes.DDNJ, RegionCodes.DDPA],
      primaryRoute: RouteEnum.Employees,
      menuLinksGroups: [
        {
          GroupName: 'Account',
          MenuLinks: [
            {
              linkName: 'Home',
              link: RouteEnum.Home,
              icon: faHome,
            },
            {
              linkName: 'Employees',
              link: RouteEnum.Employees,
              icon: faUsers,
              isPrimary: true,
            },
            {
              linkName: 'Add Employee',
              link: RouteEnum.AddEmployee,
              icon: faUserPlus,
            },
            {
              linkName: 'Open Enrollment',
              link: RouteEnum.OpenEnrollment,
              icon: faUserPlus,
            },
            {
              linkName: 'Qualifying Event',
              link: RouteEnum.QualifyingEvent,
              icon: faUserPlus,
            },
            {
              linkName: 'Supplies',
              link: RouteEnum.Supplies,
              icon: faParachuteBox,
            },
            {
              linkName: 'Company Profile',
              link: RouteEnum.EditCompanyProfile,
              icon: faLandmark,
            },
            {
              linkName: 'Documents',
              link: RouteEnum.EmployerDocuments,
              icon: faClipboardList,
            },
            {
              linkName: 'Online Payment',
              link: RouteEnum.OnlinePayment,
              authorizedUserTypes: [UserTypeEnum.Employer, UserTypeEnum.Admin, UserTypeEnum.MasterEmployer],
              icon: faCreditCard,
            },
            {
              linkName: 'Invoices',
              icon: faFile,
              subMenuLinks: [
                {
                  linkName: 'Detailed Invoice',
                  link: RouteEnum.Invoices,
                  icon: faFilePdf,
                },
                {
                  linkName: 'Detailed Invoice',
                  link: RouteEnum.InvoicesExcel,
                  icon: faFileExcel,
                },
              ],
            },
            {
              linkName: 'Manage ER Users',
              link: RouteEnum.ManageEmployeUsers,
              authorizedUserTypes: [UserTypeEnum.Admin],
              authorizedRight: [UserRightCodeEnum.ManageERUser],
              icon: faFile,
            },
            {
              linkName: 'Eligibility Access',
              authorizedUserTypes: [UserTypeEnum.Admin, UserTypeEnum.Employer, UserTypeEnum.MasterEmployer],
              link: RouteEnum.EligibilityAccess,
              icon: faUsers,
            },
            {
              linkName: 'Manage Eligibility Upload',
              authorizedUserTypes: [
                UserTypeEnum.Admin,
                UserTypeEnum.Producer,
                UserTypeEnum.Ga,
                UserTypeEnum.Employer,
                UserTypeEnum.BaProducer,
                UserTypeEnum.NewBaProducer,
              ],
              authorizedRight: [UserRightCodeEnum.ManageEligibilityUpload, UserRightCodeEnum.ManageEligibility],
              icon: faFile,
              subMenuLinks: [
                {
                  linkName: 'Manage Eligibility Upload',
                  link: RouteEnum.ManageEligibilityTemplate,
                },
                {
                  linkName: 'File Management',
                  link: RouteEnum.FileManagement,
                },
                {
                  linkName: 'Email Management',
                  link: RouteEnum.EligibilityEmailManagement,
                },
              ],
            },
            {
              linkName: 'Manage XML',
              authorizedUserTypes: [
                UserTypeEnum.Admin,
                UserTypeEnum.Producer,
                UserTypeEnum.Ga,
                UserTypeEnum.Employer,
                UserTypeEnum.BaProducer,
                UserTypeEnum.NewBaProducer,
              ],
              authorizedRight: [UserRightCodeEnum.ManageEligibilityUpload, UserRightCodeEnum.ManageEligibility],
              icon: faFile,
              subMenuLinks: [
                {
                  linkName: 'Xml Interface',
                  link: RouteEnum.ManageEligibilityXmlTemplate,
                },
                {
                  linkName: 'File Management',
                  link: RouteEnum.XmlFileManagement,
                },
                {
                  linkName: 'Email Management',
                  link: RouteEnum.EligibilityEXmlEmailManagement,
                },
              ],
            },
          ],
        },
        {
          GroupName: 'Site Navigation',
          MenuLinks: [
            {
              linkName: 'Business Program',
              link: RouteEnum.BusinessProgram,
              icon: faBriefcase,
            },
            {
              linkName: 'Tutorials',
              link: RouteEnum.Tutorials,
              icon: faGraduationCap,
            },
            {
              linkName: 'FAQs',
              link: RouteEnum.Faq,
              icon: faUserCheck,
            },
            {
              linkName: 'Links',
              link: RouteEnum.Links,
              icon: faEllipsis,
            },
            {
              linkName: 'Contact Us',
              link: RouteEnum.ContactUs,
              icon: faIdCard,
            },
          ],
        },
      ],
    };
  }

  private regionView (): AuthorizedUserView {
    return {
      viewType: ViewType.RegionView,
      allowedUserTypes: [UserTypeEnum.Admin, UserTypeEnum.Producer],
      userModule: [RegionCodes.DDCA, RegionCodes.DDIC, RegionCodes.DDNJ, RegionCodes.DDPA],
      primaryRoute: RouteEnum.SelectEmployer,
      menuLinksGroups: [
        {
          GroupName: 'Admin',
          MenuLinks: [
            {
              linkName: 'Dashboard',
              icon: faMessage,
              link: RouteEnum.Dashboard,
              authorizedUserTypes: [UserTypeEnum.Admin],
            },
            {
              linkName: 'Employer',
              icon: faBuilding,
              isPrimary: true,
              subMenuLinks: [
                {
                  linkName: 'Select Employer',
                  link: RouteEnum.SelectEmployer,
                  isPrimary: true,
                  authorizedUserTypes: [UserTypeEnum.Admin],
                },
                {
                  linkName: 'Manage Supplies',
                  link: RouteEnum.ManageSUpplies,
                  authorizedUserTypes: [UserTypeEnum.Admin],
                },
              ],
            },
            {
              linkName: 'Master ID',
              link: RouteEnum.ManageEmployerLink,
              icon: faCity,
              isPrimary: true,
            },
            {
              linkName: 'Email',
              icon: faEnvelope,
              subMenuLinks: [
                {
                  linkName: 'Template',
                  link: RouteEnum.EmailTemplate,
                },
              ],
              authorizedUserTypes: [UserTypeEnum.Admin],
            },
            {
              linkName: 'Users',
              icon: faUserCircle,
              subMenuLinks: [
                {
                  linkName: 'Employer Users',
                  link: RouteEnum.EmployerUsers,
                  authorizedUserTypes: [UserTypeEnum.Admin],
                },
              ],
            },
            {
              linkName: 'Manage Content',
              icon: faMessage,
              authorizedUserTypes: [UserTypeEnum.Admin],
              subMenuLinks: [
                {
                  linkName: 'Manage Content',
                  icon: faMessage,
                  link: RouteEnum.ManageContent,
                  authorizedUserTypes: [UserTypeEnum.Admin],
                },
                {
                  linkName: 'Manage Home Page Image',
                  icon: faMessage,
                  link: RouteEnum.ManageContentPicture,
                  authorizedUserTypes: [UserTypeEnum.Admin],
                },
              ],
            },
            {
              linkName: 'Manage Invoices',
              link: RouteEnum.ManageInvoices,
              icon: faFile,
              authorizedUserTypes: [UserTypeEnum.Admin],
            },
            {
              linkName: 'Reports',
              icon: faFileLines,
              subMenuLinks: [
                {
                  linkName: 'Login Reports',
                  link: RouteEnum.LoginReports,
                },
                {
                  linkName: 'COBRA Reports',
                  link: RouteEnum.CobraReports,
                },
                {
                  linkName: 'Federal/State COBRA Edit',
                  link: RouteEnum.FederalStateCobraEdit,
                },
                {
                  linkName: 'Open Enrollment Report',
                  link: RouteEnum.OpenEnrollmentReport,
                },
              ],
              authorizedUserTypes: [UserTypeEnum.Admin],
            },
            {
              linkName: 'Web Analytics',
              icon: faMessage,
              link: RouteEnum.WebAnalytics,
              authorizedUserTypes: [UserTypeEnum.Admin],
            },
          ],
        },
      ],
    };
  }
}
