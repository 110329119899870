import { Component, Input, OnInit, ɵsetAllowDuplicateNgModuleIdsForTest } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  faKey,
  faArrowLeft,
  faRotate,
  faUser,
  faUserCheck,
  faEnvelope,
  faPhone,
  faFloppyDisk,
  faClockRotateLeft,
  faLock,
} from '@fortawesome/free-solid-svg-icons';
import randomatic from 'randomatic';
import { Observable, catchError, debounceTime, of, take, tap, throwError } from 'rxjs';
import { AuthenticationUserType } from 'src/app/core/models/api/auth/AuthenticationUserTypeEnum';
import { ProducerUserApiRequest } from 'src/app/core/models/api/auth/requests/update-create-producer-user.request';
import { UserRightCodeEnum } from 'src/app/core/models/api/auth/responses/user/user-rights/user-right-code.enum';
import { GetMasterProducerApiResponse } from 'src/app/core/models/api/master-producer/get-master-producer.response';
import { RouteEnum } from 'src/app/core/models/routes.enum';
import { UserTypeEnum } from 'src/app/core/models/user-type.enum';
import { ViewType } from 'src/app/core/models/user-view.model';
import { AuthService } from 'src/app/core/services/http/auth/auth.service';
import { UserControlService } from 'src/app/core/services/http/user-control.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UserViewService } from 'src/app/core/services/user-view.service';
import { PasswordValidator } from 'src/app/shared/components/form-elements/password.validator';
import { ModalProps } from 'src/app/shared/components/modal/modal.component';
import { LogDetailsInfo } from 'src/app/shared/components/profile/log-details/log-details.component';
import { UserJournal } from 'src/app/shared/components/view-journal/view-journal.component';

@Component({
  selector: 'app-add-edit-producer-user',
  templateUrl: './add-edit-producer-user.component.html',
  styleUrls: ['./add-edit-producer-user.component.scss', '../styles.scss'],
})
export class AddEditProducerUserComponent implements OnInit {
  keyIcon = faKey;
  arrowLeft = faArrowLeft;
  generatePWIcon = faRotate;
  personIcon = faUser;
  personCheckIcon = faUserCheck;
  emailIcon = faEnvelope;
  phoneIcon = faPhone;
  saveIcon = faFloppyDisk;
  historyIcon = faClockRotateLeft;
  resetPasswordButtonIcon = faClockRotateLeft;

  isEdittingUser: boolean;
  userId: number;

  userLocked: boolean = false;
  badLoginAttempts: number = 0;
  lockedUserIcon = faLock;

  producerForm: FormGroup;
  saveLoading: boolean;
  changePasswordLoading: boolean;

  userData$: Observable<GetMasterProducerApiResponse>;
  modalProps: ModalProps;
  showResetPasswordModal: boolean;
  logDetailsInfo: LogDetailsInfo = {};
  openJournal: any;
  producerUsersUrl: string = RouteEnum.ManageAllMpUsers;
  journalInput: UserJournal;
  masterProducerId: string | undefined;

  @Input() isSubProducer: boolean = false;
  @Input() subProducerFirstName: string;
  @Input() subProducerLastName: string;
  @Input() subProducerEmail: string;
  @Input() subProducerPhone: string;
  @Input() subProducerAccessId: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private userControlService: UserControlService,
    private authService: AuthService,
    private notificationService: NotificationService,
    private userViewService: UserViewService
  ) {}

  ngOnInit(): void {
    this.producerUsersUrl =
      this.userViewService.GetUserView() == ViewType.Controller ? RouteEnum.ManageAllMpUsers : RouteEnum.ManageMpUsers;

    this.masterProducerId = this.userViewService.GetCurrentUserViewState()?.MasterProducerId;

    if(this.isSubProducer === false)
    {
      this.userId = Number(this.route.snapshot.paramMap.get('id'));
      this.isEdittingUser = this.userId !== 0;
      
    }else{
      this.producerUsersUrl = RouteEnum.SubProducersAccess;
    }
    this.userData$ = of({} as GetMasterProducerApiResponse);
    this.initForm({});

    if(this.isSubProducer === true)
    {
      this.loadSubProducerInfo();

    }
    this.onUsernameChange();
    this.modalProps = {
      disableButtons: true,
    };

    if (this.isEdittingUser) this.loadData();
  }

  getCustomTitle(){
    if(this.isEdittingUser)
    {
      if(this.isSubProducer)
        return 'Edit Sub Producer User';
      
      return 'Edit MP User'; 
    }
     return 'Add Producer';

  }

  onResetPassword() {
    let passwordField = this.producerForm.get('password');
    let userField = this.producerForm.get('userName');

    let data = this.userData$;
    this.changePasswordLoading = true;
    data.pipe(take(1)).subscribe(res => {
      if (passwordField?.valid && this.userId && userField?.value) {
        this.authService
          .ResetPassword(
            passwordField.value,
            this.userId.toString(),
            userField.value,
            AuthenticationUserType.Producer,
            res.response.MasterProducerId
          )
          .subscribe(res => {
            if (res.status) this.notificationService.success(res.message);
            else this.notificationService.error(res.message);

            this.showResetPasswordModal = false;
            this.changePasswordLoading = false;
          });
      }
    });
  }

  unlockUser(){
    this.notificationService.confirmation("Confirm User Unlock?", () => {
      this.authService.UnlockUser(this.userId ?? 0).subscribe(res => {

        if(res.status === true){
          this.notificationService.success(res.response);
          window.location.reload();

        }else{
          this.notificationService.error(res.response);
        }
      });
    });
  }

  onSubmit(response: GetMasterProducerApiResponse) {
    if (this.producerForm.invalid) {
      this.producerForm.markAllAsTouched();
      return;
    }

    let { userName, firstName, lastName, password, email, phoneNumber, disabled, allowedToSeeCommissionStatements } = this.producerForm.value;
    let userPayload = {
      FirstName: firstName,
      Username: userName,
      LastName: lastName,
      Password: password,
      Email: email,
      Phone: phoneNumber,
      Disabled: disabled,
      MasterProducerId: this.isEdittingUser ? response.response.MasterProducerId : this.masterProducerId,
      UserID: this.userId === 0 ? '' : this.userId,
      Type: AuthenticationUserType.Producer,
      IsSubProducer: this.isSubProducer,
      SubProducerAccessId: this.subProducerAccessId,
      AllowedToSeeCommissionStatements: allowedToSeeCommissionStatements
    } as ProducerUserApiRequest;

    this.saveLoading = true;
    this.userControlService
      .CreateOrUpdateUser(userPayload)
      .pipe(
        catchError(error => {
          this.notificationService.error('Something went wrong');
          this.saveLoading = false;
          return throwError(() => error);
        })
      )
      .subscribe(res => {
        if (res.status) {
          this.notificationService.success(res.message);
          this.router.navigate([this.producerUsersUrl]);
        } else this.notificationService.error(res.message);
        this.saveLoading = false;
      });
  }

  onGeneratePassword() {
    this.producerForm.get('password')?.patchValue(randomatic('Aa0!', 10));
  }

  onViewJournalClick() {
    this.openJournal = true;
  }

  private initForm(producerForm: ProducerFormFields) {
    let passwordValidations = this.isEdittingUser
      ? []
      : [Validators.minLength(8), PasswordValidator.strong, Validators.required];

    this.producerForm = this.fb.group({
      userName: [producerForm?.userName ?? '', Validators.required],
      firstName: [producerForm?.firstName ?? '', Validators.required],
      lastName: [producerForm?.lastName ?? '', Validators.required],
      password: [producerForm?.password ?? '', passwordValidations],
      email: [producerForm?.email ?? '', [Validators.required, Validators.email]],
      phoneNumber: [producerForm?.phoneNumber ?? '', [Validators.required,Validators.minLength(10), Validators.maxLength(10)]],
      masterProducerName: [{ value: producerForm?.masterProducerName ?? '', disabled: true }, Validators.required],
      disabled: [producerForm?.disabled ?? false, Validators.required],
      allowedToSeeCommissionStatements: [producerForm?.allowedToSeeCommissionStatements  === true]
    });
  }

  loadSubProducerInfo()
  {
    this.producerForm.patchValue({
      firstName: this.subProducerFirstName,
      lastName: this.subProducerLastName,
      email: this.subProducerEmail,
      phoneNumber: this.subProducerPhone
    });
  }

  private loadData() {
    this.userData$ = this.userControlService.GetMasterProducer(this.userId.toString()).pipe(
      tap(res => {
        let data: ProducerFormFields = {
          disabled: res.response.Disabled,
          email: res.response.Email,
          firstName: res.response.FirstName,
          lastName: res.response.LastName,
          masterProducerName: res.response.ProducerName,
          phoneNumber: res.response.Phone,
          userName: res.response.Username,
          allowedToSeeCommissionStatements: res.response.AllowedToSeeCommissionStatements == true
        };

        this.userLocked = res.response.IsLockOut;
        this.badLoginAttempts = res.response.BadLoginAttempts;
        this.isSubProducer = res.response.IsSubProducer;

        this.logDetailsInfo = {
          FailedLogins: res.response.Failed_login,
          SuccessLogins: res.response.Success_login,
          TotalLogins: res.response.Failed_login + res.response.Success_login,
          LastLoginAttempt: res.response.Lastlogin,
          LastLoginStatus: res.response.LastloginStatus,
          LastLogOff: res.response.Lastlogoff,
        };

        this.journalInput = { UserId: this.userId, UserType: res.response.Type };

        this.initForm(data);
        this.onUsernameChange();
      })
    );
  }

  private onUsernameChange() {
    this.producerForm
      .get('userName')
      ?.valueChanges.pipe(debounceTime(1000))
      .subscribe((userName: string) => {
        if (!userName || userName.length === 0) return;

        this.authService.CheckUser({ UserName: userName }).subscribe(res => {
          let userNameField = this.producerForm.get('userName');

          if (!res.status) userNameField?.setErrors({ usernameTaken: true });
          else {
            userNameField?.setErrors(null);
          }
        });
      });
  }
}

type ProducerFormFields = {
  userName?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  password?: string;
  phoneNumber?: string;
  disabled?: boolean;
  masterProducerName?: string;
  allowedToSeeCommissionStatements?: boolean;
};
