import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';
import { formatDate } from 'src/app/core/helpers/date-helpers';
import { GaUser } from 'src/app/core/models/api/user/ga-user.response';
import { RouteEnum } from 'src/app/core/models/routes.enum';
import { UserViewState } from 'src/app/core/models/user-view-state.model';
import { ViewType } from 'src/app/core/models/user-view.model';
import { GaUsersService } from 'src/app/core/services/http/ga-users.service';
import { UserViewService } from 'src/app/core/services/user-view.service';
import { DisplayedColumns } from 'src/app/shared/components/table/table.component';

@Component({
  selector: 'app-manage-ga-users',
  templateUrl: './manage-ga-users.component.html',
  styleUrls: ['./manage-ga-users.component.scss'],
})
export class ManageGaUsersComponent implements OnInit {
  viewState: UserViewState | null;
  data$: Observable<GaUser[]>;
  displayedColumns: DisplayedColumns[];
  view: ViewType | undefined;

  @ViewChild('actionColumnTemplate', { static: true }) actionColTemplate: TemplateRef<unknown>;
  @ViewChild('disabledColumnTemplate', { static: true }) disabledColumnTemplate: TemplateRef<unknown>;
  @ViewChild('dateColumnTemplate', { static: true }) dateColumnTemplate: TemplateRef<unknown>;
  editIcon = faPencil;
  editGaUrl = RouteEnum.EditGaUser;
  addGaUrl = RouteEnum.AddGaUser;


  constructor(private gaUserService: GaUsersService, private userViewService: UserViewService) {}

  ngOnInit(): void {
    this.viewState = this.userViewService.GetCurrentUserViewState();

    this.displayedColumns = [
      { columnName: 'Username', label: 'UserName', sortable: true },
      { columnName: 'FirstName', label: 'First Name' },
      { columnName: 'Email', label: 'Email', sortable: true },
      { columnName: 'Phone', label: 'Phone' },
      { columnName: 'Lastlogin', label: 'Last Login', template: this.dateColumnTemplate },
      { columnName: 'Disabled', label: 'Disabled', template: this.disabledColumnTemplate },
      { columnName: 'GaNr', label: 'GA NR' },

      {
        columnName: 'Action',
        label: 'Action',
        template: this.actionColTemplate,
        enableFullDataColumnTemplateContext: true,
      },
    ];

    if (this.viewState?.GaId) {
      this.data$ = this.gaUserService.GetGaUsers(Number(this.viewState.GaId));
    }
  }

  customFormatDate(date: string) {
    return formatDate(date);
  }

  get isControllerView() {
    return this.view == ViewType.Controller;
  }
}
