import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, filter } from 'rxjs';
import { UserViewState } from '../models/user-view-state.model';
import { UserTypeEnum } from '../models/user-type.enum';
import { MenuLink, MenuLinksGroups, ViewType } from '../models/user-view.model';
import { UserRightCodeEnum } from '../models/api/auth/responses/user/user-rights/user-right-code.enum';
import { Router } from '@angular/router';
import { RegionCodes } from '../models/api/auth/responses/get-regions.response';
import { RouteEnum } from '../models/routes.enum';
import { UserViewConfigurationService } from './user-view-configuration.service';
import { StaticPage, getStaticPageByRegionAndPageName } from '../helpers/view-helper';
import { VerticalMenuService } from './vertical-menu.service';
import { ViewportScroller } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class UserViewService {
  CurrentUserViewState: Observable<UserViewState | null>;
  CurrentUserViewSubject: BehaviorSubject<UserViewState | null>;
  CurrentUserTemp?: UserViewState;
  CURRENT_VIEW_STATE_STORAGE_NAME = 'CurrentUserViewState';

  constructor (
    private router: Router,
    private configurationService: UserViewConfigurationService,
    private verticalMenuService: VerticalMenuService
  ) {
    this.CurrentUserViewSubject = new BehaviorSubject<UserViewState | null>(this.getCurrentUserFromLocalStorage());
    this.CurrentUserViewState = this.CurrentUserViewSubject.asObservable().pipe(filter(x => x?.Propagate ?? false));
  }

  ShowUserInitialView (userType: UserTypeEnum) {
    let viewState = new UserViewState(userType);
    viewState.SetView(this.getInitialUserViewFromUserType(userType));
    this.changeUserViewState(viewState);
    return this;
  }

  ChangeMasterProducerNr (masterProducerNr: string) {
    this.updateState(viewState => {
      console.log('Changing manage producer nr', masterProducerNr);
      viewState.SetMasterProducerNr(masterProducerNr);
    });
    return this;
  }

  ChangeMasterProducerName (masterProducerName: string) {
    this.updateState(viewState => {
      console.log('Changing manage producer name', masterProducerName);
      viewState.SetMasterProducerName(masterProducerName);
    });
    return this;
  }

  ChangeIsNewBaUser (isNewBaUser: boolean) {
    this.updateState(viewState => {
      console.log('Changing isNewBaUser', isNewBaUser);
      viewState.SetIsNewBaUser(isNewBaUser);
    });
    return this;
  }

  ChangeIsGaUser(isGaUser: boolean) {
    this.updateState(viewState => {
      viewState.SetIsGaUser(isGaUser);
    })
    return this;
  }

  ChangeIsBaUser (isBaUser: boolean) {
    this.updateState(viewState => {
      console.log('Changing IsBaUser', isBaUser);
      viewState.SetIsBaUser(isBaUser);
    });
    return this;
  }

  ChangeQualifyingEvent (qualifyingEvent: boolean) {
    this.updateState(viewState => {
      console.log('Changing qualifying event', qualifyingEvent);
      viewState.SetQualifyingEvent(qualifyingEvent);
    });
  }

  ChangeEmployerManageEligibility (ManageEligibility: boolean) {
    this.updateState(viewState => {
      console.log('Changing manage eligibility to', ManageEligibility);
      viewState.SetEmployerManageEligibility(ManageEligibility);
    });
    return this;
  }

  ChangeRegion (region: RegionCodes) {
    this.updateState(viewState => {
      console.log('Changing region', viewState.ActualRegion, region);
      viewState.SetPrevRegion(viewState.ActualRegion);
      viewState.SetRegion(region);
    });
    return this;
  }

  ChangeViewOnly (viewOnly: boolean) {
    this.updateState(viewState => {
      console.log('Changing ViewOnly', viewState.IsViewOnly, viewOnly);
      viewState.SetViewOnly(viewOnly);
    });
    return this;
  }

  ChangePlanNumber (planNR: string) {
    this.updateState(viewState => {
      console.log('Changing plan number', planNR, viewState.EmployerNumber);
      viewState.SetPlanNumber(planNR);
    });
    return this;
  }

  ChangeEmployerNumber (employerNumber: string) {
    this.updateState(viewState => {
      console.log('Changing employer number', employerNumber, viewState.EmployerNumber);
      viewState.SetEmployerNumber(employerNumber);
    });
    return this;
  }

  ChangeImpersonationState (impersonate: boolean) {
    this.updateState(viewState => {
      console.log('Changing impersonation from', viewState.Impersonate, 'to', impersonate);
      viewState.SetInpersonateOtherViews(impersonate);
    });
    return this;
  }

  ChangeWaitingPeriod (waitingPeriod: string) {
    this.updateState(viewState => {
      console.log('Changing waiting period', waitingPeriod, viewState.EmployerNumber);
      viewState.SetWaitingPeriod(waitingPeriod);
    });
    return this;
  }

  ChangeEmployerOeMonth (oeMonth?: number) {
    if (oeMonth === undefined) return this;
    this.updateState(viewState => {
      console.log('Changing oe month', oeMonth, viewState.EmployerNumber);
      viewState.SetEmployerOeMonth(oeMonth);
    });
    return this;
  }

  ChangeCompanyName (companyName: string) {
    this.updateState(viewState => {
      console.log('Changing companyName', companyName, viewState.EmployerNumber);
      viewState.SetCompanyName(companyName);
    });
    return this;
  }

  ChangeMasterEmployer (masterEmployer: boolean) {
    this.updateState(viewState => {
      console.log('Changing master employer', masterEmployer, viewState.MasterEmployer);
      viewState.SetMasterEmployer(masterEmployer);
    });
    return this;
  }

  ChangeEmployerId (employerId: string) {
    this.updateState(viewState => {
      console.log('Changing employer id', employerId, viewState.EmployerNumber);
      viewState.SetEmployerId(employerId);
    });
    return this;
  }

  ChangeSubProducer () {
    this.updateState(viewState => {
      console.log('Changing sub producer');
      viewState.SetSubProducer(true);
    });
    return this;
  }

  ChangeGaId(GaId: number) {
    this.updateState(viewState => {
      viewState.SetGaId(GaId);
    })
    return this;
  }

  ChangeMasterProducerId (masterProducerId: string) {
    this.updateState(viewState => {
      console.log('Changing Master producer id', masterProducerId, viewState.MasterProducerId);
      viewState.SetMasterProducerId(masterProducerId);
    });
    return this;
  }

  ChangeProducerNameAndRegion (producerName: string, producerRegion: string) {
    this.updateState(viewState => {
      console.log('Changing Master producer name to', producerName);
      viewState.SetMasterProducerName(producerName);
      viewState.SetMasterProducerRegion(producerRegion);
    });
    return this;
  }

  ChangeAbsNewFeed (absNewsFeed: boolean) {
    this.updateState(viewState => {
      console.log('Changing Abs News Feed to', absNewsFeed);
      viewState.SetABSNewFeed(absNewsFeed);
    });
    return this;
  }

  GetSelectedRegion (): RegionCodes {
    let userView = this.getCurrentUserFromLocalStorage();
    if (userView == null) return RegionCodes.None;
    return userView.ActualRegion;
  }

  GetCurrentUserViewState (): UserViewState | null {
    return this.getCurrentUserFromLocalStorage();
  }

  ShowModulePickerView () {
    this.updateState(viewState => viewState.SetModulePickerView(true));
    return this;
  }

  HideModulePickerView () {
    this.updateState(viewState => viewState.SetModulePickerView(false));
    return this;
  }

  ChangeView (newViewType: ViewType) {
    this.updateState(viewState => {
      console.log(`View Changed from ${viewState.ActualView} to ${newViewType}`);
      viewState.SetPrevView(viewState.ActualView);
      viewState.SetView(newViewType);
    });
    return this;
  }

  Propagate (propagate: boolean) {
    this.updateState(viewState => viewState.SetPropagate(propagate));
    return this;
  }

  NavigateToPageView (route: string = '/') {
    this.router.navigate([route]);
    return this;
  }

  RemoveUserState () {
    sessionStorage.removeItem(this.CURRENT_VIEW_STATE_STORAGE_NAME);
    this.CurrentUserViewSubject.next(null);
  }

  GetPrimaryLink (): RouteEnum {
    let currentUser = this.getCurrentUserFromLocalStorage();
    let routeReturned: RouteEnum = RouteEnum.Root;

    if (currentUser === null) return routeReturned;

    let { ActualView } = currentUser;

    let userView = this.configurationService
      .GetUserViewConfiguration()
      .find(userView => userView.viewType == ActualView);

    let isAdminReturingFromView = (view: ViewType) =>
      currentUser &&
      currentUser.PrevView === view &&
      currentUser.UserType === 'Admin' &&
      ActualView === ViewType.Controller &&
      currentUser.MasterProducerId !== null &&
      currentUser.MasterProducerId !== undefined;

    var isAdminReturningFromProducerView = isAdminReturingFromView(ViewType.ProducerView);
    var isAdminReturningFromBaView =
      isAdminReturingFromView(ViewType.BenefitAdministratorNewBaUserView) ||
      isAdminReturingFromView(ViewType.BenefitAdministratorView);

    if (isAdminReturningFromProducerView) routeReturned = RouteEnum.SelectMasterProducer;
    else if (isAdminReturningFromBaView) routeReturned = RouteEnum.SelectBaUsers;
    else if (ActualView === ViewType.EmployerView) {
      if (currentUser.EmployerId !== undefined) {
        if (currentUser.UserType === 'Admin') {
          routeReturned = RouteEnum.Employees;
        } else {
          routeReturned = RouteEnum.Home;
        }
      } else routeReturned = RouteEnum.SelectEmployer;
    } else routeReturned = userView?.primaryRoute ?? RouteEnum.Root;

    this.verticalMenuService.SetActiveMenuLinkByRoute(routeReturned);

    return routeReturned;
  }

  GetUserView (): ViewType | undefined {
    let view = this.getCurrentUserFromLocalStorage();
    return view?.ActualView;
  }

  GetAvaiableUserMenuLinksFromViewState (rights?: UserRightCodeEnum[]): MenuLinksGroups[] {
    let currentUser = this.getCurrentUserFromLocalStorage();

    if (currentUser === null) return [];

    let { ActualView, UserType } = currentUser;

    let userView = this.configurationService
      .GetUserViewConfiguration()
      .find(userView => userView.viewType == ActualView);
    if (!userView) {
      console.log(`UserView not found for ${ActualView}`);
      return [];
    }

    let isUserAllowed = userView.allowedUserTypes.filter(allowedUserType => allowedUserType === UserType);

    if (!isUserAllowed) {
      console.log('User not allowed');
      return [];
    }

    return userView.menuLinksGroups.map(menuLinkGroup => {
      menuLinkGroup.MenuLinks = this.filterLinks(menuLinkGroup.MenuLinks, UserType, rights);
      return menuLinkGroup;
    });
  }

  GetUserStaticPageIdByName (pageName: StaticPage) {

    const currentUser = this.getCurrentUserFromLocalStorage()
    let region = currentUser?.ActualRegion;

    if(currentUser?.UserType === 'GA' && region === RegionCodes.None)
    {
      region = RegionCodes.Producer;
    }

    if (!region) {
      console.debug(
        '[GetUserStaticPageIdByName] Region not specified in the view state. Please ensure the region is valid and try again.'
      );
      return;
    }

    let pageId = getStaticPageByRegionAndPageName(region, pageName);

    if (!pageId) {
      console.debug(
        `[GetUserStaticPageIdByName] Unable to find a static page for the specified region "${region}". Please ensure the region is valid and try again.`
      );
      return;
    }

    return pageId;
  }

  private filterLinks (menuLink: MenuLink[], UserType: UserTypeEnum, rights?: UserRightCodeEnum[]): MenuLink[] {
    return menuLink.filter(menuLink => {
      let userAuthorized = true;
      let hasRight = true;
      let customAuthorization = true;

      if (menuLink.authorizedUserTypes) {
        let userAuthorized = menuLink.authorizedUserTypes.find(x => x == UserType);
        if (!userAuthorized) return false;
      }

      if (rights && menuLink.authorizedRight)
        hasRight = menuLink.authorizedRight?.findIndex(authorizedRight => rights.includes(authorizedRight)) >= 0;

      if (menuLink.customAuthorization) customAuthorization = menuLink.customAuthorization();

      if (menuLink.subMenuLinks) menuLink.subMenuLinks = this.filterLinks(menuLink.subMenuLinks, UserType, rights);

      return userAuthorized && hasRight && customAuthorization;
    });
  }

  private changeUserViewState (userViewState: UserViewState) {
    this.setCurrentUserToLocalStorage(userViewState);
    this.CurrentUserViewSubject.next(userViewState);
  }

  private getInitialUserViewFromUserType (userTypeEnum: UserTypeEnum): ViewType {
    let view = this.configurationService
      .GetInitialViewsConfiguration()
      .filter(initialView => initialView.userType === userTypeEnum);
    if (view.length == 1) {
      return view[0].viewType;
    }
    console.log('Default view not setted. Setting EmployerView as initial');
    return ViewType.EmployerView;
  }

  public GetUser (): UserViewState | null {
    return this.getCurrentUserFromLocalStorage();
  }

  private getCurrentUserFromLocalStorage = (): UserViewState | null => {
    let rawView = sessionStorage.getItem(this.CURRENT_VIEW_STATE_STORAGE_NAME);

    if (rawView == null) return null;
    rawView = JSON.parse(rawView);

    return Object.assign(new UserViewState(UserTypeEnum.None), rawView);
  };

  private setCurrentUserToLocalStorage = (user: UserViewState) =>
    sessionStorage.setItem(this.CURRENT_VIEW_STATE_STORAGE_NAME, JSON.stringify(user));

  private updateState (updateStateFunc: (updateState: UserViewState) => void) {
    let userView = this.getCurrentUserFromLocalStorage();
    if (userView == null) return this;
    updateStateFunc(userView);
    this.changeUserViewState(userView);
    return this;
  }
}
