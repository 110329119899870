import { outputAst } from '@angular/compiler';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { faClockRotateLeft, faEye } from '@fortawesome/free-solid-svg-icons';
import { Observable, map, tap } from 'rxjs';
import { IDocument } from 'src/app/core/models/api/documents.response';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ModalProps } from 'src/app/shared/components/modal/modal.component';
import { DisplayedColumns } from 'src/app/shared/components/table/table.component';
import { EmployerDocumentJournal, ProducerDocumentJournal } from 'src/app/shared/components/view-journal/view-journal.component';
import { FileHandle } from 'src/app/shared/directives/dragDrop.directive';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnInit {
  eye = faEye;
  isAdmin: boolean = false;

  @ViewChild('actionColumnTemplate', { static: true }) actionColTemplate: TemplateRef<unknown>;
  @ViewChild('archivedActionColumnTemplate', { static: true }) archivedActionColTemplate: TemplateRef<unknown>;
  @ViewChild('typeColTemplate', { static: true }) typeColTemplate: TemplateRef<unknown>;
  @ViewChild('fileNameColTemplate', { static: true }) fileNameColTemplate: TemplateRef<unknown>;

  // Upload
  @Input() headlineLabel: string;
  @Input() customTitle: string;
  @Input() openUploadDocumentModal: boolean;
  @Input() showButtons: ShowButtons;
  @Input() showColumns: ShowColumns;
  @Input() modalProps: ModalProps;
  @Input() documents$: IDocument[];
  @Input() isLoading: boolean;
  @Input() sendNotificationButtonLabel: string = 'Send Notification';
  @Input() showViewJournal: boolean = false;
  @Input() journalInput : ProducerDocumentJournal | EmployerDocumentJournal;
  @Input() showArchived: boolean =  true;

  rows$: IDocument[];
  archivedRows$: IDocument[];

  @Output() documentDropped: EventEmitter<File> = new EventEmitter<File>();
  @Output() onDownloadDocumentEvent: EventEmitter<FileEvent> = new EventEmitter<FileEvent>();
  @Output() OnActivateDocumentEvent: EventEmitter<FileEvent> = new EventEmitter<FileEvent>();
  @Output() onUnarchiveFileEvent: EventEmitter<FileEvent> = new EventEmitter<FileEvent>();
  @Output() onArchiveFileEvent: EventEmitter<FileEvent> = new EventEmitter<FileEvent>();
  @Output() onDeleteFileEvent: EventEmitter<FileEvent> = new EventEmitter<FileEvent>();
  @Output() onSendNotificationEvent: EventEmitter<{}> = new EventEmitter<{}>();

  displayedColumns: DisplayedColumns[];

  archivedDocumentsDisplayedColumns: DisplayedColumns[];

  historyIcon = faClockRotateLeft;
  openJournal: boolean = false;

  constructor(
    private notificationService : NotificationService
  ) {}

  ngOnInit() {
    this.displayedColumns = [
      {
        columnName: 'FileName',
        label: 'File Name',
        template: this.fileNameColTemplate,
        enableFullDataColumnTemplateContext: true,
        sortable: true,
      },
      { columnName: 'UploadedDate', label: 'Date', sortable: true },
      { columnName: 'Active', label: 'Active', sortable: true},
      {
        columnName: 'Type',
        label: 'Type',
        template: this.typeColTemplate,
        overrideValueFromColumn: 'FileName',
        sortable: true,
      },
      { columnName: 'Size', label: 'Size (KB)', sortable: true },
      { columnName: 'Downloads', label: 'Count', sortable: true },
      {
        columnName: 'Action',
        label: 'Action',
        template: this.actionColTemplate,
        enableFullDataColumnTemplateContext: true,
      },
    ];
    

    if(!this.customTitle)
    {
      this.customTitle = 'Documents'
    }
    if(!this.headlineLabel)
    {
      this.headlineLabel = `<p>
        In addition to the downloadable documents available here, please visit our Supplies page for more documents
        <br />
        and resources including, but not limited to, the following:
      </p>
      <ul>
        <li>Enrollment forms</li>
        <li>Notices of Privacy Practices</li>
        <li>Instructions on how to find a dentist</li>
      </ul>
      <p>* Please note that the documents below are part of the initial Welcome Kit and are subject to change.</p>`;
    }

    this.archivedDocumentsDisplayedColumns = this.displayedColumns.map(col => {
      if (col.columnName === 'Action') return { ...col, template: this.archivedActionColTemplate };
      return col;
    });

    if(this.showColumns.showCount === false ) {
      this.displayedColumns = this.displayedColumns.filter(col => col.columnName !== 'Downloads');
    }
    if(this.showColumns.showActive === false ) {
      this.displayedColumns = this.displayedColumns.filter(col => col.columnName !== 'Active');
    }
    if(this.showColumns.showSize === false ) {
      this.displayedColumns = this.displayedColumns.filter(col => col.columnName !== 'Size');
    }
    if (this.showColumns.showUserNameField) {
      this.displayedColumns.splice(1, 0, { columnName: 'UploadedByUserName', label: 'User Name', sortable: true });
      this.archivedDocumentsDisplayedColumns.splice(1, 0, {
        columnName: 'UploadedByUserName',
        label: 'User Name',
        sortable: true,
      });
    }

    this.updateDocuments(this.documents$);
  }

  openModal() {
    this.openUploadDocumentModal = false;
    setTimeout(() => {
      this.openUploadDocumentModal = true;
    }, 100);
  }
  // Action buttons
  onDownloadDocument(fileID: number, filename: string) {
    this.onDownloadDocumentEvent.emit({ fileId: fileID.toString(), fileName: filename });
  }

  onActivateDocument(fileId: number, fileName: string) {
    this.OnActivateDocumentEvent.emit({ fileId: fileId.toString(), fileName: fileName });
  }
  onUnarchiveFile(fileID: number, filename: string) {
    this.onUnarchiveFileEvent.emit({ fileId: fileID.toString(), fileName: filename });
  }

  onArchiveFile(fileID: number, filename: string) {
    this.onArchiveFileEvent.emit({ fileId: fileID.toString(), fileName: filename });
  }

  onDeleteFIle(fileID: number, filename: string) {
    this.onDeleteFileEvent.emit({ fileId: fileID.toString(), fileName: filename });
  }

  onUploadDocument(file: FileHandle) {
    this.documentDropped.emit(file.file);
  }

  onSendNotification() {
    this.notificationService.confirmation("Confirm sending notification to all users on this Group?", () => {
      this.onSendNotificationEvent.emit();
    });
  }

  shouldShowViewJournal(){
    return this.showViewJournal === true;
  }

  private updateDocuments(res: IDocument[]) {
    this.rows$ = res.filter(document => !document.Archived);
    this.archivedRows$ = res.filter(document => document.Archived);
  }
}

export type ShowButtons = {
  ShowUploadDocumentButton: boolean;
  ShowSendNotificationButton: boolean;
  ShowDeleteRowButton: boolean;
  ShowArchiveRowButton: boolean;
  ShowUnarchiveRowButton: boolean;
};
export type ShowColumns = {
  showUserNameField: boolean;
  showCount: boolean;
  showSize: boolean;
  showActive: boolean;
};

export type FileEvent = { fileId: string; fileName: string };
