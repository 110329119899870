import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EmployerLinksReponse } from '../../models/api/employer/employer-links.response';
import { environment } from 'src/environments/environment';
import { IsStringNullOrEmpty } from '../../helpers/validation-helpers';
import { EmployersSearchRequest } from '../../models/api/employer/employers-search.request';
import { EmployersSearchResponse } from '../../models/api/employer/employers-search.response';
import { EmployerLinkInput } from '../../models/api/employer/employer-link.input';
import { ViewUserJournalResponse } from '../../models/api/user/view-user-journal.reponse';
import { ApiResponse } from '../../models/api/api-response';
@Injectable({
  providedIn: 'root',
})
export class EmployerLinksService {
  constructor (private http: HttpClient) {}


  GetEmployerLinks():Observable<EmployerLinksReponse[]> {
    return this.http.get<EmployerLinksReponse[]>(`${environment.alliedApi.baseUrl}/employer-links`)
  }

  GetEmployerLinkById(id: number): Observable<EmployerLinksReponse[]> {

    return this.http.get<EmployerLinksReponse[]>(`${environment.alliedApi.baseUrl}/employer-links/${id}`)

  }
  GetMasterEmployerHasEmailSetup(masterId: number){
    return this.http.get<boolean>(`${environment.alliedApi.baseUrl}/employer-links/MasterEmployerHasEmailSetup/${masterId}`);

  }
  GetMasterIdLogs(masterId: number){
    return this.http.get<ViewUserJournalResponse[]>(`${environment.alliedApi.baseUrl}/employer-links/Logs/${masterId}`);
  }

  GetEmployersForUser(): Observable<EmployerLinksReponse[]> {
    return this.http.get<EmployerLinksReponse[]>(`${environment.alliedApi.baseUrl}/employer-links/MasterEmployerClients`)
  }

  ReactivateEmployer(input: EmployerLinkInput): Observable<ApiResponse<Object>>{
    return this.http.post<ApiResponse<Object>>(`${environment.alliedApi.baseUrl}/employer-links/ReactivateEmployer`,input);
  }

  UpdateFlags(input: EmployerLinkInput): Observable<ApiResponse<Object>>{
    return this.http.patch<ApiResponse<Object>>(`${environment.alliedApi.baseUrl}/employer-links/UpdateFlags`,input);
  }

  SetMasterEmployer(input: EmployerLinkInput): Observable<Object>{
    return this.http.post(`${environment.alliedApi.baseUrl}/employer-links/SetMasterEmployer`,input);
  }

  RemoveFromLink(input: EmployerLinkInput): Observable<Object>{
    return this.http.post(`${environment.alliedApi.baseUrl}/employer-links/RemoveEmployerFromLink`,input);
  }

  AddEmployerToLink(input: EmployerLinkInput): Observable<Object>{
    return this.http.post(`${environment.alliedApi.baseUrl}/employer-links/AddEmployerToLink`,input);
  }

  SearchEmployers(
    page: number,
    pageSize: number,
    statusFilter: number = 0,
    search: string = '',
    orderBy: 'asc' | 'desc' = 'asc',
    orderByColumnName: string = 'EmployerNR'
  ) {
    if (IsStringNullOrEmpty(search)) search = ' ';

    return this.http.post<EmployersSearchResponse>(
      `${environment.alliedApi.baseUrl}/employer-links/searchEmployers`,
      new EmployersSearchRequest({
        orderByColumnName: orderByColumnName,
        start: page * pageSize,
        length: pageSize,
        actualPage: page,
        search: search,
        orderBy: orderBy,
        statusFilter: statusFilter,
      }).toRequest(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') }
    );
  }
}
