import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportsRoutes } from './reports.routing';
import { LoginReportsComponent } from './login-reports/login-reports.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CobraReportsComponent } from './cobra-reports/cobra-reports.component';
import { FederalCobraReportComponent } from './federal-cobra-report/federal-cobra-report.component';
import { OpenEnrollmentReportComponent } from './open-enrollment-report/open-enrollment-report.component';
import { ContactInformationReportComponent } from './contact-information-report/contact-information-report.component';
import { SsnDuplicateRecordsComponent } from './ssn-duplicate-records/ssn-duplicate-records.component';

@NgModule({
  imports: [CommonModule, ReportsRoutes, SharedModule, FormsModule, ReactiveFormsModule],
  declarations: [LoginReportsComponent, CobraReportsComponent, FederalCobraReportComponent, OpenEnrollmentReportComponent, ContactInformationReportComponent, SsnDuplicateRecordsComponent],
})
export class ReportsModule {}
