import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { distinctUntilChanged, Observable, take } from 'rxjs';
import { formatDate } from 'src/app/core/helpers/date-helpers';
import { SSNDuplicateRecordResponse } from 'src/app/core/models/api/ssn-duplicate-record.response';
import { FileService } from 'src/app/core/services/file.service';
import { ReportService } from 'src/app/core/services/http/report.service';
import { DisplayedColumns, TableDownloadButtons } from 'src/app/shared/components/table/table.component';

@Component({
  selector: 'app-ssn-duplicate-records',
  templateUrl: './ssn-duplicate-records.component.html',
  styleUrls: ['./ssn-duplicate-records.component.scss'],
})
export class SsnDuplicateRecordsComponent implements OnInit {
  constructor(private fb: FormBuilder, private reportService: ReportService, private fileService: FileService) {}

  ssnDuplicateRecordsForm: FormGroup;
  DownloadButtons: TableDownloadButtons;
  minDate: Date = new Date(2000,1,1)
  maxDate: Date = new Date();
  displayedColumns: DisplayedColumns[];
  ssnReportInfo$: Observable<SSNDuplicateRecordResponse[]>;
  @ViewChild('timeTemplate', { static: true }) timeTemplate: TemplateRef<unknown>;

  ngOnInit(): void {
    this.ssnDuplicateRecordsForm = this.fb.group({
      ssnReportDate: [this.maxDate, Validators.required],
    });

    this.displayedColumns = [
      { columnName: 'ReportDate', label: 'Report Date', template: this.timeTemplate },
      { columnName: 'Region', label: 'Region', sortable: true },
      { columnName: 'EmployerNr', label: 'Employer NR', sortable: true },
      { columnName: 'EmployeeFirstName', label: 'Employee First Name', sortable: true },
      { columnName: 'EmployeeLastName', label: 'Employee Last Name', sortable: true },
      { columnName: 'EmployeeSSN', label: 'Employee SSN', sortable: true },
    ];

    this.loadData();

    const ssnReportDateChange = this.ssnDuplicateRecordsForm.controls.ssnReportDate?.valueChanges.pipe(
      distinctUntilChanged()
    );

    ssnReportDateChange &&
      ssnReportDateChange.subscribe(reportDate => {
        
        if (moment(reportDate, 'L').isAfter(moment())) {
          this.ssnDuplicateRecordsForm.controls.ssnReportDate?.setErrors({customErrorMessage: 'Please select a date that is either today or before.'})
        }else{
          this.loadData();
        }
      });

    this.DownloadButtons = {
      DownloadXLSX: { callback: () => this.downloadXLSX() },
    };
  }

  FormatDate(date: string) {
    return moment(date).format('L')
  }

  private downloadXLSX() {
    this.ssnReportInfo$.pipe(take(1)).subscribe(rows => {
      const reportDate = moment(this.ssnDuplicateRecordsForm.controls.ssnReportDate?.value).format('L');
      this.fileService.GenerateXLSX(
        `Allied Administrators - SSN duplicates - ${reportDate.replace('/', '_')}`,
        this.mapToRowObject(rows)
      );
    });
  }

  mapToRowObject(ssnRecords: SSNDuplicateRecordResponse[]) {
    return ssnRecords.map(record => ({
      ReportDate: moment(record.ReportDate).format('L'),
      Region: record.Region,
      EmployerNr: record.EmployerNr,
      EmployeeFirstName: record.EmployeeFirstName,
      EmployeeLastName: record.EmployeeLastName,
      EmployeeSSN: record.EmployeeSSN,
    }));
  }

  loadData() {
    const date = this.ssnDuplicateRecordsForm.controls.ssnReportDate?.value;
    var reportDate = new Date(date).toISOString();
    if (!date) return;

    //get data
    this.ssnReportInfo$ = this.reportService.GetSsnDuplicateRecord(reportDate);
  }
}
