enum CompanyProfileRoutes {
  ViewCompanyProfile = '/dashboard/company-profile/view-company-profile',
  EditCompanyProfile = '/dashboard/company-profile',
}

enum OnlinePaymentRoutes {}

enum Producer {
  ProducerDocuments = '/dashboard/producer/documents',
}

enum CommonRoutes {
  Root = '/dashboard',
  Supplies = '/dashboard/supplies',
  Invoices = '/dashboard/invoice',
  InvoicesPdf = '/dashboard/invoice/pdf',
  InvoicesExcel = '/dashboard/invoice/excel',
  ManageInvoices = '/dashboard/invoice/manage-invoices',
  OnlinePayment = '/dashboard/online-payment',
  UnderDevelopment = '/dashboard/under-development',
  AddEmployee = '/dashboard/employee/add-employee',
  ManageContent = '/dashboard/manage-content',
  ManageContentPicture ='/dashboard/manage-content-picture',
  Message = '/dashboard/message',
  EmailTemplate = '/dashboard/email/email-template',
  EditEmailTemplate = '/dashboard/email/edit-email-template',
  WebAnalytics = '/dashboard/web-analytics',
  CommissionStatements = '/dashboard/commission-statements',
  YourClients = '/dashboard/master-producer-clients',
  GaClients = '/dashboard/ga-clients',
  SubProducersAccess = '/dashboard/sub-producers-access',
  SubProducersGroups = '/dashboard/sub-producers-groups',
  
  XMLYourClients = '/dashboard/xml-ba-producer-clients',
  MarketingResources = '/dashboard/marketing-resources',
  AddMarketingResources = '/dashboard/add-marketing-resources',
  EditMarketingResources = '/dashboard/edit-marketing-resources',
  Profile = '/dashboard/profile',
  ModulePicker = '/dashboard/module-picker',
  Documents = '/dashboard/documents',
  Dashboard = '/dashboard/chart'
  
}

//Bulk user creation
enum Eligibility {
  ManageMasterEligibilityTemplate = '/dashboard/eligibility/manage-master-eligibility-template',
  MasterFileManagement = '/dashboard/eligibility/master-file-management',
  MasterEligibilityEmailManagement = '/dashboard/email/eligibility/master-email-management',

  ManageEligibilityTemplate = '/dashboard/eligibility/manage-eligibility-template',
  FileManagement = '/dashboard/eligibility/file-management',
  EligibilityEmailManagement = '/dashboard/email/eligibility/email-management',

  ManageEligibilityXmlTemplate = '/dashboard/eligibility/manage-eligibility-xml-template',
  XmlFileManagement = '/dashboard/eligibility/xml-file-management',
  EligibilityEXmlEmailManagement = '/dashboard/email/eligibility/xml-email-management',

  ArchiveLogsXml = '/dashboard/eligibility/archive-logs-xml',
  ArchiveLogsExcel = '/dashboard/eligibility/archive-logs-excel',
}

enum ControllerRoutes {
  Onboarding = '/dashboard/onboarding',
  CMS = '/dashboard/cms',
  NewUserRegistration = '/dashboard/new-user-registration',
  ReplicationStatus = '/dashboard/replication-status',
  EmailLog = '/dashboard/email/email-log',
  ProductsManagement = '/dashboard/products-management',
  ManageGA = '/dashboard/manage-ga',
  EmailConfiguration = '/dashboard/email/email-configuration',
  EmailManagement = '/dashboard/email/emails-management',
  SelectMasterProducer = '/dashboard/select-master-producer',
  ViewLatestVersionHistory = '/dashboard/version-history/latest',
  ViewArchivedVersionHistory = '/dashboard/version-history/archived',
}

enum SiteNavigation {
  Home = '/dashboard/site-navigation/home',
  BusinessProgram = '/dashboard/site-navigation/business-program',
  Faq = '/dashboard/site-navigation/faq',
  Links = '/dashboard/site-navigation/links',
  ContactUs = '/dashboard/site-navigation/contact-us',
  AlliedBenefitSuite = '/dashboard/site-navigation/allied-benefit-suite',
  Tutorials = '/dashboard/site-navigation/tutorials',
  AddTutorial='/dashboard/add-tutorial',
  EditTutorial='/dashboard/edit-tutorial',
  ManageTutorials = '/dashboard//manage-tutorials',
}

enum Reports {
  LoginReports = '/dashboard/reports/login-report',
  CobraReports = '/dashboard/reports/cobra-report',
  FederalStateCobraEdit = '/dashboard/reports/federal-state-cobra-edit',
  OpenEnrollmentReport= '/dashboard/reports/open-enrollment',
  ContactInformationReport = '/dashboard/reports/contact-information',
  SsnDuplicateRecordsComponent = '/dashboard/reports/ssn-duplicate-records'
}

enum AuthRoutes {
  SignIn = '/auth/signin',
}
enum UsersRoutes {
  EmployerUsers = '/dashboard/users/employer-users',
  AdminUsers = '/dashboard/users/admin-users',
  ManageAllMpUsers = '/dashboard/users/manage-all-mp-users',
  ManageMpUsers = '/dashboard/users/manage-mp-users',
  ManageGAUSers = '/dashboard/users/manage-ga-users',
  ManageEmployeUsers = '/dashboard/users/manage-employer-users',
}

enum UserRoutes {
  UserProfile = `/dashboard/user/profile`,
  // Admin
  EditAdminUser = `/dashboard/user/edit-admin-user`,
  AddAdminUser = `/dashboard/user/add-admin-user`,

  // Producer
  EditProducerUser = `/dashboard/user/edit-producer-user`,
  AddProducerUser = `/dashboard/user/add-producer-user`,

  // Employer
  EditEmployerUser = `/dashboard/user/edit-employer-user`,
  AddEmployerUser = `/dashboard/user/add-employer-user`,

  // Add General Agent
  EditGeneralAgentUser =  `/dashboard/user/edit-general-agent-user`,
  ViewGeneralAgentUser =  `/dashboard/user/view-general-agent-user`,
  AddGeneralAgentUser =  `/dashboard/user/add-general-agent-user`,

  // BA
  AddBaUser = '/dashboard/user/add-benefit-administrator',
  EditBaUser = '/dashboard/user/edit-benefit-administrator',

  //GA
  EditGaUser = `/dashboard/user/edit-ga-user`,
  AddGaUser = `/dashboard/user/add-ga-user`

}

enum EmployerRoutes {
  AddSupply = '/dashboard/supplies/add-supply',
  EditSupply = '/dashboard/supplies/edit-supply',
  ManageSUpplies = '/dashboard/supplies/manage-supplies',
  ManageEmployerLink = '/dashboard/employer/manage-links',
  EditEmployerLink = '/dashboard/employer/edit-link',
  AddEmployerLink = '/dashboard/employer/add-link',
  SelectEmployer = '/dashboard/employer/select-employer',
  EmployerDocuments = '/dashboard/employer/documents',
  MasterEmployerClients= '/dashboard/employer/master-employer',
}

enum EmployeeRoutes {
  Employees = '/dashboard/employees',
  AddEmployee = '/dashboard/employee/add-employee',
  EditEmployee = '/dashboard/employee/edit-employee',
  OpenEnrollment = '/dashboard/employee/open-enrollment',
  QualifyingEvent = '/dashboard/employee/qualifying-event',
  ViewEmployee = '/dashboard/employee/view-employee'
}

enum BenefitAdministrators {
  SelectBaUsers = '/dashboard/benefit-administrator/select-ba-users',
  ManageBaGroups = '/dashboard/benefit-administrator/manage-ba-groups',
  EligibilityAccess = '/dashboard/benefit-administrator/eligibility-access',
  ApprovePendingBaAccess = '/dashboard/benefit-administrator/approve-pending-ba-access'
}

export const RouteEnum = {
  ...CommonRoutes,
  ...CompanyProfileRoutes,
  ...OnlinePaymentRoutes,
  ...Producer,
  ...SiteNavigation,
  ...AuthRoutes,
  ...UsersRoutes,
  ...EmployerRoutes,
  ...EmployeeRoutes,
  ...Reports,
  ...ControllerRoutes,
  ...UserRoutes,
  ...Eligibility,
  ...BenefitAdministrators
};
export type RouteEnum =
  | CommonRoutes
  | CompanyProfileRoutes
  | OnlinePaymentRoutes
  | Producer
  | SiteNavigation
  | AuthRoutes
  | UsersRoutes
  | UserRoutes
  | EmployerRoutes
  | EmployeeRoutes
  | ControllerRoutes
  | Reports
  | BenefitAdministrators
  | Eligibility;


  export const getRouteEnumByString = (val: string): RouteEnum | undefined => {
    const routeValues = Object.values(RouteEnum);

    for (const route of routeValues) {
      if (route === val) {
        return route as RouteEnum;
      }
    }

    return undefined;
  };
