import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { faFilePdf, faSheetPlastic } from '@fortawesome/free-solid-svg-icons';
import R from 'ramda';
import { BehaviorSubject, Observable, debounceTime, distinctUntilChanged, skip } from 'rxjs';

@Component({
  selector: 'app-table',
  styleUrls: ['./table.component.scss'],
  template: `
    <div ngClass="wrapper" *ngIf="DisplayedColumns">
      <!-- Table Controls Section -->
      <div ngClass="table_section">
        <div ngClass="table_controls_section" [class.justify_flex_end]="headerItensCount == 1">
          <div style="display: flex; gap: 10px; align-items: center;" *ngIf="DownloadButtons">
            <custom-button
              [forceCapitalization]="true"
              *ngIf="DownloadButtons.DownloadXLSX"
              style="align-self: flex-start;"
              [label]="DownloadButtons.DownloadXLSX.label || 'XLSX'"
              [icon]="iconSheet"
              [color]="'tertiary'"
              (onClick)="DownloadButtons.DownloadXLSX.callback()"></custom-button>
            <custom-button
              [forceCapitalization]="true"
              *ngIf="DownloadButtons.DownloadPDF"
              style="align-self: flex-start;"
              [label]="DownloadButtons.DownloadPDF.label || 'PDF'"
              [icon]="iconPDF"
              [color]="'tertiary'"
              (onClick)="DownloadButtons.DownloadPDF.callback()"></custom-button>
          </div>
          <mat-form-field *ngIf="EnableSearch" appearance="fill" style="width: 25%;">
            <input
              #inputSearch
              [formControl]="searchInputControl"
              matInput
              ngClass="custom_search_input"
              (keyup.enter)="onSearchEvent.emit(inputSearch.value)" />
            <mat-label>Search</mat-label>
          </mat-form-field>
        </div>
      </div>

      <!-- Table Section -->
      <div ngClass="table-responsive">
        <table
          mat-table
          [dataSource]="dataSource"
          matSort
          (matSortChange)="onSort.emit($event)"
          #empTbSortWithObject="matSort">
          <ng-container *ngFor="let item of DisplayedColumns">
            <ng-container [matColumnDef]="item['columnName']">
              <!-- Set th as sortable-->
              <ng-container *ngIf="item['sortable']; else withoutSort">
                <th [mat-sort-header]="item['columnName']" mat-header-cell *matHeaderCellDef [disabled]="IsLoading">
                  {{ item['label'] }}
                </th>
              </ng-container>

              <ng-template #withoutSort>
                <th mat-header-cell *matHeaderCellDef>{{ item['label'] }}</th>
              </ng-template>

              <ng-container *ngIf="IsLoading; else tdLoaded">
                <td mat-cell *matCellDef="let element">
                  <div style="padding-right: 10px;">
                    <app-skeleton-input></app-skeleton-input>
                  </div>
                </td>
              </ng-container>

              <ng-template #tdLoaded>
                <!-- Row Template -->
                <td mat-cell *matCellDef="let element">
                  <ng-container *ngIf="!item.template">
                    {{ element[item.overrideValueFromColumn ? item['overrideValueFromColumn'] : item['columnName']] }}
                  </ng-container>

                  <ng-container *ngIf="item.template">
                    <ng-container *ngIf="item.enableFullDataColumnTemplateContext; else normalTemplate">
                      <ng-template
                        *ngTemplateOutlet="
                          item.template;
                          context: {
                            $implicit: element
                          }
                        "></ng-template>
                    </ng-container>
                    <ng-template #normalTemplate>
                      <ng-template
                        *ngTemplateOutlet="
                          item.template;
                          context: {
                            $implicit:
                              element[
                                item.overrideValueFromColumn ? item['overrideValueFromColumn'] : item['columnName']
                              ]
                          }
                        ">
                      </ng-template>
                    </ng-template>
                  </ng-container>
                </td>
              </ng-template>
            </ng-container>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columnsLabels"></tr>
          <tr mat-row *matRowDef="let row; columns: columnsLabels"></tr>
        </table>
      </div>
      <div *ngIf="isEmpty && emptyRowsTextMessage" style="width: 100%; display: flex; justify-content: center;">
        {{ emptyRowsTextMessage }}
      </div>
      <mat-paginator
        appStylePaginator
        *ngIf="EnablePagination"
        (page)="onPageChange($event)"
        [pageIndex]="Pagination?.Index"
        [length]="Pagination?.Total"
        [pageSize]="Pagination?.PageSize"
        [pageSizeOptions]="[10, 25, 100]"
        showFirstLastButtons>
      </mat-paginator>
    </div>
  `,
})
export class TableComponent<T> implements OnInit, AfterViewInit {
  dataSource: MatTableDataSource<T>;
  constructor() {}
  iconPDF = faFilePdf;
  iconSheet = faSheetPlastic;
  columnsLabels: string[];

  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.EnablePagination && !this.Pagination && this.Rows) {
      this.dataSource.paginator = value;
    }
  }

  @ViewChild('empTbSortWithObject', { static: false })
  set sort(value: MatSort) {
    if (this.EnableLocalSort) this.dataSource.sort = value;
  }

  @ViewChild('inputSearch') inputSearch: ElementRef;

  @Input() DownloadButtons?: TableDownloadButtons;
  /** Show table as loading and It will display one skeleton column by default. To change that, set LoadingColumns input number*/
  @Input() IsLoading: boolean = false;
  @Input() SearchDebounceTime: number = 500;
  @Input() LoadingColumns: number = 1;
  @Input() DisplayedColumns: DisplayedColumns[];
  @Input() EnableSearch: boolean = false;
  @Input() EnableLocalSearch: boolean = false;
  @Input() EnableLocalSort: boolean = true;
  @Input() searchedText?: string;
  @Input() emptyRowsTextMessage?: string;
  @Input() Rows?: T[];

  /** Shows pagination*/
  @Input() EnablePagination?: boolean;
  @Input() Pagination?: ManualPagination;

  @Output() PaginationEvent: EventEmitter<PageEvent> = new EventEmitter();
  @Output() onSearchEvent: EventEmitter<string> = new EventEmitter();
  @Output() onSearchEventObs: EventEmitter<Observable<string>> = new EventEmitter();
  @Output() onSort: EventEmitter<Sort> = new EventEmitter();

  @Input() onSearch: (searchText: string) => Observable<any>;

  searchInputControl = new FormControl();

  ngAfterViewInit(): void {
    if (this.searchedText) {
      this.inputSearch.nativeElement.focus();
    }
  }

  ngOnChanges(_: SimpleChanges) {
    if (!this.Rows) {
      this.IsLoading = true;
      this.dataSource = new MatTableDataSource<T>(R.range(0, this.LoadingColumns).map<T>(_ => ({} as T)));
      return;
    }
    this.IsLoading = false;
    this.dataSource = new MatTableDataSource(this.Rows);
  }

  ngOnInit() {
    this.searchedText && this.searchInputControl.patchValue(this.searchedText);

    if (this.DisplayedColumns)
      this.columnsLabels = this.DisplayedColumns.filter(column => !column.disableColumn).map(
        column => column.columnName
      );

    this.onSearchEventObs.emit(this.searchInputControl.valueChanges);
    this.searchInputControl.valueChanges
      .pipe(debounceTime(this.SearchDebounceTime), skip(1), distinctUntilChanged())
      .subscribe(inputText => {
        if (this.EnableLocalSearch) this.dataSource.filter = inputText.trim().toLowerCase();
        else this.onSearchEvent.emit(inputText as string);
      });

    this.searchInputControl.patchValue(this.searchedText);
  }

  onPageChange($event: PageEvent) {
    this.PaginationEvent.emit($event);
  }

  get headerItensCount() {
    let count = 0;
    if (this.EnableSearch) count++;
    if (this.DownloadButtons) count++;
    return count;
  }

  get isEmpty() {
    let _isEmpty = this.Rows && this.Rows.length == 0 && !this.IsLoading;
    return _isEmpty;
  }
}

export type DisplayedColumns = {
  template?: TemplateRef<any>;
  /** Column names must match row key names*/
  columnName: string;

  /** Name that will be displayed in the header */
  label: string;

  overrideValueFromColumn?: string;

  enableFullDataColumnTemplateContext?: boolean;

  disableColumn?: boolean;
  
  sortable?: boolean;
};

export type TableDownloadButtons = { DownloadPDF?: DownloadItem; DownloadXLSX?: DownloadItem };
type DownloadItem = {
  label?: string;
  callback: () => void;
};

export type ManualPagination = {
  /** Takes control of pagination */
  Total: number;
  ActualPage: number;
  Index: number;
  PageSize: number;
};
