import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { faPencil, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';
import { EmployerLinksReponse } from 'src/app/core/models/api/employer/employer-links.response';
import { RouteEnum } from 'src/app/core/models/routes.enum';
import { EmployerLinksService } from 'src/app/core/services/http/employer-links.service';
import { DisplayedColumns } from 'src/app/shared/components/table/table.component';

@Component({
  selector: 'app-manage-employer-link',
  templateUrl: './manage-employer-link.component.html',
  styleUrls: ['./manage-employer-link.component.scss']
})
export class ManageEmployerLinkComponent implements OnInit {

  constructor(private employerLinkService: EmployerLinksService,
    private router: Router,
  ) { }

  editIcon = faPencil;
  deleteIcon = faTrash;
  addIcon = faPlus;
  editLink = RouteEnum.EditEmployerLink
  linksInfo$: Observable<EmployerLinksReponse[]>;
  activeRows: EmployerLinksReponse[];
  deniedRows: EmployerLinksReponse[];
  archivedRows: EmployerLinksReponse[];
  displayedColumns: DisplayedColumns[];
  @ViewChild('masterIdTemplate', { static: true }) masterIdTemplate: TemplateRef<unknown>;
  @ViewChild('yesNoTemplate', { static: true }) yesNoTemplate: TemplateRef<unknown>;
  @ViewChild('actionTemplate', { static: true }) actionTemplate: TemplateRef<unknown>;


  ngOnInit(): void {

    this.displayedColumns = [
      {
        columnName: 'MasterIdDisplay',
        label: 'Master ID',
        template: this.masterIdTemplate,
        enableFullDataColumnTemplateContext: true,
        sortable: true,
      }, {
        columnName: 'EmployerNumbers',
        label: 'Employer Numbers', sortable: true
      },
      {
        columnName: 'ConsolidatedBilling',
        label: 'Consolidated Billing', sortable: true, template: this.yesNoTemplate
      },
      {
        columnName: 'ConsolidatedReport',
        label: 'Consolidated Report', sortable: true, template: this.yesNoTemplate
      },
      {
        columnName: 'Approved',
        label: 'Approved', sortable: true
      },
      {
        columnName: 'Action',
        label: 'Action',
        overrideValueFromColumn:'MasterId',
        template:this.actionTemplate
      }
    ]

    this.loadData();
  }

  loadData() {
    this.linksInfo$ = this.employerLinkService.GetEmployerLinks()
    this.linksInfo$.subscribe(rows => {
      let active = rows.filter(x => x.Approved === 'Yes' && x.Active === 'Yes');
      let denied = rows.filter(x => x.Approved === 'No');
      let archived = rows.filter(x => x.Active === 'No');

      this.activeRows = active;
      this.deniedRows = denied;
      this.archivedRows = archived;
      
    });
  }

  getMasterId(masterId: number){
    return masterId.toString().padStart(7,'0');
  }

  onNavigateToPage(route: RouteEnum, id?: string) {
    var routeArray: string[] = [route.toString()];
    if (id) routeArray.push(id);

    this.router.navigate(routeArray);
  }

  addLink(){
    this.onNavigateToPage(RouteEnum.AddEmployerLink)
  }

}
