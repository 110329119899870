<app-content-card CustomTitle="Manage Employer Associations">

  <div ngClass="search_filter_wrapper">
    <div ngClass="search_filter"></div>
    <custom-button
      [forceCapitalization]="true"
      style="align-self: flex-end"
      label="CREATE MASTER ID"
      (onClick)="addLink()"
      [icon]="addIcon"></custom-button>
  </div>


  <ng-container *ngIf="linksInfo$ | withLoading | async as linksInfo">
    <mat-tab-group>
      <mat-tab label="Active">
        <p><b>Groups that are currently associated with a Master ID. These groups have the option of a single login and the ability to view all associated groups.</b></p>
        <app-table
          [Rows]="activeRows ?? undefined"
          [IsLoading]="linksInfo.loading ?? false"
          [DisplayedColumns]="displayedColumns"
          [EnableLocalSearch]="true"
          [EnablePagination]="true"
          [EnableSearch]="true"
          [EnableLocalSort]="true">
        </app-table>
      </mat-tab>
      <mat-tab label="Denied">
        <app-table
        [Rows]="deniedRows ?? undefined"
        [IsLoading]="linksInfo.loading ?? false"
        [DisplayedColumns]="displayedColumns"
        [EnableLocalSearch]="true"
        [EnableSearch]="true"
          [EnablePagination]="true"
          [EnableLocalSort]="true">
      </app-table>
      </mat-tab>
      <mat-tab label="Archived">
        <app-table
        [Rows]="archivedRows ?? undefined"
        [IsLoading]="linksInfo.loading ?? false"
        [DisplayedColumns]="displayedColumns"
        [EnableLocalSearch]="true"
          [EnablePagination]="true"
          [EnableSearch]="true"
        [EnableLocalSort]="true">
      </app-table>
      </mat-tab>
    </mat-tab-group>
    
  </ng-container>
  <ng-template #masterIdTemplate let-data>
    <span>{{ getMasterId(data.MasterId) }}</span>
  </ng-template>
  <ng-template #yesNoTemplate let-data>
    <span>{{ data ? 'Yes' : 'No' }}</span>
  </ng-template>
  <ng-template #actionTemplate let-data>
    <div ngClass="action_icons">
      <div (click)="onNavigateToPage(editLink, data)">
        <fa-icon ngClass="action_icon" title="Edit Link" [icon]="editIcon"></fa-icon>
      </div>
      <!-- <div>
        <fa-icon ngClass="action_icon" title="Deactivate Association" [icon]="deleteIcon"></fa-icon>
      </div> -->
    </div>
  </ng-template>
</app-content-card>
