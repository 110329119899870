import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { faEye, faPencil, faPlus } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, map, mapTo, Observable, take, tap } from 'rxjs';
import { formatDate } from 'src/app/core/helpers/date-helpers';
import { UsersAPIResponse } from 'src/app/core/models/api/auth/responses/user/info/user-info.response.model';
import { EmployeeData, GetAllEmployeeResponse } from 'src/app/core/models/api/employee/get-all-employees.response';
import { RouteEnum } from 'src/app/core/models/routes.enum';
import { UserTypeEnum } from 'src/app/core/models/user-type.enum';
import { FileService } from 'src/app/core/services/file.service';
import { AuthService } from 'src/app/core/services/http/auth/auth.service';
import { EmployeeControlService, GetAllEmployeesI } from 'src/app/core/services/http/employee-control.service';
import { EmployerService } from 'src/app/core/services/http/employer.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UserViewService } from 'src/app/core/services/user-view.service';
import { MenuVerticalState, VerticalMenuService } from 'src/app/core/services/vertical-menu.service';
import {
  DisplayedColumns,
  ManualPagination,
  TableDownloadButtons,
} from 'src/app/shared/components/table/table.component';

@Component({
  selector: 'app-employees',
  styleUrls: ['./employees.component.scss'],
  template: `
    <app-content-card CustomTitle="Employees">
    <span *ngIf="isSpecialWaitingPeriod() === false" style="float:right"><b>
    {{getWaitingPeriodMessage()}}</b></span>

      <div ngClass="search_filter_wrapper">
        <div ngClass="search_filter">
            <span>This page displays the list of all Employees.</span>
          <app-radio-button
            (onRadioButtonClick)="onFilter($event)"
            [labels]="['Active', 'Terminated', 'Cobra', 'All']"
            [checked]="checkedFilter.value"></app-radio-button>
            
        </div>
        <custom-button
          [forceCapitalization]="true"
          *ngIf="allowAddEmployee()"
          style="align-self: flex-end;"
          label="ADD EMPLOYEE"
          (onClick)="onAddEmployee()"
          [icon]="addIcon"></custom-button>
      </div>
      <ng-container *ngIf="rows$ | withLoading | async as rows">
        <app-table
          [DisplayedColumns]="displayedColumns"
          [EnablePagination]="true"
          [Pagination]="pagination"
          (PaginationEvent)="onPaginatorEvent($event)"
          (onSearchEvent)="onSearch($event)"
          (onSort)="onSort($event)"
          [EnableSearch]="true"
          [searchedText]="employeeRequest.value.search"
          [IsLoading]="rows.loading ?? false"
          [Rows]="rows.value"
          [DownloadButtons]="downloadButtons"></app-table>
      </ng-container>
    </app-content-card>

    <ng-template #dateTemplate let-data>
    <span>{{data | date:'MM/dd/yyyy'}}</span>
    </ng-template>

    <!-- Column Template Region -->
    <ng-template #actionColumnTemplate let-data>
      <div ngClass="action_icons">
        <div (click)="onNavigateToPage(viwEmployeeLink, data)">
          <fa-icon ngClass="action_icon" [title]="'View employee'" [icon]="viewIcon"></fa-icon>
        </div>
        <a (click)="onNavigateToPage(editEmployeeLink, data)" *ngIf="allowManageEmployees()">
          <fa-icon ngClass="action_icon" [title]="'Edit employee'" [icon]="editIcon"></fa-icon>
        </a>
      </div>
    </ng-template>

    <ng-template #employerNumberTemplate let-data>
      <a ngClass="table_cell_link" [title]="'View ' + data + ' as ER'">{{ data }}</a>
    </ng-template>
  `,
})
export class EmployeesComponent implements OnInit {
  viewIcon = faEye;
  editIcon = faPencil;
  addIcon = faPlus;

  //routes
  editEmployeeLink = RouteEnum.EditEmployee;
  viwEmployeeLink = RouteEnum.ViewEmployee;

  // Controls
  @ViewChild('actionColumnTemplate', { static: true }) actionColTemplate: TemplateRef<unknown>;
  @ViewChild('dateTemplate', { static: true }) dateTemplate: TemplateRef<unknown>;
  checkedFilter: BehaviorSubject<string>;
  employeeRequest: BehaviorSubject<GetAllEmployeesI>;
  employerId: string;
  gaManageEligibility: boolean = true;

  // Async data
  rows$: Observable<EmployeeData[]>;

  // Table variables
  displayedColumns: DisplayedColumns[];
  pagination: ManualPagination | undefined;
  downloadButtons: TableDownloadButtons | undefined;

  constructor(
    private employeeControl: EmployeeControlService,
    private authService: AuthService,
    private userViewService: UserViewService,
    private fileService: FileService,
    private notificationService: NotificationService,
    private verticalMenuService: VerticalMenuService,
    private router: Router,
    private employerService: EmployerService
  ) {}
  ngOnInit() {
    let userState = this.userViewService.GetCurrentUserViewState();
    if (!userState || !userState.EmployerId) {
      this.notificationService.error('Something went wrong, please, login again!');
      return;
    }
    this.employerId = userState.EmployerId;

    if(userState.UserType === UserTypeEnum.Ga)
    {
      this.employerService.GetGaManageEligiblity(this.employerId).subscribe(res => {
        this.gaManageEligibility = res;
      });
    }

    this.checkedFilter = new BehaviorSubject<string>('Active');

    this.displayedColumns = [
      {
        columnName: 'Action',
        label: 'Action',
        overrideValueFromColumn: 'EmployeeID',
        template: this.actionColTemplate,
      },
      { columnName: 'Firstname', label: 'First Name', sortable: true },
      { columnName: 'Lastname', label: 'Last Name', sortable: true },
      // { columnName: 'Deps', label: "Dependent's Name" },
      { columnName: 'ActiveDep', label: 'Dependent Count', sortable: true },
      { columnName: 'SSN', label: 'SSN', sortable: true },
      { columnName: 'Cobra', label: 'Cobra', sortable: true },
      { columnName: 'BirthDate', label: 'Birth Date', sortable: true, template: this.dateTemplate },
      { columnName: 'HireDate', label: 'Hire Date', sortable: true, template: this.dateTemplate },
      { columnName: 'EffectiveDate', label: 'Effective Date', sortable: true, template: this.dateTemplate },
      { columnName: 'TerminationDate', label: 'Termination Date', sortable: true, template: this.dateTemplate },
    ];

    this.downloadButtons = {
      DownloadPDF: {
        callback: () => {
          let headers = [
            {
              Firstname: 'Firstname',
              Lastname: 'Lastname',
              ActiveDep: 'ActiveDep',
              SSN: 'SSN',
              Cobra: 'Cobra',
              BirthDate: 'BirthDate',
              HireDate: 'HireDate',
              EffectiveDate: 'EffectiveDate',
              TerminationDate: 'TerminationDate',
            },
          ];

          this.rows$.pipe(take(1)).subscribe(res => {
            this.fileService
              .GeneratePDF('Allied Administrators - Employees', headers, this.mapToDownloadTableObject(res))
              .save('Allied Administrators - Employees');
          });
        },
      },
      DownloadXLSX: {
        callback: () => {
          this.rows$.pipe(take(1)).subscribe(res => {
            this.fileService.GenerateXLSX('Allied Administrators - Employees', this.mapToDownloadTableObject(res));
          });
        },
      },
    };

    this.employeeRequest = new BehaviorSubject<GetAllEmployeesI>({
      employerId: this.employerId,
      page: 0,
      pageSize: 10,
      orderBy: 'desc',
      statusFilter: this.getStatusFilter(this.checkedFilter.value),
    });

    this.rows$ = this.employeeControl.GetAllEmployees(this.employeeRequest.value).pipe(
      this.updatePagination(0, 10),
      map(res => res.data),
      // this.mapDataToTable()
    );
  }

  getWaitingPeriodMessage(): string {
    let userView = this.userViewService.GetUser();
    let isOe = this.employerService.IsOpenEnrollmentSync(userView?.OeMonth ?? 0);

    if(isOe === true)
    {
      let firstMonthName = this.employerService.GetOpenEnrollmentFirstMonth(userView?.OeMonth ?? 0);
      let secondMonthName = this.employerService.GetMonthName(userView?.OeMonth ?? 0);
      return 'Your group has a non-standard waiting period. Please contact your Account Coordinator to enroll new members during the open enrollment months of '+firstMonthName+' and '+secondMonthName+'.';
    }

    return 'Your group has a non-standard waiting period. Please contact your Account Coordinator to enroll new members.';
  }

  isSpecialWaitingPeriod(){
    let userView = this.userViewService.GetUser();

    if(this.authService.IsAdmin() === false && userView?.WaitingPeriod === 'X') return false;

    return true;
  }
  allowAddEmployee(): boolean {
    let userView = this.userViewService.GetUser();

    if(userView?.IsViewOnly === true) return false;

    if (this.authService.IsMP() === true && userView?.EmployerManageEligibility === false) return false;

    if(this.authService.IsAdmin() === false && userView?.WaitingPeriod === 'X') return false;

    if(this.authService.IsGA() === true) return this.gaManageEligibility;

    return true;
  }

  allowManageEmployees(): boolean {
    let userView = this.userViewService.GetUser();
    if(userView?.IsViewOnly === true) return false;
    
    if (this.authService.IsMP() && userView?.EmployerManageEligibility === false) return false;

    if(this.authService.IsGA() === true) return this.gaManageEligibility;

    return true;
  }

  onNavigateToPage(route: RouteEnum, id?: string) {
    var routeArray: string[] = [route.toString()];
    if (id) routeArray.push(id);

    this.router.navigate(routeArray);
  }

  onSort(sort: Sort) {
    let sortColumn = sort.active;
    let sortDirection: 'asc' | 'desc' = sort.direction === 'asc' ? 'asc' : 'desc';
    this.employeeRequest.next({ ...this.employeeRequest.value, orderByColumnName: sortColumn, orderBy: sortDirection });

    this.rows$ = this.employeeControl.GetAllEmployees(this.employeeRequest.value).pipe(
      this.updatePagination(),
      map(res => res.data),
      // this.mapDataToTable()
    );
  }

  onAddEmployee() {
    this.onNavigateToPage(RouteEnum.AddEmployee);
  }

  onFilter($event: string) {
    this.checkedFilter.next($event);

    this.employeeRequest.next({ ...this.employeeRequest.value, statusFilter: this.getStatusFilter($event) });

    this.rows$ = this.employeeControl.GetAllEmployees(this.employeeRequest.value).pipe(
      this.updatePagination(),
      map(res => res.data),
      // this.mapDataToTable()
    );
  }

  onPaginatorEvent($event: PageEvent) {
    this.employeeRequest.next({ ...this.employeeRequest.value, page: $event.pageIndex, pageSize: $event.pageSize });

    this.rows$ = this.employeeControl.GetAllEmployees(this.employeeRequest.value).pipe(
      this.updatePagination($event.pageIndex, $event.pageSize),
      map(res => res.data),
      // this.mapDataToTable()
    );
  }

  onSearch(searchText: string) {
    this.employeeRequest.next({ ...this.employeeRequest.value, search: searchText, page: 0 });

    this.rows$ = this.employeeControl.GetAllEmployees(this.employeeRequest.value).pipe(
      this.updatePagination(),
      map(res => res.data),
      // this.mapDataToTable()
    );
  }

  private mapToDownloadTableObject(res: EmployeeData[]) {
    return res.map(employee => ({
      Firstname: employee.Firstname,
      Lastname: employee.Lastname,
      ActiveDep: employee.ActiveDep,
      SSN: employee.SSN,
      Cobra: employee.Cobra,
      BirthDate: formatDate(employee.BirthDate,'L'),
      HireDate: formatDate(employee.HireDate,'L'),
      EffectiveDate: formatDate(employee.EffectiveDate,'L'),
      TerminationDate: formatDate(employee.TerminationDate,'L'),
    }));
  }

  private mapDataToTable() {
    return map<EmployeeData[], EmployeeData[]>(res => {
      // return res;

      return res.map(employee => {
        if (employee.EffectiveDate) employee.EffectiveDate = formatDate(employee.EffectiveDate, 'L');
        if (employee.HireDate) employee.HireDate = formatDate(employee.HireDate, 'L');
        if (employee.BirthDate) employee.BirthDate = formatDate(employee.BirthDate, 'L');
        if (employee.TerminationDate) employee.TerminationDate = formatDate(employee.TerminationDate, 'L');
        return employee;
      });
    });
  }

  private updatePagination(index: number = 0, pageSize: number = 10) {
    return tap<GetAllEmployeeResponse>(res => {
      this.pagination = {
        Total: res.recordsFiltered,
        ActualPage: Number(res.draw),
        Index: index,
        PageSize: pageSize,
      };
    });
  }

  private getStatusFilter(checkedItem: string) {
    return (
      new Map<string, number>([
        ['All', 0],
        ['Active', 1],
        ['Terminated', 2],
        ['Cobra', 3],
      ]).get(checkedItem) || 0
    );
  }
}
