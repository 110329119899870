import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { faPencil, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { map, Observable, take } from 'rxjs';
import { SuppliesData } from 'src/app/core/models/api/supply/supplies.response';
import { RouteEnum } from 'src/app/core/models/routes.enum';
import { FileService } from 'src/app/core/services/file.service';
import { SupplyService } from 'src/app/core/services/http/supply.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { DisplayedColumns, TableDownloadButtons } from 'src/app/shared/components/table/table.component';

@Component({
  selector: 'app-manage-supplies',
  styleUrls: ['./manage-supplies.component.scss'],
  template: `
    <app-content-card CustomTitle="Supplies">
      <div style="display: flex; justify-content: space-between; padding: 10px;">
        <span style="font-size: 13px; color: #444">Manage or search supplies to add/edit/remove.</span>
        <custom-button
          [forceCapitalization]="true"
          [icon]="addIcon"
          label="ADD NEW SUPPLY"
          [routerLink]="[addSupplyUrl]"></custom-button>
      </div>
      <ng-container *ngIf="rows$ | withLoading | async as rows">
        <app-table
          [DisplayedColumns]="columns"
          [EnablePagination]="true"
          [EnableLocalSort]="true"
          [IsLoading]="rows.loading ?? false"
          [Rows]="rows.value"
          [DownloadButtons]="downloadButtons"
          [EnableSearch]="true"></app-table>
      </ng-container>
    </app-content-card>

    <!-- Column Template Region -->
    <ng-template #actionColumnTemplate let-data>
      <a [routerLink]="[editSupplyUrl, data.SupplyId]" title="Edit Supply">
        <fa-icon [icon]="editIcon" style="color: #444; margin-right: 10px;"></fa-icon>
      </a>

      <a (click)="onSupplyDelete(data.SupplyId, data.Name)" title="Delete Supply" style="cursor:pointer">
        <fa-icon [icon]="deleteIcon"></fa-icon>
      </a>
    </ng-template>

    <ng-template #nameColumnTemplate let-data>
      <a ngClass="table_cell_link" [routerLink]="[editSupplyUrl, data.SupplyId]" title="Edit Supply">{{ data.Name }}</a>
    </ng-template>
  `,
})
export class ManageSuppliesComponent implements OnInit {
  // Icons
  editIcon = faPencil;
  deleteIcon = faTrash;
  addIcon = faPlus;

  // Controls
  columns: DisplayedColumns[] = [];
  editSupplyUrl: RouteEnum = RouteEnum.EditSupply;
  addSupplyUrl: RouteEnum = RouteEnum.AddSupply;
  downloadButtons: TableDownloadButtons;

  // Async Data
  rows$: Observable<SuppliesData[]>;

  //Column Templates
  @ViewChild('actionColumnTemplate', { static: true }) actionColTemplate: TemplateRef<unknown>;
  @ViewChild('nameColumnTemplate', { static: true }) nameColTemplate: TemplateRef<unknown>;

  constructor(
    private supplyService: SupplyService,
    private fileService: FileService,
    private notificationService: NotificationService
  ) {}

  onSupplyDelete(id: string, name: string) {
    this.notificationService.confirmation(
      `It will delete the supply: ${name}`,
      () => {
        this.supplyService.DeleteSupply(id).subscribe(res => {
          if (!res.Status) {
            this.notificationService.error(res.Message);
            return;
          }

          this.notificationService.success(res.Message);
          this.loadData();
        });
      },
      'Confirm?'
    );
  }

  ngOnInit() {
    this.downloadButtons = {
      DownloadPDF: {
        callback: () => {
          this.rows$.subscribe(res => {
            return this.fileService
              .GeneratePDF(
                'Allied Administrators - Manage Supplies',
                [{ LimitAbs: 'LimitAbs', LimitRel: 'LimitRel', DeltaCareUSA: 'DeltaCareUSA', DELTA: 'DELTA' }],
                this.mapRows(res)
              )
              .save('Allied Administrators - Manage Supplies');
          });
        },
      },
      DownloadXLSX: {
        callback: () => {
          this.rows$.subscribe(res => {
            console.log(res);
            this.fileService.GenerateXLSX('Allied Administrators - Manage Supplies', this.mapRows(res));
          });
        },
      },
    };

    this.columns = [
      {
        columnName: 'Name',
        label: 'Supply',
        template: this.nameColTemplate,
        enableFullDataColumnTemplateContext: true,
        sortable: true,
      },
      { columnName: 'LimitAbs', label: 'Limit/Employer', sortable: true },
      { columnName: 'LimitRel', label: 'Limit/Employee', sortable: true },
      { columnName: 'DeltaCareUSA', label: 'DeltaCare USA', sortable: true },
      { columnName: 'DELTA', label: 'DELTA', sortable: true },
      {
        columnName: 'Action',
        label: 'Action',
        template: this.actionColTemplate,
        enableFullDataColumnTemplateContext: true,
      },
    ];

    this.loadData();
  }

  private mapRows(supplisData: SuppliesData[]) {
    return supplisData.map(res => ({
      LimitAbs: res.LimitAbs,
      LimitRel: res.LimitRel,
      DeltaCareUSA: res.DeltaCareUSA,
      DELTA: res.DELTA,
    }));
  }

  private loadData() {
    this.rows$ = this.supplyService.GetAllSupplies().pipe(map(response => response.Data));
  }
}
