import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  faKey,
  faArrowLeft,
  faRotate,
  faUser,
  faUserCheck,
  faEnvelope,
  faPhone,
  faFloppyDisk,
  faClockRotateLeft,
  faLock,
} from '@fortawesome/free-solid-svg-icons';
import randomatic from 'randomatic';
import { catchError, debounceTime, Observable, of, take, tap, throwError } from 'rxjs';
import { AuthenticationUserType } from 'src/app/core/models/api/auth/AuthenticationUserTypeEnum';
import { GaUserApiRequest } from 'src/app/core/models/api/auth/requests/update-create-ga-user.request';
import { ProducerUserApiRequest } from 'src/app/core/models/api/auth/requests/update-create-producer-user.request';
import { GetGaUser, GetGaUserApiResponse } from 'src/app/core/models/api/general-agent/get-ga-user.response';
import { RouteEnum } from 'src/app/core/models/routes.enum';
import { AuthService } from 'src/app/core/services/http/auth/auth.service';
import { GaUsersService } from 'src/app/core/services/http/ga-users.service';
import { UserControlService } from 'src/app/core/services/http/user-control.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UserViewService } from 'src/app/core/services/user-view.service';
import { PasswordValidator } from 'src/app/shared/components/form-elements/password.validator';
import { ModalProps } from 'src/app/shared/components/modal/modal.component';
import { LogDetailsInfo } from 'src/app/shared/components/profile/log-details/log-details.component';
import { UserJournal } from 'src/app/shared/components/view-journal/view-journal.component';

@Component({
  selector: 'app-add-edit-ga-user',
  templateUrl: './add-edit-ga-user.component.html',
  styleUrls: ['./add-edit-ga-user.component.scss'],
})
export class AddEditGaUserComponent implements OnInit {
  keyIcon = faKey;
  arrowLeft = faArrowLeft;
  generatePWIcon = faRotate;
  personIcon = faUser;
  personCheckIcon = faUserCheck;
  emailIcon = faEnvelope;
  phoneIcon = faPhone;
  saveIcon = faFloppyDisk;
  historyIcon = faClockRotateLeft;
  resetPasswordButtonIcon = faClockRotateLeft;

  isEdittingUser: boolean;
  userId: number;

  userLocked: boolean = false;
  badLoginAttempts: number = 0;
  lockedUserIcon = faLock;

  gaUserForm: FormGroup;

  saveLoading: boolean;
  changePasswordLoading: boolean;

  userData$: Observable<GetGaUserApiResponse>;
  modalProps: ModalProps;
  showResetPasswordModal: boolean;
  logDetailsInfo: LogDetailsInfo = {};
  openJournal: any;
  gaUsersUrl: string = RouteEnum.ManageGAUSers;
  journalInput: UserJournal;
  gaId: number | undefined;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private userControlService: UserControlService,
    private authService: AuthService,
    private notificationService: NotificationService,
    private gaUserService: GaUsersService,
    private userViewService: UserViewService
  ) {}

  ngOnInit(): void {
    this.gaId = this.userViewService.GetCurrentUserViewState()?.GaId;

    this.userId = Number(this.route.snapshot.paramMap.get('id'));
    this.isEdittingUser = this.userId !== 0;

    this.userData$ = of({} as GetGaUserApiResponse);
    this.initForm({});

    this.onUsernameChange();
    this.modalProps = {
      disableButtons: true,
    };

    if (this.isEdittingUser) this.loadData();
  }

  private initForm(gaForm: GaFormFields) {
    let passwordValidations = this.isEdittingUser
      ? []
      : [Validators.minLength(8), PasswordValidator.strong, Validators.required];

    this.gaUserForm = this.fb.group({
      userName: [gaForm?.userName ?? '', Validators.required],
      firstName: [gaForm?.firstName ?? '', Validators.required],
      lastName: [gaForm?.lastName ?? '', Validators.required],
      password: [gaForm?.password ?? '', passwordValidations],
      email: [gaForm?.email ?? '', [Validators.required, Validators.email]],
      phoneNumber: [
        gaForm?.phoneNumber ?? '',
        [Validators.required, Validators.minLength(10), Validators.maxLength(10)],
      ],
      disabled: [gaForm?.disabled ?? false, Validators.required],
    });
  }

  onResetPassword() {
    let passwordField = this.gaUserForm.get('password');
    let userField = this.gaUserForm.get('userName');

    let data = this.userData$;
    this.changePasswordLoading = true;
    data.pipe(take(1)).subscribe(res => {
      if (passwordField?.valid && this.userId && userField?.value) {
        this.authService
          .ResetPassword(passwordField.value, this.userId.toString(), userField.value, AuthenticationUserType.GA)
          .subscribe(res => {
            if (res.status) this.notificationService.success(res.message);
            else this.notificationService.error(res.message);

            this.showResetPasswordModal = false;
            this.changePasswordLoading = false;
          });
      }
    });
  }

  onSubmit(response: GetGaUserApiResponse) {
    if (this.gaUserForm.invalid) {
      this.gaUserForm.markAllAsTouched();
      return;
    }

    let { userName, firstName, lastName, password, email, phoneNumber, disabled } = this.gaUserForm.value;
    let userPayload = {
      FirstName: firstName,
      Username: userName,
      LastName: lastName,
      Password: password,
      Email: email,
      Phone: phoneNumber,
      Disabled: disabled,
      GaId: this.gaId,
      UserID: this.userId === 0 ? '' : this.userId,
      Type: AuthenticationUserType.GA,
    } as GaUserApiRequest;

    this.saveLoading = true;
    this.userControlService
      .CreateOrUpdateUser(userPayload)
      .pipe(
        catchError(error => {
          this.notificationService.error('Something went wrong');
          this.saveLoading = false;
          return throwError(() => error);
        })
      )
      .subscribe(res => {
        if (res.status) {
          this.notificationService.success(res.message);
          this.router.navigate([this.gaUsersUrl]);
        } else this.notificationService.error(res.message);
        this.saveLoading = false;
      });
  }

  onGeneratePassword() {
    this.gaUserForm.get('password')?.patchValue(randomatic('Aa0!', 10));
  }

  onViewJournalClick() {
    this.openJournal = true;
  }

  unlockUser() {
    this.notificationService.confirmation('Confirm User Unlock?', () => {
      this.authService.UnlockUser(this.userId ?? 0).subscribe(res => {
        if (res.status === true) {
          this.notificationService.success(res.response);
          window.location.reload();
        } else {
          this.notificationService.error(res.response);
        }
      });
    });
  }

  private loadData() {
    this.userData$ = this.gaUserService.GetGaUser(this.userId).pipe(
      tap(res => {
        let data: GaFormFields = {
          disabled: res.response.Disabled,
          email: res.response.Email,
          firstName: res.response.FirstName,
          lastName: res.response.LastName,
          phoneNumber: res.response.Phone,
          userName: res.response.Username,
        };

        this.userLocked = res.response.IsLockOut;
        this.badLoginAttempts = res.response.BadLoginAttempts;

        this.logDetailsInfo = {
          FailedLogins: res.response.Failed_login,
          SuccessLogins: res.response.Success_login,
          TotalLogins: res.response.Failed_login + res.response.Success_login,
          LastLoginAttempt: res.response.Lastlogin,
          LastLoginStatus: res.response.LastloginStatus,
          LastLogOff: res.response.Lastlogoff,
        };

        this.journalInput = { UserId: this.userId, UserType: res.response.Type };

        this.initForm(data);
        this.onUsernameChange();
      })
    );
  }

  getCustomTitle() {
    if (this.isEdittingUser) {
      return 'Edit GA User';
    }
    return 'Add Ga User';
  }

  private onUsernameChange() {
    this.gaUserForm
      .get('userName')
      ?.valueChanges.pipe(debounceTime(1000))
      .subscribe((userName: string) => {
        if (!userName || userName.length === 0) return;

        this.authService.CheckUser({ UserName: userName }).subscribe(res => {
          let userNameField = this.gaUserForm.get('userName');

          if (!res.status) userNameField?.setErrors({ usernameTaken: true });
          else {
            userNameField?.setErrors(null);
          }
        });
      });
  }
}

type GaFormFields = {
  userName?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  password?: string;
  phoneNumber?: string;
  disabled?: boolean;
};
