import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { faArrowLeft, faBars } from '@fortawesome/free-solid-svg-icons';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  concat,
  concatMap,
  delay,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  of,
  startWith,
  Subscription,
  take,
  tap,
  throwError,
} from 'rxjs';
import { AuthenticationUserType } from 'src/app/core/models/api/auth/AuthenticationUserTypeEnum';
import { UserResponse } from 'src/app/core/models/api/auth/responses/user/info/user-info.response.model';
import { UserRightCodeEnum } from 'src/app/core/models/api/auth/responses/user/user-rights/user-right-code.enum';
import { UserRightsResponse } from 'src/app/core/models/api/auth/responses/user/user-rights/user-rights.response.model';
import { RouteEnum } from 'src/app/core/models/routes.enum';
import { UserViewState } from 'src/app/core/models/user-view-state.model';
import { ViewType, MenuLinksGroups } from 'src/app/core/models/user-view.model';
import { AuthService } from 'src/app/core/services/http/auth/auth.service';
import { EmployerService } from 'src/app/core/services/http/employer.service';
import { EmployersService } from 'src/app/core/services/http/employers.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UserViewService } from 'src/app/core/services/user-view.service';
import { VerticalMenuService } from 'src/app/core/services/vertical-menu.service';
import { fader } from 'src/app/route-animations';
import { AvatarMenuLinksInput } from 'src/app/shared/components/avatar-menu/avatar-menu.component';
import { ModalProps } from 'src/app/shared/components/modal/modal.component';
import { MenuLink } from 'src/app/shared/components/vertical-menu/components/menu-item/menu-item.component';
import { VerticalMenuItensGroup } from 'src/app/shared/components/vertical-menu/vertical-menu.component';
import { environment } from 'src/environments/environment';
import { FeatureFlagService } from 'src/app/core/services/http/feature-flag.service';
import { IsStringNullOrEmpty } from 'src/app/core/helpers/validation-helpers';
import { VersionHistoryService } from './pages/version-history/version-history.service';
import { VersionHistoryResponse } from './pages/version-history/version-history.response';
import { BenefitAdministratorService } from 'src/app/core/services/http/benefit-administrator.service';
import { EmailService } from 'src/app/core/services/http/email.service';
import { SubProducerService } from 'src/app/core/services/http/sub-producer.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { UserTypeEnum } from 'src/app/core/models/user-type.enum';

@Component({
  selector: 'app-user-dashboard',
  animations: [fader],
  styleUrls: ['./user-dashboard.component.scss'],
  template: `
    <div ngClass="main-dashboard-container">
      <app-vertical-menu
        ngClass="sidebar"
        [Open]="(menuVerticalToggle | async) || false"
        [IsLoading]="(menuVerticalIsLoading | async) || false"></app-vertical-menu>

      <div
        ngClass="right_of_the_sidebar header-section"
        [class.margin_left_open_sidebar]="(menuVerticalToggle | async) || false">
        <app-navbar ngClass="navbar">
          <navbar-left-side>
            <ng-template leftSideContent>
              <div style="display: flex; gap: 10px;">
                <div style="display: flex; align-items: center; cursor: pointer;" (click)="toggleMenuVertical()">
                  <fa-icon
                    *ngIf="(menuVerticalToggle | async) || false; else closed"
                    [icon]="menuVerticalToggleIcon"
                    [inverse]="true"></fa-icon>
                  <ng-template #closed
                    ><fa-icon [icon]="menuVerticalBarsIcon" [size]="'xl'" [inverse]="true"></fa-icon
                  ></ng-template>
                </div>
                <div *ngIf="userViewState$ | async as user" style="color: white;">
                  <span *ngIf="user.Impersonate"> {{ GetImpersonationName(user) }}</span>
                </div>
              </div>
            </ng-template>
          </navbar-left-side>
          <navbar-right-side>
            <ng-template rightSideContent>
              <div style="display: flex; align-items: center; gap: 15px;">
                <span *ngIf="userResponse$ | async as user" ngClass="username">{{ user.FirstName }}</span>
                <app-avatar-menu [menuLinks]="menulinks"></app-avatar-menu>
              </div>
            </ng-template>
          </navbar-right-side>
        </app-navbar>
      </div>

      <div
        ngClass="right_of_the_sidebar content-menu-wrapper"
        [class.margin_left_open_sidebar]="(menuVerticalToggle | async) || false">
        <div ngClass="content-section">
          <div *ngIf="IsLoading$ | async; else loaded">LOading...</div>
          <ng-template #loaded>
            <div [@routeAnimations]="prepareRoute(outlet)">
              <router-outlet #outlet="outlet"></router-outlet>
            </div>
          </ng-template>
        </div>
      </div>

      <div
        ngClass="right_of_the_sidebar footer-section"
        [class.margin_left_open_sidebar]="(menuVerticalToggle | async) || false">
        <div>Allied Administrators’ Website for Delta Dental’s Small Business Program</div>
        <div>
          ©{{ getCurrentYear() }} Allied Administrators Inc. All Rights Reserved.<app-term-of-use-modal
          ></app-term-of-use-modal>
        </div>
        <div>
          Software Version
          <a style="cursor:pointer" class="version-number-details" (click)="openCurrentVersionModal()">{{
            getCurrentSoftwareVersionNumber() | number : '1.1'
          }}</a>
        </div>
      </div>
    </div>

    <app-modal [modalProps]="infoModalProps" [open]="showInfoModal" modalHeader="Application Message">
      <div [innerHTML]="getInfoMessage()" class="error-modal"></div>
    </app-modal>
    <app-modal [modalProps]="errorModalProps" [open]="showErrorModal" modalHeader="Application Error">
      <div [innerHTML]="getErrorMessage()" class="error-modal"></div>
    </app-modal>

    <app-modal
      [modalProps]="currentVersionModalProps"
      [open]="showVersionDetails"
      modalHeader="Current Version Details">
      <div [innerHTML]="getCurrentVersionDetails()"></div>
    </app-modal>

    <app-modal [modalProps]="cobraModalProps" [open]="shouldSelectCobra" modalHeader="Cobra Type Selection">
      <div ngClass="cobra_question_modal_wrapper">
        <p *ngIf="UserType == 'R'">Please confirm your COBRA type</p>
        <p *ngIf="UserType == 'P'">Please confirm your client's COBRA type</p>
        <app-radio-button
          [options]="CobraTypesOptions"
          (onRadioButtonClick)="selectCobraType($event)"></app-radio-button>
        <br />
        <p>
          <b>What status do I fall under?</b><br />

          Cal-COBRA- You need to select Cal-COBRA if your group policy is in force with 2-19 employees covered on at
          least 50 percent of its working days during the preceding calendar year or the preceding calendar quarter if
          the employer was not in business during any part of the preceding calendar year.
          <br />
          If you have 20 or more employees then you would fall under Federal COBRA.
        </p>
      </div>
    </app-modal>
  `,
})
export class UserDashboardComponent implements OnInit {
  userViewStateSubscription: Subscription;
  private channel: BroadcastChannel;
  private tabId: string;
  private otherTabExists: boolean;

  GetImpersonationName(user: UserViewState) {
    if (user.CompanyName) return `${user.EmployerNumber} - ${user.CompanyName}`;
    return user.ProducerName;
  }

  menuVerticalToggleIcon = faArrowLeft;
  menuVerticalBarsIcon = faBars;
  menuVerticalToggle: BehaviorSubject<boolean>;
  menuVerticalIsLoading: BehaviorSubject<boolean>;

  menulinks: AvatarMenuLinksInput[] = [
    {
      label: 'View Profile',
      route: RouteEnum.UserProfile,
      func: () => {
        this.verticalMenuService.DeselectActiveMenu();
      },
    },
    {
      label: 'Logout',
      route: '/auth/signin',
      func: () => {
        this.authService.Logout();
        this.userViewService.RemoveUserState();
        this.verticalMenuService.RemoveVerticalMenuState();
      },
    },
  ];

  shouldSelectCobra: boolean;
  showVersionDetails: boolean;
  showErrorModal: boolean;
  showInfoModal: boolean;
  cobraModalProps: ModalProps;
  currentVersionModalProps: ModalProps;
  errorModalProps: ModalProps;
  infoModalProps: ModalProps;
  UserType?: AuthenticationUserType;
  CobraTypesOptions: { key: string; value: string }[];
  selectedCobraType: string;

  toggleMenuVertical(isOpen?: boolean) {
    this.verticalMenuService.UpdateState(previous => {
      return { ...previous, IsOpen: isOpen !== undefined ? isOpen : !previous.IsOpen };
    });
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  getCurrentYear() {
    return new Date().getFullYear();
  }

  userViewState$: Observable<UserViewState | null>;
  userResponse$: Observable<UserResponse | null>;
  userRightsData$: Observable<UserRightsResponse | null>;
  currentVersion: VersionHistoryResponse;

  verticalMenuItensGroups$: VerticalMenuItensGroup[] = [];
  selectedModule?: ViewType;
  IsLoading$: Observable<boolean>;


  constructor(
    private authService: AuthService,
    private userViewService: UserViewService,
    private verticalMenuService: VerticalMenuService,
    private employersService: EmployersService,
    private employerService: EmployerService,
    private http: HttpClient,
    private notificationService: NotificationService,
    private featureFlagService: FeatureFlagService,
    private versionHistoryService: VersionHistoryService,
    private benefitAdministratorService: BenefitAdministratorService,
    private emailService: EmailService,
    private route: ActivatedRoute,
    private router: Router,
    private subProducerService: SubProducerService,
    private modalService: ModalService
  ) {
    this.userRightsData$ = new Observable<UserRightsResponse | null>();
    this.userViewState$ = new Observable<UserViewState>();
    this.userResponse$ = new Observable<UserResponse | null>();
    this.IsLoading$ = new Observable<boolean>();

    this.channel = new BroadcastChannel('user-session');
    this.tabId = this.authService.getTabId();
    const tabsCount = this.authService.countTabs();

    this.otherTabExists = tabsCount > 1;
  }

  ngOnDestroy(): void {
    this.channel.close();
    this.userViewStateSubscription.unsubscribe();
  }

  ngOnInit(): void {
    const existingSession = this.authService.getTabSession();

    if (!existingSession) {
      this.authService.clearTabSession();
      this.router.navigate(['/auth/signin']);
    }

    this.channel.postMessage({
      tabId: this.tabId,
    });
    if(this.otherTabExists) {
      this.authService.clearTabs();
      this.authService.addTabToLocalStorage(this.tabId);
    }

    this.channel.onmessage = event => {
      if (event.data?.tabId !== this.tabId) {
        this.channel.postMessage({
          tabId: event.data?.tabId,
          tabExists: true
        })
        this.authService.clearTabSession();
        this.router.navigate(['/auth/signin']);
        window.location.reload();
      }
      if(event.data?.tabId == this.tabId && event.data?.tabExists) {
      this.notificationService.alert('Other active sessions were logged off. Users can only persist one active session');
      }
    };

    this.modalService.errorModal.pipe(distinctUntilChanged()).subscribe(val => {
      this.showErrorModal = val === true;
    });
    this.modalService.infoModal.pipe(distinctUntilChanged()).subscribe(val => {
      this.showInfoModal = val === true;
    });
    this.userResponse$ = this.authService.userInfo;
    this.userViewState$ = this.userViewService.CurrentUserViewState;
    this.userRightsData$ = this.authService.userRights;
    this.CobraTypesOptions = [];
    this.menuVerticalToggle = new BehaviorSubject<boolean>(true);
    this.menuVerticalIsLoading = new BehaviorSubject<boolean>(true);
    this.verticalMenuService.state.pipe(distinctUntilChanged()).subscribe(res => {
      this.menuVerticalIsLoading.next(res.IsLoading);
      this.menuVerticalToggle.next(res.IsOpen);
    });

    this.MountView();
    this.authService.GetUserInfo();
    this.userViewStateSubscription = this.userViewState$.subscribe(actualView => {
      actualView && this.setAvatarMenuLink(actualView);

      
      if (actualView && actualView.EmployerId && actualView.ActualRegion != 'None' && actualView.ActualView === ViewType.EmployerView) {
        this.setCobraModal(actualView);
      }
    });
    this.getSoftwareVersionNumber();
    this.infoModalProps = {
      OkButton: {
        show: false,
      },
      CloseButton: {
        OnAction: () => {
          this.modalService.updateInfoModal(false);
        },
        show: true,
      },
      CanCloseModal: true,
    };
    this.errorModalProps = {
      OkButton: {
        show: false,
      },
      CloseButton: {
        OnAction: () => {
          this.modalService.updateErrorModal(false);
        },
        show: true,
      },
      CanCloseModal: true,
    };
    this.currentVersionModalProps = {
      OkButton: {
        show: false,
      },
      CloseButton: {
        OnAction: () => {
          this.showVersionDetails = false;
        },
        show: true,
      },
      CanCloseModal: true,
    };
  }

  private getSoftwareVersionNumber() {
    this.versionHistoryService.getLatestVersion().subscribe(res => {
      this.currentVersion = res;
    });
  }

  getCurrentSoftwareVersionNumber() {
    return this.currentVersion?.VersionNumber;
  }
  getCurrentVersionDetails() {
    return this.currentVersion?.Content;
  }

  getInfoMessage() {
    return this.modalService.infoMessage;
  }
  getErrorMessage() {
    return this.modalService.errorMessage;
  }
  openCurrentVersionModal() {
    this.showVersionDetails = true;
  }

  private setAvatarMenuLink(actualView: UserViewState) {
    let hasReturnToYourView = this.menulinks.find(x => x.label.includes('Return to your view'));

    if (!hasReturnToYourView && actualView && actualView.Impersonate) {
      this.menulinks.splice(1, 0, {
        label: 'Return to your view',
        func: () => this.resetUserViewState(),
      });
    }

    if (hasReturnToYourView && !actualView?.Impersonate) {
      this.menulinks = this.menulinks.filter(x => !x.label.includes('Return to your view'));
    }
  }

  public selectCobraType($event: string) {
    this.selectedCobraType = $event;

    this.cobraModalProps.disableButtons = false;
  }

  private setCobraModal(actualView: UserViewState) {
    this.cobraModalProps = {
      OkButton: {
        OnAction: () => {
          if (!this.selectedCobraType) {
            this.notificationService.error('Please, select one cobra type');
            return;
          }

          this.http
            .put(`${environment.alliedApi.baseUrl}/employers/UpdateCobraType`, {
              employerId: actualView.EmployerId,
              cobraTypeId: this.selectedCobraType,
            })
            .subscribe(_ => {
              this.notificationService.success('cobra type updated successfully');
            });
        },
      },
      CloseButton: {
        show: false,
      },
      CanCloseModal: false,
      disableButtons: true,
    };

    this.fillCobraTypeOptions()
      .pipe(
        concatMap(_ =>
          this.authService.userInfo.pipe(
            take(1),
            tap(res => (this.UserType = res?.Type))
          )
        ),
        concatMap(_ => this.employersService.ShouldSelectCobraType(actualView.EmployerId ?? ''))
      )
      .subscribe(res => (this.shouldSelectCobra = res));
  }

  private fillCobraTypeOptions() {
    let cobraTypes = this.http.get<{ Description: string; Id: number }[]>(
      `${environment.alliedApi.baseUrl}/CobraTypes`
    );
    return cobraTypes.pipe(
      tap(res => {
        this.CobraTypesOptions = res.map<{ key: string; value: string }>(cobraType => ({
          key: cobraType.Id.toString(),
          value: cobraType.Description,
        }));
      })
    );
  }

  private resetUserViewState() {
    let currentState = this.userViewService.GetCurrentUserViewState();
    if (currentState) {
      this.userViewService
        .Propagate(false)
        .ChangeEmployerId('')
        .ChangePlanNumber('')
        .ChangeCompanyName('')
        .ChangeWaitingPeriod('')
        .ChangeIsNewBaUser(false)
        .ChangeIsBaUser(false)
        .ChangeImpersonationState(false)
        .ChangeAbsNewFeed(false)
        .ChangeView(currentState.PrevView);

      let isAdminReturningFromEmployer =
        this.authService.IsAdmin() && currentState?.ActualView === ViewType.EmployerView;

      if (!isAdminReturningFromEmployer) {
        this.userViewService.ChangeRegion(currentState.PrevRegion);
      }

      this.userViewService.Propagate(true).NavigateToPageView(this.userViewService.GetPrimaryLink().toString());
    }
  }

  private MapToVerticalMenuItem(menuLinksGroups: MenuLinksGroups[]): VerticalMenuItensGroup[] {
    return menuLinksGroups.map(menuLinksGroup => {
      return {
        groupName: menuLinksGroup.GroupName,
        verticalMenuItemNew: menuLinksGroup.MenuLinks.map(menuLink => ({
          Label: menuLink.linkName,
          Link: menuLink.link,
          Icon: menuLink.icon,
          Active: menuLink.isPrimary,
          SubMenuLinks: menuLink.subMenuLinks
            ? menuLink.subMenuLinks.map<MenuLink>(subMenu => ({
                Label: subMenu.linkName,
                Link: subMenu.link,
                Icon: subMenu.icon,
                Active: subMenu.isPrimary,
              }))
            : [],
        })),
      } as VerticalMenuItensGroup;
    });
  }

  menuLinksGroups: MenuLinksGroups[] | null = [];
  MountView() {
    const viewObservableCombined = combineLatest([this.userViewState$, this.userRightsData$, this.userResponse$]);
    let isEmployerView = (state: UserViewState | null): boolean =>
      state?.ActualView === ViewType.EmployerView && !IsStringNullOrEmpty(state.EmployerId);

    const isAdminView = (state: UserViewState | null): boolean =>
      state?.ActualView === ViewType.Controller;

    let isProducerOrBaView = (state: UserViewState | null): boolean =>
      state?.ActualView === ViewType.ProducerView ||
      state?.ActualView === ViewType.BenefitAdministratorNewBaUserView ||
      state?.ActualView === ViewType.BenefitAdministratorView;

    this.setQualifyingEventViewState(true);
    viewObservableCombined
      .pipe(
        filter(_ => this.authService.IsLoggedIn()),
        tap(_ => this.verticalMenuService.SetIsLoading(true)),
        tap(([userViewState, userRightData, userResponse]) => {
          let userRights = undefined;
          if (!userResponse) return;

          if (userResponse.Type == AuthenticationUserType.Admin) {
            if (!userRightData || userRightData.response.length == 0) return;

            userRights = userRightData.response[0].rights.filter(x => x.assigned === 1).map(x => x.RightCode);
          }
          this.menuLinksGroups = this.userViewService.GetAvaiableUserMenuLinksFromViewState(userRights);
        }),
        concatMap(res => this.CobraTypeMenuLinkFilter().pipe(map(_ => res))),
        concatMap(res => {
          if (isProducerOrBaView(res[0])) {
            return of(res).pipe(
              concatMap(res => this.verifySubProducer(res[1], res[0]).pipe(map(_ => res))),
              concatMap(res => this.VerifyAbsFeed(res[1], res[0]).pipe(map(_ => res))),
              concatMap(res => this.verifyUserCanSeeCommissionStatement(res[1], res[0]).pipe(map(_ => res)))
            );
          }

          if (isEmployerView(res[0])) {
            return of(res).pipe(
              concatMap(res => this.AccessEligibilityMenuFilter(res[1], res[0]).pipe(map(_ => res))),
              concatMap(res => this.accessGaEligiblity(res[1], res[0]).pipe(map(_ => res))),
              concatMap(res => this.ManageXMLMenuLinkFilter(res[1], res[0]).pipe(map(_ => res))),
              concatMap(res => this.VerifyProducerEligibility(res[1], res[0]).pipe(map(_ => res))),
              concatMap(res => this.VerifyProducerEligibility(res[1], res[0]).pipe(map(_ => res))),
              concatMap(res => this.VerifyWaitingPeriod(res[1], res[0]).pipe(map(_ => res))),
              concatMap(res =>
                this.OpenEnrollmentMenuLinkFilter(res[1], res[0])
                  .pipe(concatMap(canViewOpenEnrollment => this.QualifyingEventMenuLinkFilter(canViewOpenEnrollment)))
                  .pipe(map(_ => res))
              ),
              concatMap(res => this.ErUserViewOnly(res[1], res[0]).pipe(map(_ => res)))
            );
          }

          return of(res);
        })
      )
      .subscribe(res => {
        if (this.menuLinksGroups) this.verticalMenuItensGroups$ = this.MapToVerticalMenuItem(this.menuLinksGroups);

        this.verticalMenuService.UpdateState(previous => {
          return { ...previous, MenuLinksGroups: this.verticalMenuItensGroups$ };
        });
        this.verticalMenuService.SetActiveMenuLinkByRoute(this.userViewService.GetPrimaryLink());

        this.verticalMenuService.SetIsLoading(false);
      });

    this.IsLoading$ = viewObservableCombined.pipe(
      map(([userViewState, userRightData, authResponse]) => {
        return userViewState?.IsLoading || false;
      }),
      startWith(true)
    );
  }

  private accessGaEligiblity(userRightData: UserRightsResponse | null, userViewState: UserViewState | null) {
    
    if (userViewState && userViewState.EmployerId && userViewState.GaId) {
      if(userViewState.UserType !== UserTypeEnum.Ga) return of(false);
      return this.employerService.GetGaManageEligiblity(userViewState.EmployerId).pipe(
        tap(res => {
          if (!this.menuLinksGroups?.length || !userViewState) return;
          if (res !== true) {
            this.menuLinksGroups = this.removeLinkByLinkName(this.menuLinksGroups, ['Open Enrollment']);
            this.menuLinksGroups = this.removeLinkByLinkName(this.menuLinksGroups, ['Add Employee']);
            this.menuLinksGroups = this.removeLinkByLinkName(this.menuLinksGroups, ['Qualifying Event']);
            this.setQualifyingEventViewState(false);
          }
        })
      );
    }

    return of(false);
  }
  private AccessEligibilityMenuFilter(userRightData: UserRightsResponse | null, userViewState: UserViewState | null) {
    if (userViewState && userViewState.EmployerId) {
      return this.benefitAdministratorService
        .GetEmployerManagement(userViewState.EmployerId, userViewState.ActualRegion)
        .pipe(
          tap(res => {
            if (!this.menuLinksGroups?.length || !userViewState) return;

            if (res.response.employerHaveRequestToManage === false && !this.authService.IsAdmin())
              this.menuLinksGroups = this.removeLinkByLinkName(this.menuLinksGroups, ['Eligibility Access']);
          })
        );
    }

    return of();
  }

  private ManageXMLMenuLinkFilter(userRightData: UserRightsResponse | null, userViewState: UserViewState | null) {
    if (!this.menuLinksGroups?.length || !userViewState) return of(false);

    this.employerService.GetEmployerInfoById(userViewState.EmployerId ?? '').subscribe(employerData => {
      if (userViewState.UserType === 'Employer') {
        if (!employerData?.Data.ManageErXmlEligibility) {
          if (this.menuLinksGroups)
            this.menuLinksGroups = this.removeLinkByLinkName(this.menuLinksGroups, ['Manage XML']);
        }
        if (!employerData?.Data.ManageErEligibilityUpload) {
          if (this.menuLinksGroups)
            this.menuLinksGroups = this.removeLinkByLinkName(this.menuLinksGroups, ['Manage Eligibility Upload']);
        }
      } 
      else if(userViewState.UserType === 'GA') {
        if (!employerData?.Data.ManageGaXmlEligibility) {
          if (this.menuLinksGroups)
            this.menuLinksGroups = this.removeLinkByLinkName(this.menuLinksGroups, ['Manage XML']);
        }
        if (!employerData?.Data.ManageGaEligibilityUpload) {
          if (this.menuLinksGroups)
            this.menuLinksGroups = this.removeLinkByLinkName(this.menuLinksGroups, ['Manage Eligibility Upload']);
        }
      }
      else if (userViewState.UserType === 'Producer') {
        if (!employerData?.Data.ManageMPXmlEligibility) {
          if (this.menuLinksGroups)
            this.menuLinksGroups = this.removeLinkByLinkName(this.menuLinksGroups, ['Manage XML']);
        }
        if (!employerData?.Data.ManageMpEligibilityUpload) {
          if (this.menuLinksGroups)
            this.menuLinksGroups = this.removeLinkByLinkName(this.menuLinksGroups, ['Manage Eligibility Upload']);
        }
      } else if (userViewState.UserType === 'NewBaProducer' || userViewState.UserType === 'BaProducer') {
        if (!employerData?.Data.ManageBaXmlEligibility) {
          if (this.menuLinksGroups)
            this.menuLinksGroups = this.removeLinkByLinkName(this.menuLinksGroups, ['Manage XML']);
        }
        if (!employerData?.Data.ManageBaEligibilityUpload) {
          if (this.menuLinksGroups)
            this.menuLinksGroups = this.removeLinkByLinkName(this.menuLinksGroups, ['Manage Eligibility Upload']);
        }
      }
      //TODO - ADD BA CONTROL FOR XML
    });
    return of(true);
  }

  private CobraTypeMenuLinkFilter() {
    return this.featureFlagService.IsFlagEnabled(this.featureFlagService.COBRATYPES).pipe(
      tap(hasCobraTypes => {
        if (this.menuLinksGroups && !hasCobraTypes)
          this.menuLinksGroups = this.removeLinkByLinkName(this.menuLinksGroups, [
            'COBRA Reports',
            'Federal/State COBRA Edit',
          ]);
      })
    );
  }

  private VerifyWaitingPeriod(userRightData: UserRightsResponse | null, userViewState: UserViewState | null) {
    if (!this.menuLinksGroups?.length || !userViewState) return of(false);

    if (userViewState.UserType === 'Admin') return of(false);

    if (userViewState.WaitingPeriod === 'X') {
      this.setQualifyingEventViewState(false);
      this.menuLinksGroups = this.removeLinkByLinkName(this.menuLinksGroups, ['Add Employee']);
    }

    return of(true);
  }

  private verifySubProducer(userRightData: UserRightsResponse | null, userViewState: UserViewState | null) {
    if (!this.menuLinksGroups?.length || !userViewState) return of(false);

    if (userViewState.IsSubProducer === true) {
      this.menuLinksGroups = this.removeLinkByLinkName(this.menuLinksGroups, ['Sub Producers']);
    }

    return of(true);
  }

  private VerifyAbsFeed(userRightData: UserRightsResponse | null, userViewState: UserViewState | null) {
    if (!this.menuLinksGroups?.length || !userViewState) return of(false);

    if (userViewState.ABSNewFeed !== true) {
      this.menuLinksGroups = this.removeLinkByLinkName(this.menuLinksGroups, ['Allied Benefit Suite']);
    }

    return of(true);
  }

  private VerifyProducerEligibility(userRightData: UserRightsResponse | null, userViewState: UserViewState | null) {
    if (!this.menuLinksGroups?.length || !userViewState) return of(false);

    if (
      userViewState.UserType !== 'Producer' &&
      userViewState.UserType !== 'NewBaProducer' &&
      userViewState.UserType !== 'BaProducer'
    )
      return of(false);

    if (
      userViewState.EmployerManageEligibility === false ||
      userViewState.EmployerManageEligibility === undefined ||
      userViewState.EmployerManageEligibility === null
    ) {
      this.menuLinksGroups = this.removeLinkByLinkName(this.menuLinksGroups, ['Open Enrollment']);
      this.menuLinksGroups = this.removeLinkByLinkName(this.menuLinksGroups, ['Add Employee']);
      this.menuLinksGroups = this.removeLinkByLinkName(this.menuLinksGroups, ['Qualifying Event']);
      this.setQualifyingEventViewState(false);
    }

    return of(true);
  }

  private verifyUserCanSeeCommissionStatement(
    userRightData: UserRightsResponse | null,
    userViewState: UserViewState | null
  ) {
    if (!this.menuLinksGroups?.length || !userViewState) return of(false);

    if (userViewState.IsSubProducer !== true) return of(true);

    return this.subProducerService.IsUserAllowedToSeeCommissionStatements().pipe(
      tap(response => {
        if (response === false) {
          if (this.menuLinksGroups)
            this.menuLinksGroups = this.removeLinkByLinkName(this.menuLinksGroups, ['Commission Statements']);
        }
      })
    );
  }

  private ErUserViewOnly(userRightData: UserRightsResponse | null, userViewState: UserViewState | null) {
    if (!this.menuLinksGroups?.length || !userViewState) return of(false);
    if (this.authService.IsEr() !== true) {
      return of(true);
    }

    if (userViewState.IsViewOnly === true) {
      this.setQualifyingEventViewState(false);
      this.menuLinksGroups = this.removeLinkByLinkName(this.menuLinksGroups, ['Open Enrollment']);
      this.menuLinksGroups = this.removeLinkByLinkName(this.menuLinksGroups, ['Add Employee']);
      this.menuLinksGroups = this.removeLinkByLinkName(this.menuLinksGroups, ['Qualifying Event']);
      this.menuLinksGroups = this.removeLinkByLinkName(this.menuLinksGroups, ['Online Payment']);
      this.menuLinksGroups = this.removeLinkByLinkName(this.menuLinksGroups, ['Manage Eligibility Upload']);
      this.menuLinksGroups = this.removeLinkByLinkName(this.menuLinksGroups, ['Manage XML']);
    }

    return of(true);
  }

  private OpenEnrollmentMenuLinkFilter(userRightData: UserRightsResponse | null, userViewState: UserViewState | null) {
    if (!this.menuLinksGroups?.length || !userViewState) return of(false);

    let hasEmployeeEditUserRight = false;

    if (userRightData)
      userRightData.response.filter(x => {
        let employeeEdit = x.rights.filter(y => y.RightCode === UserRightCodeEnum.EmployeeEdit);
        hasEmployeeEditUserRight = employeeEdit[0].assigned === 1;
      });

    return this.employerService.IsOpenEnrollmentAsync(Number(userViewState?.EmployerId)).pipe(
      tap(canViewOpenEnrollmentMenuLink => {
        let adminCanViewOEMenuLink =
          canViewOpenEnrollmentMenuLink && this.authService.IsAdmin() && hasEmployeeEditUserRight;

        let masterProducerCanViewOEMenuLink =
          canViewOpenEnrollmentMenuLink && this.authService.IsMP() && userViewState?.EmployerManageEligibility;

        if (adminCanViewOEMenuLink) return;
        else if (masterProducerCanViewOEMenuLink) return;
        else if (canViewOpenEnrollmentMenuLink) return;

        if (this.menuLinksGroups)
          this.menuLinksGroups = this.removeLinkByLinkName(this.menuLinksGroups, ['Open Enrollment']);
      })
    );
  }

  private QualifyingEventMenuLinkFilter(canViewOpenEnrollmentMenuLink: boolean) {
    return this.featureFlagService.IsFlagEnabled(this.featureFlagService.QUALIFYINGEVENT).pipe(
      tap(qualifyingEventEnabled => {
        if (this.menuLinksGroups && this.authService.IsAdmin() === false && canViewOpenEnrollmentMenuLink) {
          this.setQualifyingEventViewState(false);
          this.menuLinksGroups = this.removeLinkByLinkName(this.menuLinksGroups, ['Qualifying Event']);
        }
      })
    );
  }

  private setQualifyingEventViewState(qualifyingEvent: boolean) {
    const viewState = this.userViewService.GetCurrentUserViewState();
    if (viewState?.QualifyingEvent !== qualifyingEvent) {
      this.userViewService.ChangeQualifyingEvent(qualifyingEvent);
    }
  }

  private removeLinkByLinkName(menuLinkGroups: MenuLinksGroups[], linkNames: string[]): MenuLinksGroups[] {
    return menuLinkGroups.map(group => {
      group.MenuLinks = group.MenuLinks.filter(link => !linkNames.includes(link.linkName));
      group.MenuLinks = group.MenuLinks.map(link => {
        if (link.subMenuLinks)
          link.subMenuLinks = link.subMenuLinks.filter(subMenuLink => !linkNames.includes(subMenuLink.linkName));
        return link;
      });
      return group;
    });
  }
}
